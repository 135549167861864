/********************************************************************
 *
 * Summary.jsx
 *
 * Renders a summary of the ticket and status.
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { WidgetFrame } from "../../../lib";
import format from "../../../../utils/format";
import { SEVERITY_ICONS } from "../lib/iconLib";
import Ownership from "./Ownership";

/**
 * Summary
 *
 * Renders the ticket summary information
 *
 * @param {*} param0
 * @returns
 */
const Summary = ({
  ticket,
  timezone,
  reducer: { state, dispatch, actions },
  disabled,
  onUpdate,
  onEdit,
}) => {
  const { completed } = state;

  if (!ticket) return;

  return (
    <WidgetFrame
      title={ticket ? ticket.severity.name : "Loading..."}
      avatar={!!ticket && SEVERITY_ICONS[ticket.severity.id]}
      action={
        <IconButton
          disabled={disabled}
          onClick={() => {
            onEdit();
          }}
        >
          <Edit />
        </IconButton>
      }
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, pt: 0 }}>
        <Box sx={{ pb: 0 }}>
          <Typography
            variant="body2"
            sx={{ color: "text.secondary" }}
          >{`Created By ${
            ticket.createdBy
              ? `${ticket.createdBy.fname} ${ticket.createdBy.lname}`
              : "Anonymous"
          } on ${format.date(ticket.timestamp, timezone)}`}</Typography>
        </Box>
        <Box sx={{ pb: 2 }}>
          <Typography>{ticket.description}</Typography>
        </Box>
        <Ownership
          ticket={ticket}
          disabled={disabled}
          reducer={{ state, dispatch, actions }}
          onUpdate={onUpdate}
        />
      </Box>
    </WidgetFrame>
  );
};

/**
 * PropTypes
 */
Summary.propTypes = {
  ticket: PropTypes.object,
  timezone: PropTypes.string,
  reducer: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default Summary;
