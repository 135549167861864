import Catalog from "../components/Catalog";

//
//  ProductTypes
//
import ProductTypes from "../components/Catalog/ProductTypes";

//
//  ProductType
//
import ProductType from "../components/Catalog/ProductType";
import ProductTypeProducts from "../components/Catalog/ProductType/Products";
import ProductTypeIdentity from "../components/Catalog/ProductType/Identity";
import ProductTypeFeatureTypes from "../components/Catalog/ProductType/FeatureTypes";
import ProductTypeRateClasses from "../components/Catalog/ProductType/RateClasses/index";
import ProductTypePartners from "../components/Catalog/ProductType/Partners";

//
//  Product
//
import Product from "../components/Catalog/Product";
import { ProductEdit } from "../components/Catalog/Product/Identity";

//
//  RateClasses
//
import RateClasses from "../components/Catalog/RateClasses";

//
//  RateClass
//
import RateClass from "../components/Catalog/RateClass";
import RateClassIdentity from "../components/Catalog/RateClass/Identity";
import RateClassEdit from "../components/Catalog/RateClass/Identity/RateClassEdit";
import RateClassPolicies from "../components/Catalog/RateClass/Policies";
import RateClassPartners from "../components/Catalog/RateClass/Partners";
import RateClassPartnersEdit from "../components/Catalog/RateClasses/RateClassPartnersEdit";
import RateClassRateEdit from "../components/Catalog/RateClasses/RateClassRateEdit";

//
//  Reports
//
import ProductReport from "../components/Catalog/Reports/ProductReport";
import Redirect from "./Redirect";

export default {
  path: "/app/catalog/",
  element: <Catalog />,
  children: [
    {
      path: "/app/catalog/",
      element: <Redirect to="/app/catalog/producttypes/" />,
    },
    {
      path: "/app/catalog/producttypes/",
      element: <ProductTypes />,
    },
    {
      path: "/app/catalog/producttype/",
      element: <ProductType />,
    },
    {
      path: "/app/catalog/producttype/:productTypeId",
      element: <ProductType />,
      children: [
        {
          path: "/app/catalog/producttype/:productTypeId/products",
          element: <ProductTypeProducts />,
        },
        {
          path: "/app/catalog/producttype/:productTypeId/description",
          element: <ProductTypeIdentity />,
        },
        {
          path: "/app/catalog/producttype/:productTypeId/features",
          element: <ProductTypeFeatureTypes />,
        },
        {
          path: "/app/catalog/producttype/:productTypeId/rateclasses",
          element: <ProductTypeRateClasses />,
        },
        {
          path: "/app/catalog/producttype/:productTypeId/partners",
          element: <ProductTypePartners />,
        },
        {
          path: "/app/catalog/producttype/:productTypeId",
          element: (
            <Redirect to={`/app/catalog/producttype/:productTypeId/products`} />
          ),
        },
      ],
    },
    {
      path: "/app/catalog/product/:id",
      element: <Product />,
    },
    {
      path: "/app/catalog/product/edit/:id",
      element: <ProductEdit />,
    },
    {
      path: "/app/catalog/product/edit/",
      element: <ProductEdit />,
    },
    {
      path: "/app/catalog/rateclasses/",
      element: <RateClasses />,
    },
    {
      path: "/app/catalog/rateclass/",
      element: <RateClassEdit />,
    },
    {
      path: "/app/catalog/rateclass/:id",
      element: <RateClass />,
      children: [
        {
          path: "/app/catalog/rateclass/:id/identity/",
          element: <RateClassIdentity />,
        },
        {
          path: "/app/catalog/rateclass/:id/policies/",
          element: <RateClassPolicies />,
        },
        {
          path: "/app/catalog/rateclass/:id/partners/",
          element: <RateClassPartners />,
        },
        {
          path: "/app/catalog/rateclass/:id",
          element: <Redirect to={`/app/catalog/rateclass/:id/identity/`} />,
        },
      ],
    },
    {
      path: "/app/catalog/rateclass/:id/partners/edit/",
      element: <RateClassPartnersEdit />,
    },
    {
      path: "/app/catalog/rate/edit/:id",
      element: <RateClassRateEdit />,
    },
    {
      path: "/app/catalog/reports/products/",
      element: <ProductReport />,
    },
  ],
};
