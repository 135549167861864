/********************************************************************
 *
 * components/goods/products/index.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from "@mui/material";
import { Error } from "@mui/icons-material";
import { DateTime } from "luxon";
import { WidgetFrame } from "../../lib";
import format from "../../../utils/format";
import { useApp, useAPI } from "../../../providers/AppProvider";

/**
 * ProductsForGoods
 *
 * List of Products that contain this good
 *
 */
const ProductsForTour = ({ tour }) => {
  const [products, setProducts] = useState(null);
  const app = useApp();
  const api = useAPI();

  /**
   *  Products State Management
   */
  useEffect(() => {
    if (tour) fetchProducts(tour.id);
  }, [tour]);

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * FetchProducts
   *
   * @param {*} tourId
   */
  const fetchProducts = (tourId) => {
    api
      .fetch(`/api/suppliergoods/suppliergood/${tourId}/products/?verbose`)
      .then(({ payload: products }) => {
        setProducts(products);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * Render
   *
   * Renders a list of products that contain the tour
   *
   */

  if (!tour) return null;

  return (
    <WidgetFrame title={`Products for Good ${tour.id}`}>
      {!products && (
        <Typography variant="body2">
          Tour is currently not assinged to any products.
        </Typography>
      )}
      <List>
        {!!products &&
          products.map((product) => (
            <ListItem
              key={product.id}
              href={`/app/catalog/product/${product.id}`}
              component="a"
              target="__blank"
              sx={{
                "&:hover": {
                  backgroundColor: "background.default",
                  color: "#666666",
                  textDecoration: "none",
                },
              }}
            >
              <ListItemAvatar>
                <Avatar alt={product.name} src={product.imageURL}>
                  {product.name[0]}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={product.name}
                secondary={<ProductTime tour={tour} product={product} />}
              />
            </ListItem>
          ))}
      </List>
    </WidgetFrame>
  );
};

const ProductTime = ({ tour, product }) => {
  const productStart = DateTime.fromISO(product.start).setZone(
    product.timezone
  );
  const productEnd = DateTime.fromISO(product.end).setZone(product.timezone);
  const alert =
    DateTime.fromISO(tour.start).diff(productStart).as("minutes") !== 0 ||
    DateTime.fromISO(tour.end).diff(productEnd).as("minutes") !== 0;

  return (
    <Box
      sx={
        !!alert
          ? {
              color: "error.main",
            }
          : null
      }
    >
      {!!alert && (
        <Box style={{ paddingRight: "8px" }} component="span">
          <Error
            sx={{
              color: "error.main",
            }}
          />
        </Box>
      )}
      {`${productStart.toLocaleString(
        DateTime.DATETIME_SHORT
      )} - ${format.duration(productEnd.diff(productStart).as("minutes"))}`}
    </Box>
  );
};

ProductsForTour.propTypes = {
  tour: PropTypes.object.isRequired,
};

export default ProductsForTour;
