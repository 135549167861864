/********************************************************************
 *
 * FormSection.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import { Card, CardHeader, CardContent } from "@mui/material";

/**
 * FormSection
 *
 * @param {*} param0
 *
 * @returns
 */
const FormSection = ({ title, children }) => (
  <Card
    data-test-id="FORM_FRAME"
    sx={{
      padding: 2,
      borderRadius: 4,
      border: "1px solid rgba(122,123,151,.3)",
      backgroundColor: "#ffffff",
    }}
  >
    {!!title && <CardHeader title={title} />}
    <CardContent
      sx={{
        display: "grid",
        gap: 2,
        gridTemplateColumns: "repeat(12, 1fr)",
        width: "100%",
      }}
    >
      {children}
    </CardContent>
  </Card>
);

/**
 * PropTypes
 */
FormSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default FormSection;
