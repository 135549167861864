/********************************************************************
 *
 * Summary.jsx
 *
 * Renders a summary of the work order and controls the work status.
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Avatar,
  TextField,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Edit } from "@mui/icons-material";
import { WidgetFrame } from "../../../lib";
import format from "../../../../utils/format";
import { SEVERITY_ICONS } from "../lib/iconLib";

/**
 * Summary
 *
 * Renders the work order summary information
 *
 * @param {*} param0
 * @returns
 */
const Summary = ({
  workOrder,
  timezone,
  reducer: { state, dispatch, actions },
  disabled,
  onUpdate,
  onEdit,
}) => {
  const { completed } = state;

  if (!workOrder) return;
  return (
    <WidgetFrame
      title={workOrder ? workOrder.asset.name : "Loading..."}
      avatar={<Avatar>{!!workOrder && workOrder.id}</Avatar>}
      action={
        <IconButton
          onClick={() => {
            onEdit();
          }}
        >
          <Edit />
        </IconButton>
      }
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, pt: 0 }}>
        <Box sx={{ pb: 0 }}>
          <Typography
            variant="body2"
            sx={{ color: "text.secondary" }}
          >{`Created By ${
            workOrder.createdBy
              ? `${workOrder.createdBy.fname} ${workOrder.createdBy.lname}`
              : "Anonymous"
          } on ${format.date(workOrder.created, timezone)}`}</Typography>
        </Box>
        <Box sx={{ pb: 2 }}>
          <Typography>{workOrder.description}</Typography>
        </Box>
        <WidgetFrame
          title={!!workOrder && workOrder.severity.name}
          avatar={!!workOrder && SEVERITY_ICONS[workOrder.severity.id]}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              pt: 1,
              pb: 2,
            }}
          >
            <DatePicker
              label={
                !workOrder || !workOrder.started ? "Not Started" : "Started"
              }
              disabled={true}
              value={format.toDateTime(workOrder.started, timezone) || null}
              slotProps={{ field: { clearable: true } }}
              onChange={(value) => {
                onUpdate({ started: format.toISO(value) });
              }}
            />
            <DatePicker
              label={
                !workOrder || !workOrder.completed
                  ? "Not Completed"
                  : "Completed"
              }
              disabled={true}
              value={format.toDateTime(workOrder.completed, timezone) || null}
              slotProps={{ field: { clearable: true } }}
              onChange={(value) => {
                onUpdate({ completed: format.toISO(value) });
              }}
            />
            <TextField
              label="Reading"
              value={workOrder.reading || ""}
              disabled={disabled || completed}
              onBlur={(event) => {
                onUpdate({ reading: event.target.value });
              }}
              sx={{ width: "100%" }}
            />
          </Box>
          {!workOrder || !workOrder.started ? (
            <Button
              variant="contained"
              onClick={() => {
                onUpdate({ started: format.todayISO() });
              }}
            >
              Start
            </Button>
          ) : !workOrder.completed ? (
            <Button
              variant="contained"
              disabled={!state.openItems}
              onClick={() => {
                onUpdate({ completed: format.todayISO() });
              }}
            >
              Finish
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={() => {
                onUpdate({ completed: null });
              }}
            >
              Reopen
            </Button>
          )}
        </WidgetFrame>
      </Box>
    </WidgetFrame>
  );
};

/**
 * PropTypes
 */
Summary.propTypes = {
  workOrder: PropTypes.object.isRequired,
  timezone: PropTypes.string,
  reducer: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default Summary;
