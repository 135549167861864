/********************************************************************
 *
 * /components.sales/leads/addNote.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState } from "react";
import { Box, TextField, Button } from "@mui/material";
import { WidgetFrame } from "../../lib";

/**
 * AddNote
 *
 * @param {*} props
 */
const AddNote = ({ onAddNote }) => {
  const [note, setNote] = useState("");

  return (
    <WidgetFrame title="Notes">
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Box>
          <TextField
            label="Add Note"
            multiline
            rows={6}
            value={note}
            onChange={(e) => {
              setNote(e.target.value);
            }}
            fullWidth
            variant="outlined"
          />
        </Box>
        <Box>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              onAddNote(note);
              setNote("");
            }}
            sx={{ mr: 1 }}
          >
            Add
          </Button>
        </Box>
      </Box>
    </WidgetFrame>
  );
};

export default AddNote;
