/********************************************************************
 *
 * Collection.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { CardList, LinkCard2, CardAction } from "../../../lib";
import {
  RateClassSummaryHeader,
  RateClassSummary,
} from "../../RateClass/Identity/RateClassSummaryHeader";

/**
 * Collection
 *
 * @param {*} param0
 * @returns
 */
const Index = ({ rateClasses, onDelete }) => {
  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle Methods
  //
  ///////////////////////////////////////////////////////////////////////

  if (!rateClasses) return "";

  return (
    <CardList
      xs={12}
      sm={6}
      md={6}
      lg={6}
      xl={4}
      spacing={2}
      items={rateClasses}
      onRenderCard={({ item: rateClass }) => (
        <LinkCard2
          link={`/app/catalog/rateclass/${rateClass.id}`}
          header={<RateClassSummaryHeader rateClass={rateClass} />}
          content={<RateClassSummary rateClass={rateClass} />}
          actions={
            <>
              <CardAction
                to={`/app/catalog/rateclass/${rateClass.id}/partners/`}
                label="Partners"
              />
              <CardAction
                to={`/app/catalog/rateclass/${rateClass.id}`}
                label="Rates"
              />
              <CardAction to="#" label="Delete" />
            </>
          }
        />
      )}
    />
  );
};

export default Index;
