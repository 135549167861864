/********************************************************************
 *
 * SalesByProduct.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Receipt } from "@mui/icons-material";
import moment from "moment-timezone";
import { Main } from "../../lib";
import format from "../../../utils/format";
import { useApp, useAPI } from "../../../providers/AppProvider";

/**
 * SalesByProduct
 *
 * Renders a sales-by-product report
 *
 */
const SalesByProduct = () => {
  const [entries, setEntries] = useState(null);
  const [start, setStart] = useState(moment().endOf("day"));
  const [end, setEnd] = useState(moment().startOf("month"));
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading");

    fetchSales(start, end)
      .then((entries) => {
        setEntries(entries);
        setLoaded(true);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setEntries(null);
        setLoaded(true);
      });
  }, []);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  const sortByAccount = (entries) => {
    return entries;
  };

  /**
   * FetchSales
   *
   * Calls the api to fetch the partners assigned to the product type
   *
   */
  const fetchSales = (start, end) => {
    return new Promise((resolve, reject) => {
      api
        .fetch(
          `/api/accounts/account/${
            process.env.REACT_APP_ACCOUNT_REVENUE
          }/entries/${format.toApiDateTime(start)}/${format.toApiDateTime(end)}`
        )
        .then((envelope) => {
          resolve(sortByAccount(envelope.payload));
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  return "SALES";
  return (
    <Main className="container" loaded={loaded}>
      {!entries && "No entries available"}
      {entries && (
        <>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <div className="page-title">
              <Receipt />
              Chart of Accounts
              <div className="small">Balance as of {format.date(start)}</div>
            </div>
            <div className="btn-toolbar mb-2 mb-md-0">
              <div className="btn-group mr-2" />
            </div>
          </div>
        </>
      )}
    </Main>
  );
};

export default SalesByProduct;
