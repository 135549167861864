/********************************************************************
 *
 * Header.jsx
 *
 * Standard header that contains branding, nav and authentication
 * credentials for the application.
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 ********************************************************************/

import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  Link,
  Avatar,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import { MenuRounded } from "@mui/icons-material";
import MainMenu from "./MainMenu";
import SideMenu from "./SideMenu";
import { useAuth, useUser } from "../../../providers/AuthProvider";
import items from "../../../data/navigation/menuItems";

/**
 * Header
 *
 * Renders the App header.
 *
 * @returns
 *
 */
const Header = () => {
  const [menu, setMenu] = useState(false);
  const [anchorEl, setAnchourEl] = useState(null);
  const auth = useAuth();
  const user = useUser();

  /**
   * onToggleMenu
   *
   * Toggles the visibility of the side menu
   *
   * @param event
   * @param open
   */
  const onToggleMenu = (event, state) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setMenu(state);
  };

  return (
    <AppBar className="app-header" color="default" elevation={4}>
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "100%",
          width: "100%",
        }}
      >
        <Link href="/app/">
          <img
            src={process.env["REACT_APP_BRAND_PROFILE_IMG"]}
            alt="Canadian Coastal"
            style={{ padding: "0.5em" }}
          />
        </Link>
        <MainMenu items={items} />
        <SideMenu
          items={items}
          open={menu}
          onClose={(e) => onToggleMenu(e, false)}
        />
        <Box>
          <IconButton
            onClick={(e) => {
              setAnchourEl(e.currentTarget);
            }}
            size="large"
          >
            <Avatar
              src={user.imageUrl}
              sx={{ width: "60px", height: "60px", bgcolor: "primary.main" }}
            >{`${user.fname[0]}${user.lname[0]}`}</Avatar>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={!!anchorEl}
            onClose={() => {
              setAnchourEl(null);
            }}
          >
            <MenuItem onClick={auth.logout}>Logout</MenuItem>
          </Menu>
          <IconButton
            onClick={(e) => onToggleMenu(e, true)}
            aria-label="Menu"
            size="large"
            sx={{ display: { sm: "inline-flex", md: "none" } }}
          >
            <MenuRounded />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
