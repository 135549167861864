/********************************************************************
 *
 * Week.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import Day from "./Day";
import moment from "moment";

/**
 * Week
 *
 * Renders a row of seven CalandarDays on the calendar grid.
 */
const Week = ({ startDate, selectedDate, onDateRender, onClickDate }) => {
  const classes = useStyles();

  return (
    <div className={classes.week}>
      {[...Array(7).keys()].map((key, index) => (
        <Day
          key={key}
          date={moment(startDate).add(index, "d")}
          selectedDate={selectedDate}
          onDateRender={onDateRender}
          onClickDate={onClickDate}
        />
      ))}
    </div>
  );
};

const useStyles = makeStyles({
  week: {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-evenly",
    alignItems: "stretch",
    alignContent: "stretch",
    width: "100% !important",
    height: "100%",
    minHeight: "60px",
    borderBottom: "1px solid rgb(235, 235, 235) !important",
  },
});

export default Week;
