/********************************************************************
 *
 * Employee.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Box, Avatar } from "@mui/material";
import { Main } from "../../lib";
import Shifts from "./Shifts";
import Access from "./Access";
import Settings from "./Settings";
import Details from "./Details";
import { useApp, useAPI } from "../../../providers/AppProvider";

/**
 * Employee
 *
 * Renders information about an employee
 *
 * @param {*} props
 */
const Employee = () => {
  const [employee, setEmployee] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  const { id } = useParams();

  useEffect(() => {
    fetchEmployee(id);
  }, [id]);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  const fetchEmployee = (id) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading");

    api
      .fetch(`/api/employees/employee/${id}`)
      .then(({ payload: employee }) => {
        setEmployee(employee);
        setLoaded(true);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Main
      header={{
        icon: employee && <Avatar src={employee.imageUrl} />,
        title: employee
          ? `${employee.fname} ${employee.lname}`
          : "New Employee",
        subtitle: employee ? employee.role : "",
      }}
      loaded={loaded}
    >
      {employee && (
        <Box
          sx={{
            display: "grid",
            gap: 4,
            gridTemplateColumns: "repeat(12, 1fr)",
            gridTemplateAreas:
              '"access access access access settings settings settings settings details details details details" "shifts shifts shifts shifts shifts shifts shifts shifts details details details details"',
          }}
        >
          <Box sx={{ gridColumn: { gridArea: "access" } }}>
            <Access employee={employee} />
          </Box>
          <Box sx={{ gridColumn: { gridArea: "settings" } }}>
            <Settings employee={employee} />
          </Box>
          <Box sx={{ gridArea: "details" }}>
            <Details employee={employee} />
          </Box>
          <Box sx={{ gridArea: "shifts" }}>
            <Shifts employee={employee} start={"2019-06-01T00:00:00Z"} />
          </Box>
        </Box>
      )}
    </Main>
  );
};

export default Employee;
