import Goods from "../components/Goods";

//
//  GoodTypes
//
import GoodTypes from "../components/Goods/GoodTypes";

//
//  Tour Type
//
import TourType from "../components/Goods/TourType";

//
//  Merchandise Type
//
import MerchType from "../components/Goods/MerchType";

//
//  Third Party Good Type
//
import SupplierGoodType from "../components/Goods/ExternalSupplierType";
import SupplierScheduler from "../components/Goods/ExternalSupplierType/SupplierScheduler";

//
//  Tour
//
import Tour from "../components/Goods/Tour";
import TourScheduler from "../components/Goods/Tour/TourScheduler";

//
//  Third Party Good
//
import SupplierGood from "../components/Goods/SupplierGood";

import Notifications from "../components/Goods/libs/Notification";

import Ticket from "../components/Goods/Tickets/Ticket";

import Redirect from "./Redirect";

export default {
  path: "/app/goods/",
  element: <Goods />,
  children: [
    {
      path: "/app/goods/",
      element: <GoodTypes />,
    },
    {
      path: "/app/goods/tourtype/:goodTypeId",
      element: <TourType />,
      children: [
        {
          path: "/app/goods/tourtype/:goodTypeId/schedule",
          element: <TourScheduler />,
        },
        {
          path: "/app/goods/tourtype/:goodTypeId/notifications",
          element: <Notifications />,
        },
        {
          path: "/app/goods/tourtype/:goodTypeId",
          element: <Redirect to={`/app/goods/tourtype/:goodTypeId/schedule`} />,
        },
      ],
    },
    {
      path: "/app/goods/merchtype/:merchTypeId",
      element: <MerchType />,
    },
    {
      path: "/app/goods/suppliertype/:goodTypeId",
      element: <SupplierGoodType />,
      children: [
        {
          path: "/app/goods/suppliertype/:goodTypeId/schedule",
          element: <SupplierScheduler />,
        },
        {
          path: "/app/goods/suppliertype/:goodTypeId/notifications",
          element: <Notifications />,
        },
        {
          path: "/app/goods/suppliertype/:goodTypeId",
          element: (
            <Redirect to={`/app/goods/suppliertype/:goodTypeId/schedule`} />
          ),
        },
      ],
    },
    {
      path: "/app/goods/tour/:id",
      element: <Tour />,
    },
    {
      path: "/app/goods/tour/",
      element: <Tour />,
    },
    {
      path: "/app/goods/ticket/:id",
      element: <Ticket />,
    },
    {
      path: "/app/goods/supplier/:id",
      element: <SupplierGood />,
    },
    {
      path: "/app/goods/supplier/",
      element: <SupplierGood />,
    },
  ],
};
