/********************************************************************
 *
 * /src/components/catalog/products/features/Index.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { Link } from "react-router";
import { Typography } from "@mui/material";
import { DirectionsBoatOutlined } from "@mui/icons-material";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  timelineItemClasses,
} from "@mui/lab";
import { WidgetFrame } from "../../../lib";
import format from "../../../../utils/format";

/**
 * Features
 *
 * Renders a list of Features.
 *
 */
const Features = ({ features }) => {
  return !features ? (
    "No Features Available"
  ) : (
    <WidgetFrame title="Product Features">
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {features.map((feature, index) => (
          <React.Fragment key={index}>
            <TimelineItem
              sx={{
                "&:missingOppositeContent": { "&:before": { display: "none" } },
              }}
            >
              <TimelineSeparator>
                <TimelineDot color="primary">
                  <DirectionsBoatOutlined />
                </TimelineDot>
                <TimelineConnector></TimelineConnector>
              </TimelineSeparator>
              <TimelineContent
                sx={{
                  width: "225px",
                }}
              >
                <Typography variant="body2" color="textSecondary">
                  {feature.start && format.time(feature.start)}
                </Typography>
                <Link
                  to={`/app/goods/tour/${feature.goodId}`}
                  sx={{
                    display: "block",
                    padding: "8px 16px",
                    "&:hover": {
                      backgroundColor: "#f8f8f8",
                      color: "#666666",
                      textDecoration: "none",
                    },
                  }}
                >
                  <Typography variant="subtitle2" component="div">
                    {feature.quantity} x {feature.name}
                  </Typography>
                  <Typography variant="caption" component="div">
                    Tour {feature.goodId}
                  </Typography>
                </Link>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem
              sx={{
                "&:missingOppositeContent": { "&:before": { display: "none" } },
              }}
            >
              <TimelineSeparator>
                <TimelineDot>
                  <DirectionsBoatOutlined />
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="body2" color="textSecondary">
                  {feature.end && format.time(feature.end)}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          </React.Fragment>
        ))}
      </Timeline>
    </WidgetFrame>
  );
};

export default Features;
