/********************************************************************
 *
 * Tickets.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import { Grid2 as Grid, Typography } from "@mui/material";
import { Alert } from "@mui/material";
import { ConfirmationNumber, ChildCare } from "@mui/icons-material";
import { LinkCard, CardList, CardAction } from "./index";
import format from "../../utils/format";

/**
 * Tickets
 *
 * Renders a card deck of Tickets.
 */
const Tickets = ({ tickets, tz }) => {
  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  if (!tickets) return null;

  return (
    <CardList
      xs={12}
      items={tickets}
      spacing={2}
      headerBG="#01899f"
      onRenderCard={({ item: ticket }) => (
        <LinkCard
          link={`/app/goods/ticket/${ticket.id}`}
          header={<TicketHeader ticket={ticket} />}
          content={<TicketSummary ticket={ticket} tz={tz} />}
          actions={
            <>
              <CardAction to="#" label="Reschedule" />
              <CardAction to="#" label="Delete" />
            </>
          }
        />
      )}
    />
  );
};

const TicketHeader = ({ ticket }) => {
  const { customer, good } = ticket;

  return (
    <Grid
      container
      justifyContent="space-between"
      sx={{
        backgroundColor: "primary.main",
        paddingLeft: 2,
        paddingRight: 2,
        paddingTop: 1,
        paddingBottom: 1,
        color: "white",
      }}
    >
      <Grid item>
        <ConfirmationNumber style={{ fontSize: "1em", marginRight: "1em" }} />
        {customer ? (
          customer.isMinor ? (
            <>
              <Typography variant="subtitle1" component="span">
                {customer.minorName}
              </Typography>
              <ChildCare style={{ fontSize: "1.5em", marginLeft: "0.75em" }} />
            </>
          ) : (
            <>
              <Typography variant="subtitle1" component="span">
                {`${customer.fname} ${customer.lname}`}
              </Typography>
            </>
          )
        ) : (
          ""
        )}
      </Grid>
      <Grid sx={{ textAlign: "right" }}>
        <Typography variant="subtitle1">{good.name}</Typography>
      </Grid>
    </Grid>
  );
};

const TicketSummary = ({ ticket, tz }) => {
  const { good, groupName, customer, timestamp } = ticket;

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Typography variant="subtitle2">Order</Typography>
        <Typography variant="subtitle1">{groupName}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="subtitle2">Issued</Typography>
        <Typography variant="subtitle1">
          {format.dateTime(timestamp, tz)}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="subtitle2">Supplier</Typography>
        <Typography variant="subtitle1">{good.supplier.name}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2">Rate Class</Typography>
        <Typography variant="subtitle1">{ticket.rateClass.name}</Typography>
      </Grid>
      {!!customer && !!customer.isMinor && (
        <>
          <Grid item xs={4}>
            Age: {customer.age}
          </Grid>
          <Grid item xs={4}>
            Weight: {customer.weight}
          </Grid>
          <Grid item xs={4}>
            Height: {customer.height}
          </Grid>
        </>
      )}
      {!!customer && (
        <>
          <Grid
            item
            xs={12}
            sx={{
              borderTop: "solid 1px #eeeeee",
              display: !customer.medical ? "none" : "inherit",
            }}
          >
            <Typography>Medical</Typography>
            <Alert severity="info">{customer.medical}</Alert>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              borderTop: "solid 1px #eeeeee",
              display:
                !customer.allergies || customer.allergies.length === 0
                  ? "none"
                  : "inherit",
            }}
          >
            <Typography className="small">Allergies</Typography>
            <Alert severity="info">{customer.allergies}</Alert>
          </Grid>
        </>
      )}
    </Grid>
  );
};

/*
 **  Tickets
 */
Tickets.propTypes = {
  tickets: PropTypes.array,
  tz: PropTypes.string,
};

export default Tickets;
