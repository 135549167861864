/********************************************************************
 *
 * Index.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState } from "react";
import { useOutletContext } from "react-router";
import { Link, Box, Card, Typography, Button } from "@mui/material";
import { AddShoppingCart } from "@mui/icons-material";
import moment from "moment-timezone";
import { WidgetFrame, TipChip } from "../../../lib";
import Calendar from "../../../lib/Calendar/Calendar";
import format from "../../../../utils/format";
import { useApp, useAPI } from "../../../../providers/AppProvider";
import ProductFactory from "./ProductFactory";

/**
 * Products
 *
 * Renders a calendar-based view of the products belonging to
 * a product type.
 *
 */
const Products = () => {
  const [products, setProducts] = useState(null);
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [tz] = useState("America/Vancouver");
  const [dateRange, setDateRange] = useState(null);
  const [showAddProduct, setShowAddProduct] = useState(false);
  const { productType } = useOutletContext();
  const app = useApp();
  const api = useAPI();

  ///////////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////////

  /**
   * FetchProducts
   *
   * Calls the API to fetch the products for the dates visible on the
   * calendar.
   *
   * @param {*} startDate
   * @param {*} endDate
   */
  const fetchProducts = (startDate, endDate) => {
    let _startDate, _endDate;

    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading");

    return new Promise((resolve, reject) => {
      if (!startDate) throw new Error("Start date not defined");
      if (!endDate) throw new Error("End date not defined");

      //
      //  Request data for the timezone-adjusted start of day
      //
      _startDate = format.getMomentLocalized(startDate, tz).startOf("day");
      _endDate = format.getMomentLocalized(endDate, tz).endOf("day");

      if (!_startDate.isValid()) throw new Error("Invalid start date");
      if (!_endDate.isValid()) throw new Error("Invalid end date");

      if (_endDate.isBefore(_startDate)) {
        resolve(null);
        return;
      }

      //
      //  Request searches
      //
      api
        .fetch(
          `/api/producttypes/producttype/${
            productType.id
          }/products/daterange/${format.toApiDateTime(
            _startDate
          )}/${format.toApiDateTime(_endDate)}`
        )
        .then(({ payload: products }) => {
          setProducts(products);
          app.endNotify(notifyHandle);
        })
        .catch((error) => {
          app.error({ error });
          setProducts(null);
        });
    });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////////

  /**
   * OnCalendarUpdate
   *
   * Fired when the dates on the calendar change.
   *
   * Fetches products for dates visible on the calendar.
   *
   * @param {*} event
   */
  const onCalendarUpdate = (event) => {
    setDateRange(event);

    if (event && event.startDate && event.endDate)
      fetchProducts(event.startDate, event.endDate);
  };

  const onRefresh = () => {
    if (dateRange) fetchProducts(dateRange.startDate, dateRange.endDate);
  };

  /**
   * OnDateRender
   *
   * Fired when a calendar date renders.
   *
   * Determines if there are tours on the specified date and
   * marks up the calendar accordingly.
   *
   * @param {*} event
   */
  const onDateRender = ({ date }) => {
    let dayProducts = [];

    for (let i = 0; products && i < products.length; i++) {
      let product = products[i];
      if (
        moment
          .tz(product.start, product.timezone)
          .isSame(moment.tz(date, product.timezone), "day")
      )
        dayProducts.push(product);
    }

    return (
      <Box
        sx={[
          { paddingLeft: "2px", height: "100%", width: "100%" },
          !!dayProducts.length && {
            backgroundColor: "#def1ed",
          },
        ]}
      >
        <Typography variant="body2">{format.mday(date)}</Typography>
        <Box sx={{ margin: "0.1em" }}>
          {dayProducts.map((dayProduct, index) => (
            <Typography
              key={index}
              component="div"
              sx={{ fontSize: "10px", lineHeight: "10px" }}
            >
              {format.time(dayProduct.start, dayProduct.timezone)}
            </Typography>
          ))}
        </Box>
      </Box>
    );
  };

  /**
   * onSelectedDateChanged
   *
   * Fired when the user clicks a date cell in the calendar.
   *
   * @param {event} event
   */
  const onSelectedDateChanged = ({ date }) => {
    setSelectedDate(date);
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Box>
      <Box sx={{ display: "flex", gap: 4 }}>
        <Box sx={{ flex: { xs: "1 1 50%" } }}>
          <WidgetFrame>
            <Calendar
              date={undefined}
              width={500}
              onCalendarUpdating={onCalendarUpdate}
              onDateRender={onDateRender}
              onSelectedDateChanged={onSelectedDateChanged}
            />
          </WidgetFrame>
        </Box>
        <Box sx={{ flex: { xs: "1 1 50%" } }}>
          <WidgetFrame>
            <Agenda products={products} date={selectedDate} tz={tz} />
          </WidgetFrame>
        </Box>
      </Box>
      {dateRange && (
        <Box mt={2}>
          <Button
            variant="outlined"
            onClick={() => {
              setShowAddProduct(true);
            }}
          >
            Add Products
          </Button>
          {showAddProduct && (
            <ProductFactory
              productType={productType}
              start={dateRange.startDate}
              end={dateRange.endDate}
              show={showAddProduct}
              onClose={() => {
                setShowAddProduct(false);
                onRefresh();
              }}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

/**
 * Agenda
 *
 * @param {*} props
 */
const Agenda = ({ products, date, tz }) => (
  <Box>
    <Typography variant="h4" component="p">
      {date ? format.dayDate(date, tz) : "No date selected"}
    </Typography>
    <hr />
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      {products &&
        products.map(
          (product, index) =>
            moment(product.start).isSameOrBefore(moment(date), "day") &&
            moment(product.end).isSameOrAfter(moment(date), "day") && (
              <AgendaItem key={index} product={product} />
            )
        )}
    </Box>
  </Box>
);

/**
 * AgendaItem
 *
 * @param {*} param0
 *
 * @returns
 */
const AgendaItem = ({ product }) => {
  const _start = moment(product.start);
  const _end = moment(product.end);

  return (
    <Card elevation={0}>
      <Link
        href={`/app/catalog/product/${product.id}`}
        sx={{
          display: "block",
          padding: 2,
          color: "#666666",
          textDecoration: "none",
          "&:hover": {
            backgroundColor: "background.default",
            color: "#666666",
            textDecoration: "none",
          },
        }}
      >
        <Box
          sx={{
            display: "grid",
            gap: 0,
            gridTemplateColumns: "repeat(12, 1fr)",
          }}
        >
          <Box sx={{ gridColumn: { xs: "1/span 8" } }}>
            <Typography variant="body">
              {`#${product.id} | ${product.name}`}
            </Typography>
            {format.isSameDay(product.start, product.end, product.tinezone) ? (
              <Typography variant="body">
                {` from ${format.time(
                  product.start,
                  product.timezone
                )} to ${format.time(product.end, product.timezone)}`}
              </Typography>
            ) : (
              <TipChip label={"multiday"} tip="Multi Day Product" />
            )}
          </Box>
          <Box sx={{ gridColumn: { xs: "9/span 4", justifySelf: "flex-end" } }}>
            <TipChip
              label={product.available}
              icon={<AddShoppingCart />}
              tip="Available"
            />
          </Box>
          <Box sx={{ gridColumn: { xs: "1/span 12" } }}>
            <Typography variant="h6" sx={{ pt: 1 }}>
              Features
            </Typography>
            {Object.keys(product.features).map((key, index) => (
              <Box
                key={index}
                sx={[
                  { display: "flex", flexDirection: "row", gap: 0 },
                  {
                    borderTop: "solid 1px #cccccc",
                    py: 0,
                  },
                ]}
              >
                <Box sx={{ flex: "0 0 100px" }}>
                  <Typography variant="caption">
                    {`${format.time(
                      product.features[key].start
                    )} - ${format.time(product.features[key].end)}`}
                  </Typography>
                </Box>
                <Box sx={{ flex: "1 1 auto" }}>
                  <Typography variant="caption">
                    {`${format.hoursBetween(
                      product.start,
                      product.end,
                      product.timezone
                    )} @ ${product.features[key].name} (${
                      product.features[key].goodId
                    })`}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Link>
    </Card>
  );
};

export default Products;
