/********************************************************************
 *
 * /Sales/Campaigns/Identity/Edit.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { ModalDialog, DialogContent, HorizontalGroup } from "../../../lib";
import format from "../../../../utils/format";
import { useApp, useAPI } from "../../../../providers";

/**
 * CampaignEdit
 *
 * Adds or edits a campaign
 *
 * @param {*} param0
 */
const CampaignEdit = ({ campaign, show, onSaved, onCancelled }) => {
  const app = useApp();
  const api = useAPI();

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnSave
   *
   * Fired when the user commits the change.
   *
   * @param {*} values
   */
  const onSave = (values) => {
    //
    //  Save
    //
    const notifyHandle = app.notify("Saving Campaign");

    api
      .update(
        `/api/campaigns/campaign/${campaign && campaign.id ? campaign.id : ""}`,
        values
      )
      .then(({ payload: campaign }) => {
        onSaved && onSaved(campaign);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  /**
   * OnCancel
   *
   * Fired when the user aborts changes.
   *
   */
  const onCancel = (dirty) => {
    if (
      !dirty ||
      window.confirm(
        "You have unsaved changes. Are you sure you want to close this dialog and loose your changes?"
      )
    ) {
      onCancelled && onCancelled();
    }
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <ModalDialog
      title="Manage Campaign"
      subtitle="Campaigns for the basis of tracking marketting efforts"
      show={!!show}
    >
      <DialogContent>
        <FormBody campaign={campaign} onSave={onSave} onCancel={onCancel} />
      </DialogContent>
    </ModalDialog>
  );
};

/**
 * FormBody
 *
 * @param {*} props
 */
const FormBody = ({ campaign, onSave, onCancel }) => (
  <Formik
    initialValues={
      campaign || { name: "", description: "", start: null, end: null, ref: "" }
    }
    enableReinitialize={true}
    onSubmit={(values) => {
      values.start = format.toApiDateTime(values.start);
      values.end = format.toApiDateTime(values.end);
      onSave(values);
    }}
    validationSchema={yup.object().shape({
      name: yup.string().nullable().required("Campaign name is required"),
      description: yup
        .string()
        .nullable()
        .required("Campaign description is required"),
      start: yup
        .string()
        .nullable()
        .required("Campaign start date is required"),
      end: yup.string().nullable().required("Campaign end date is required"),
    })}
  >
    {({
      values,
      touched,
      errors,
      dirty,
      isSubmitting,
      setFieldValue,
      handleChange,
      handleBlur,
      handleSubmit,
    }) => {
      return (
        <form onSubmit={handleSubmit}>
          <Box display="flex" justifyContent="flex-end">
            <HorizontalGroup gap={1}>
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                disabled={!dirty || isSubmitting}
              >
                Save
              </Button>
              <Button onClick={() => onCancel(dirty)}>Cancel</Button>
            </HorizontalGroup>
          </Box>
          <CampaignForm
            campaign={values}
            touched={touched}
            errors={errors}
            setFieldValue={setFieldValue}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </form>
      );
    }}
  </Formik>
);

const CampaignForm = ({
  campaign,
  touched,
  errors,
  setFieldValue,
  onBlur,
  onChange,
}) => {
  return (
    <Box>
      <TextField
        id="name"
        type="text"
        label="Name"
        placeholder="Name"
        value={campaign.name}
        onBlur={onBlur}
        onChange={onChange}
        fullWidth
        required
        error={errors.name && touched.name}
        helperText={errors.name || " "}
      />
      <TextField
        id="description"
        label="Description"
        value={campaign.description}
        onBlur={onBlur}
        onChange={onChange}
        multiline
        rows="2"
        variant="outlined"
        fullWidth
        required
        error={errors.description && touched.description}
        helperText={errors.description || " "}
      />
      <TextField
        id="start"
        label="Start"
        type="date"
        value={format.toApiDate(campaign.start)}
        onBlur={onBlur}
        onChange={onChange}
        variant="outlined"
        fullWidth
        required
        error={errors.start && touched.start}
        helperText={errors.start || " "}
      />
      <TextField
        id="end"
        label="End"
        type="date"
        value={format.toApiDate(campaign.end)}
        onBlur={onBlur}
        onChange={onChange}
        variant="outlined"
        fullWidth
        required
        error={errors.end && touched.end}
        helperText={errors.end || " "}
      />
      <TextField
        id="ref"
        label="External Reference"
        value={campaign.ref}
        onBlur={onBlur}
        onChange={onChange}
        fullWidth
        error={errors.ref && touched.ref}
        helperText={errors.ref || " "}
      />
    </Box>
  );
};

export default CampaignEdit;
