/********************************************************************
 *
 * ConfigSwitchSelect.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, Switch, Select, MenuItem } from "@mui/material";

const ConfigSwitchSelect = ({
  title,
  switchId,
  switchValue,
  valueId,
  value,
  options,
  metric,
  onChangeConfig,
}) => {
  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Box sx={{ pb: 2 }}>
      <Typography>{title}</Typography>
      <Box display="flex" alignItems="baseline">
        <Switch
          name={switchId}
          checked={switchValue}
          value="1"
          onChange={({ target }) => {
            onChangeConfig({
              key: target.name,
              value: target.checked ? 1 : 0,
            });
          }}
          sx={{
            mr: 2,
          }}
        />
        <Select
          name={valueId}
          labelId={`${valueId}-label`}
          value={value}
          onChange={({ target }) => {
            onChangeConfig({ key: target.name, value: target.value });
          }}
          sx={{
            mr: 2,
          }}
        >
          {options.map((item, index) => (
            <MenuItem key={index} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
        <Typography component="span">{metric}</Typography>
      </Box>
    </Box>
  );
};

/*
 **  PropTypes
 */
ConfigSwitchSelect.propTypes = {
  title: PropTypes.string,
  switchId: PropTypes.string.isRequired,
  switchValue: PropTypes.any.isRequired,
  valueId: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChangeConfig: PropTypes.func.isRequired,
};

export default ConfigSwitchSelect;
