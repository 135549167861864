/********************************************************************
 *
 * Index.jsx
 *
 * Renders a control to manage the ticket events. Uses the reducer
 * pattern, so the host needs to pass a dispatch function and actions
 * objects, and handle the INITIALIZE_EVENTS and TICKET_EVENT actions.
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot,
  TimelineSeparator,
} from "@mui/lab";
import { WidgetFrame, PostEditor } from "../../../../lib";
import format from "../../../../../utils/format";
import {
  useApp,
  useMaintenanceAPI,
} from "../../../../../providers/AppProvider";
import Actions from "./actions";

const EVENT_TYPES = {
  CREATEDBY: 1,
  OPENEDBY: 2,
  ASSIGNEDTO: 3,
  COMMENTBY: 4,
  COMPLETEDBY: 5,
  CLOSEDBY: 6,
};

/**
 * Index
 *
 * @param {*} param0
 *
 * @returns
 */
const Index = ({ ticket, reducer: { state, dispatch, actions } }) => {
  const app = useApp();
  const maintenanceAPI = useMaintenanceAPI();

  useEffect(() => {
    fetchEvents();
  }, []);

  /**
   * FetchEvents
   */
  const fetchEvents = () => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading Events");

    maintenanceAPI
      .fetch(`/api/tickets/ticket/${ticket.id}/events`)
      .then(({ payload: events }) => {
        dispatch({ type: actions.INITIALIZE_EVENTS, payload: events });
      })
      .catch((error) => {
        app.error({ error });
      })
      .finally(() => {
        app.endNotify(notifyHandle);
      });
  };

  /**
   * AddComment
   *
   * @param {*} post
   */
  const addComment = (post) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Saving...");

    maintenanceAPI
      .create(`/api/tickets/ticket/${ticket.id}/note`, {
        message: post.message,
      })
      .then(({ payload: event }) => {
        dispatch({ type: actions.TICKET_EVENT, payload: event });
      })
      .catch((error) => {
        app.error({ error });
      })
      .finally(() => {
        app.endNotify(notifyHandle);
      });
  };

  if (!ticket) return "Ticket cannot be null";

  return (
    <>
      <WidgetFrame>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, pt: 2 }}>
          <PostEditor edit disabled={!state.active} onPost={addComment} />
          <Actions ticket={ticket} reducer={{ state, dispatch, actions }} />
        </Box>
      </WidgetFrame>
      <WidgetFrame title="Activity">
        <Timeline position="right">
          {state.events &&
            state.events.reverse().map((event) => (
              <React.Fragment key={event.id}>
                {event.type.id != EVENT_TYPES.COMMENTBY ? (
                  <TimelineItem key={event.id} sx={{ minHeight: "50px" }}>
                    <TimelineOppositeContent
                      variant="caption"
                      sx={{
                        "&.MuiTypography-root": { alignSelf: "center" },
                        color: "#999999",
                      }}
                    >
                      {format.date(event.timestamp)}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineConnector />
                      <TimelineDot></TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: "12px", px: 2, py: 0.75 }}>
                      <Typography variant="h6" component="span">
                        {`${event.type.name}`}
                      </Typography>
                      <Typography variant="body2">
                        {event.user.fname} {event.user.lname}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                ) : (
                  <Box component="li" sx={{ py: 2, listStyle: "none" }}>
                    <PostEditor
                      author={`${event.user.fname} ${event.user.lname}`}
                      date={event.timestamp}
                      value={event.description}
                    />
                  </Box>
                )}
              </React.Fragment>
            ))}
        </Timeline>
      </WidgetFrame>
    </>
  );
};

/**
 * PropTypes
 */
Index.propTypes = {
  ticket: PropTypes.object,
  reducer: PropTypes.object.isRequired,
};

export default Index;
