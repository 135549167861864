/********************************************************************
 *
 * FeatureType.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

/**
 * FeatureType
 *
 * @param {*} param0
 *
 * @returns
 */
const FeatureType = ({ featureType }) => (
  <Typography variant="h5">{featureType.name}</Typography>
);

/**
 * PropTypes
 */
FeatureType.propTypes = {
  featureType: PropTypes.object.isRequired,
};

export default FeatureType;
