/********************************************************************
 *
 * Book.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import SyncButton from "../../../../lib/SyncButton";
import PropTypes from "prop-types";
import { useApp, useAPI } from "../../../../../providers/AppProvider";

/**
 * Book
 *
 */
const Book = ({ order, onUpdated }) => {
  const [processing, setProcessing] = useState(false);
  const app = useApp();
  const api = useAPI();

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////

  const processSale = (order) => {
    return new Promise((resolve, reject) => {
      setProcessing(true);

      api
        .create(`/api/orders/order/${order.id}/sale`, {
          application: "admin",
        })
        .then(({ payload: order }) => {
          setProcessing(false);
          resolve(order);
        })
        .catch((error) => {
          setProcessing(false);
          reject(error);
        });
    });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  const onBook = (event) => {
    event.preventDefault();

    processSale(order)
      .then((order) => {
        onUpdated(order);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  return (
    <LoadingButton variant="contained" loading={!!processing} onClick={onBook}>
      Book
    </LoadingButton>
  );
};

Book.propTypes = {
  order: PropTypes.object.isRequired,
  onUpdated: PropTypes.func.isRequired,
};

export default Book;
