/********************************************************************
 *
 * Sku.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState } from "react";
import { useNavigate } from "react-router";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Typography,
  Box,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import format from "../../../../utils/format";
import { useApp, useAPI } from "../../../../providers/AppProvider";
import { useOrder } from "../../../../providers/OrderProvider";
import PropTypes from "prop-types";

/**
 * Sku
 *
 * Renders a product sku widget.
 */
const Sku = ({ sku, canBook }) => {
  const [numPAX, setNumPAX] = useState(null);
  const [dirty, setDirty] = useState(false);
  const navigate = useNavigate();
  const currOrder = useOrder();
  const app = useApp();
  const api = useAPI();

  console.log(sku);

  ///////////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////////

  /**
   * OnChangeNumPAX
   *
   * Fired when the number of PAX is changed.
   *
   * @param {*} e
   */
  const onChangeNumPAX = (event) => {
    setNumPAX(event.target.value);
    setDirty(true);
  };

  /**
   * OnCreateOrder
   *
   * Fired when the user books a product.
   *
   * Creates an order with the selected SKU and navigates to the order.
   *
   * @param {*} skuId
   * @param {*} pax
   */
  const onCreateOrder = (skuId, pax) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Booking");

    //
    //  Create new order
    //
    api
      .create(
        `/api/orders/order/${!!currOrder.order ? currOrder.order.id : ""}`,
        {
          items: [
            {
              skuId: skuId,
              qty: pax,
            },
          ],
        }
      )
      .then(({ payload: order }) => {
        navigate(`/app/sales/order/${order.id}`);
      })
      .catch((error) => {
        app.error({ error });
      })
      .finally(() => {
        app.endNotify(notifyHandle);
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  if (!sku)
    return <div className="spinner-border text-secondary" role="status" />;

  return (
    <Card
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <CardHeader
        title={`${sku.rateClass.name}`}
        subheader={`${format.shortDate(sku.open)} - ${format.shortDate(
          sku.close
        )}`}
        sx={{ backgroundColor: "#eeeeee" }}
        data-toggle="modal"
        data-target={`#skuModal${sku.id}`}
      />
      <CardContent
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="caption">{sku.rateClass.description}</Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography component="div">{format.currency(sku.rate)}</Typography>
          <Typography component="div">x</Typography>
          <FormControl variant="outlined" size="small">
            <Select
              onChange={onChangeNumPAX}
              defaultValue="-1"
              disabled={!canBook}
            >
              <MenuItem value={-1} disabled>
                PAX
              </MenuItem>
              {(() => {
                let items = [];

                for (let i = 1; i <= sku.available; i++) {
                  items.push(
                    <MenuItem key={i} value={i}>
                      {i}
                    </MenuItem>
                  );
                }
                return items;
              })()}
            </Select>
          </FormControl>
        </Box>
      </CardContent>
      <CardActions sx={{ justifyContent: "flex-end" }}>
        {!canBook ? (
          "Unable to book - check for valid rate."
        ) : (
          <Button
            variant="contained"
            disabled={!dirty}
            onClick={() => onCreateOrder(sku.id, numPAX)}
          >
            Book
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

//
//  PropTypes
//
Sku.propTypes = {
  sku: PropTypes.object.isRequired,
  canBook: PropTypes.bool,
};

export default Sku;
