/********************************************************************
 *
 * /CRM/Reviews/Identity/Header.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { Box, Typography, Tooltip, Chip, Switch } from "@mui/material";
import { Rating } from "@mui/lab";
import { CardContentHeader } from "../../../lib";
import format from "../../../../utils/format";
import { useApp, useAPI } from "../../../../providers/AppProvider";

/**
 * ReviewHeader
 *
 * Displays header information for a Review.
 */
const ReviewHeader = ({ review, onUpdate }) => {
  const app = useApp();
  const api = useAPI();

  /**
   * OnSave
   *
   * Fired when the user commits the change.
   *
   * @param {*} values
   */
  const onSave = (values) => {
    //
    //  Save
    //
    const notifyHandle = app.notify("Saving Review");

    api
      .update(`/api/reviews/review/${review.id ? review.id : ""}`, values)
      .then(({ payload: review }) => {
        onUpdate && onUpdate(review);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  return (
    <CardContentHeader
      avatar={
        !!onUpdate ? (
          <Tooltip title="Display Status" aria-label="Display Status">
            <Switch
              checked={!!review.display}
              name="publish"
              color="primary"
              onChange={(e) => {
                onSave({ ...review, display: !review.display });
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </Tooltip>
        ) : review.display ? (
          <Chip label="Live" variant="outlined" color="primary" />
        ) : (
          <Chip label="Inctive" color="default" />
        )
      }
      content={
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="no-wrap"
          justifyContent="space-between"
          style={{ width: "100%" }}
        >
          <Box>
            <Typography variant="h5">
              {review.author}, {review.location}
            </Typography>
            <Typography variant="caption">
              {format.date(review.date)}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="flex-end">
            <Box>
              <Rating value={review.rating} precision={0.1} readOnly />
            </Box>
            {!!review.source && (
              <Box>
                <Chip label={review.source.name} color="primary" size="small" />
              </Box>
            )}
          </Box>
        </Box>
      }
    />
  );
};

export default ReviewHeader;
