/********************************************************************
 *
 * RateClassPolicies.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { useOutletContext } from "react-router";
import { Box, Typography } from "@mui/material";

const RateClassPolicies = () => {
  const { rateClass } = useOutletContext();

  if (!rateClass || !rateClass.policies) return "Invalid Rate Class";

  return rateClass.policies.map((policy) => (
    <Box key={policy.id}>
      <Typography variant="h3">{policy.name}</Typography>
      <Typography>{policy.description}</Typography>
    </Box>
  ));
};

export default RateClassPolicies;
