/********************************************************************
 *
 * ActivityLog.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import {
  Box,
  CircularProgress,
  Typography,
  IconButton,
  Chip,
} from "@mui/material";
import {
  VerifiedUser,
  MailOutline,
  AccountBox,
  Delete,
  ImageSearch,
  Smartphone,
  Instagram,
  Receipt,
  Error,
  CheckCircleOutline,
} from "@mui/icons-material";
import moment from "moment-timezone";
import format from "../../utils/format";
import { WidgetFrame, ExpansionCard, Property, SysClientName } from "../lib";
import Pagination from "../lib/Pagination";
import { useApp, useAPI } from "../../providers/AppProvider";

const PAGE_SIZE = 5;

/**
 * ActivityLog
 *
 * Displays the Activity Log
 */
const ActivityLog = ({ pageSize: extPageSize }) => {
  const [items, setItems] = useState(null);
  const [itemCount, setItemCount] = useState(null);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [currPage, setCurrPage] = useState(1);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    if (extPageSize) setPageSize(extPageSize);
  }, [extPageSize]);

  /**
   * UseEffect
   *
   * Loads the logs items and then polls for updates.
   */
  useEffect(() => {
    let handle = null;

    fetchLogItems(currPage, pageSize);
    handle = setInterval(() => fetchLogItems(currPage, pageSize), 600000); // 10 minutes

    return () => clearInterval(handle);
  }, [currPage, pageSize]);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * FetchLogitems
   *
   * Calls the api to fetch recent searches
   *
   * @param currPage
   * @param pageSize
   *
   */
  const fetchLogItems = (currPage, pageSize) => {
    //
    //  Request searches
    //
    api
      .fetch(`/api/activitylogs/?page=${currPage}&limit=${pageSize}`)
      .then(({ payload: items, max }) => {
        setItems(items);
        setItemCount(max);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  const onTurnPage = ({ currPage }) => {
    setCurrPage(currPage);
  };

  const onDeleteLogItem = (logItemId) => {
    const notifyHandle = app.notify("Deleteing Log");

    //
    //  Request searches
    //
    api
      .delete(`/api/activitylogs/log/${logItemId}`)
      .then(() => {
        app.endNotify(notifyHandle);
        fetchLogItems(currPage, pageSize);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  if (!items)
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  return (
    <WidgetFrame title="Activity">
      <>
        {items.map((item) => (
          <ActivityItem
            key={item.id}
            item={item}
            onDeleteLogItem={onDeleteLogItem}
          />
        ))}
        <br />
        <Pagination
          className={"small"}
          currPage={currPage}
          totalRecords={itemCount}
          pageSize={pageSize}
          pageNeighbours={1}
          onPageChanged={onTurnPage}
        />
      </>
    </WidgetFrame>
  );
};

/**
 * ActivityItem
 *
 * @param {*} param0
 */
const ActivityItem = ({ item, onDeleteLogItem }) => {
  return (
    <ExpansionCard
      key={item.id}
      header={
        <Box style={{ disply: "flex", flexDirection: "column", flex: 1 }}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingBottom: "8px",
            }}
          >
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Box>
                <ActivityTypeIcon typeId={item.typeId} />
              </Box>
              <Box style={{ paddingLeft: "16px" }}>
                <Typography variant="body2">
                  {format.dayDateTime(moment(item.timestamp))}
                </Typography>
              </Box>
            </Box>
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <Box style={{ display: "flex" }}>
                {item.sourceId != null && (
                  <Box>
                    <Chip
                      variant="outlined"
                      color="primary"
                      size="small"
                      label={<SysClientName id={item.sourceId} />}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box>
            <Box>
              <Typography
                variant="caption"
                component="div"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  overflowWrap: "break-word",
                  wordBreak: "break-all",
                }}
              >
                {item.description}
              </Typography>
            </Box>
          </Box>
        </Box>
      }
      content={
        <Box>
          {item.user && (
            <Property
              title="User"
              href={`/app/crm/userexperience/${item.user.id}`}
              target="__blank"
            >
              <Typography variant="body2">
                {item.user ? `${item.user.fname} ${item.user.lname}` : ""}
              </Typography>
              <Typography variant="body2">
                {item.company ? item.company.name : ""}
              </Typography>
            </Property>
          )}
          {item.remoteDetails && (
            <Property title="Remote Details">
              <img
                className="mr-1"
                style={{ height: "10px" }}
                src={item.remoteDetails.flagURL}
                alt={item.countryName}
              />
              <span>{item.remoteDetails.city}</span>
              <span>{item.remoteDetails.ip}</span>
            </Property>
          )}
          {item.userAgent && (
            <Property title="Browser">
              {item.userAgent.toLowerCase().indexOf("mobile") >= 0 && (
                <Smartphone />
              )}
              {item.userAgent.toLowerCase().indexOf("instagram") >= 0 && (
                <Instagram />
              )}
              <span>{item.userAgent}</span>
            </Property>
          )}
        </Box>
      }
      actions={
        <IconButton onClick={() => onDeleteLogItem(item.id)} size="large">
          <Delete />
        </IconButton>
      }
    />
  );
};

/**
 * ActivityTypeIcon
 *
 * @param {*} param0
 */
const ActivityTypeIcon = ({ typeId }) => {
  switch (parseInt(typeId)) {
    case 1:
      return <VerifiedUser />;
    case 3:
      return <MailOutline />;
    case 4:
      return <AccountBox />;
    case 5:
      return <ImageSearch sx={{ color: "primary.main" }} />;
    case 6:
      return <Error sx={{ color: "#cc0000" }} />;
    case 7:
      return <Receipt sx={{ color: "primary.main" }} />;
    case 8:
      return <CheckCircleOutline sx={{ color: "primary.main" }} />;
    default:
      return null;
  }
};

export default ActivityLog;
