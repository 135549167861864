/********************************************************************
 *
 * SideMenu.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 ********************************************************************/

import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";

/**
 * SideMenu
 *
 * Renders the side menu for smaller displays
 *
 * @param {*} props
 */
const SideMenu = ({ items, open = true, onClose }) => (
  <Drawer anchor={"left"} open={open} onClose={onClose}>
    <Box
      role="presentation"
      onClick={onClose}
      onKeyDown={onClose}
      sx={{
        width: 250,
      }}
    >
      <List>
        <ListItem>
          <img
            src={process.env["REACT_APP_BRAND_PROFILE_IMG"]}
            alt="Canadian Coastal"
            style={{ padding: "0.5em" }}
          />
        </ListItem>
        <Divider />
        {items &&
          items.map(({ label, hide, path, children }, index) => (
            <React.Fragment key={index}>
              {!hide && (
                <>
                  {children && children.length > 0 ? (
                    <>
                      {children.map(({ label, hide, path }, index) => (
                        <React.Fragment key={index}>
                          {!hide && (
                            <ListItem button component="a" href={path}>
                              <ListItemText primary={label} />
                            </ListItem>
                          )}
                        </React.Fragment>
                      ))}
                      <Divider />
                    </>
                  ) : (
                    <ListItem button component="a" href={path}>
                      <ListItemText primary={label} />
                    </ListItem>
                  )}
                </>
              )}
            </React.Fragment>
          ))}
      </List>
    </Box>
  </Drawer>
);

/*
 **  PropTypes
 */
SideMenu.propTypes = {
  items: PropTypes.array.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default SideMenu;
