import moment from "moment-timezone";
import { DateTime } from "luxon";

let format = new (class Format {
  timeFormat = "HHmm";

  /**
   * Converts an ISO date / time string to a Luxon DateTime
   * object with a spcified timezone.
   *
   * @param {*} isoDateTime
   * @param {*} timezone
   *
   * @returns A Luxon DateTime object with an initialized timezone.
   *
   */
  toDateTime(isoDateTime, timezone = "America/Vancouver") {
    if (isoDateTime == null) return undefined;

    return DateTime.fromISO(isoDateTime).setZone(timezone);
  }

  /**
   * toISO
   *
   * Converts a Luxon DateTime object to a UTC ISO string.
   *
   * @param {*} dateTime
   *
   * @returns An UTC ISO string
   */
  toISO(dateTime) {
    if (dateTime == null) return null;

    if (!(dateTime instanceof DateTime))
      throw new Error("Expected DateTime object type as parameter");

    return dateTime.toUTC().toISO();
  }

  /**
   * getMomentLocalized
   *
   * Initializes a date. If a timezone argument is included, then the
   * time is timezone adjusted, otherwise the time will be localized
   * to the browser locale.
   *
   * @param {date} date
   * @param {timezone} tz
   */
  getMomentLocalized(date, tz) {
    if (tz) {
      return moment.tz(date, tz);
    }
    return moment(date);
  }

  duration(duration) {
    return `${(duration / 60).toFixed(2)} hrs`;
  }

  date(date, tz) {
    return this.toDateTime(date, tz).toLocaleString(DateTime.DATE_MED);
  }

  dayDate(date, tz) {
    return this.getMomentLocalized(date, tz).format("dddd, MMM D, YYYY");
  }

  shortDate(date, tz) {
    return this.getMomentLocalized(date, tz).format(`MMM D`);
  }

  shortDay(date, tz) {
    const _date = this.toDateTime(date, tz);
    return _date.toFormat("EEEE");
  }

  day(date, tz) {
    return this.getMomentLocalized(date, tz).format(`dddd`);
  }

  shortDayDate(date, tz) {
    return this.getMomentLocalized(date, tz).format(`ddd, MMM D`);
  }

  month(date, tz) {
    return this.getMomentLocalized(date, tz).format("MMMM YYYY");
  }

  shortMonthYear(date, tz) {
    const _date = this.toDateTime(date, tz);
    return _date.toFormat("LLL yyyy");
  }

  mday(date, tz) {
    let mdate = this.getMomentLocalized(date, tz);

    if (1 === mdate.date()) {
      return mdate.format("D MMM");
    } else {
      return mdate.format("D");
    }
  }

  dayDateTime(date, tz) {
    return this.getMomentLocalized(date, tz).format(
      `ddd, MMM D YYYY ${this.timeFormat}`
    );
  }

  dateTime(date, tz) {
    return this.getMomentLocalized(date, tz).format(
      `MMM D YYYY ${this.timeFormat} ${tz ? "z" : ""}`
    );
  }

  shortDateTime(date, tz) {
    return this.getMomentLocalized(date, tz).format(
      `MMM D ${this.timeFormat} ${tz ? "z" : ""}`
    );
  }

  numDateTime(date, tz) {
    return this.getMomentLocalized(date, tz).format(
      `YYYY/MM/DD ${this.timeFormat} ${tz ? "z" : ""}`
    );
  }

  shortDayDateTime(date, tz) {
    return this.getMomentLocalized(date, tz).format(
      `ddd, MMM D ${this.timeFormat} ${tz ? "z" : ""}`
    );
  }

  time(date, tz) {
    const _date = this.toDateTime(date, tz);
    return _date.toFormat(this.timeFormat);
  }

  hoursBetween(a, b, tz) {
    const diffInHours = this.toDateTime(b, tz).diff(
      this.toDateTime(a, tz),
      "hours"
    );
    return `${diffInHours.hours} hrs`;
  }

  timeTZ(date, tz) {
    return this.getMomentLocalized(date, tz).format(
      `${this.timeFormat} ${tz ? "z" : ""}`
    );
  }

  timezone(tz) {
    return this.getMomentLocalized(moment(), tz).format(`Z z`);
  }

  fromApiDate(date, tz) {
    return this.getMomentLocalized(date, tz).format("YYYY-MM-DD");
  }

  fromApiTime(date, tz) {
    return this.getMomentLocalized(date, tz).format("HH:mm");
  }

  fromApiDateTime(datetime, tz) {
    return this.getMomentLocalized(datetime, tz).format("YYYY-MM-DD HH:mm");
  }

  toApiDateTime(datetime) {
    return `${moment(datetime).utc().format("YYYY-MM-DDTHH:mm")}Z`;
  }

  toApiDate(datetime) {
    return moment(datetime).utc().format("YYYY-MM-DD");
  }

  isSameDay(a, b, tz) {
    return this.toDateTime(a, tz).hasSame(this.toDateTime(b, tz), "day");
  }
  /**
   * Returns today's date in UTC ISO format
   */
  todayISO() {
    return DateTime.now().toUTC().toISO();
  }

  percent(amount) {
    return `${amount * 100}%`;
  }

  currency(amount) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });

    return formatter.format(amount / 100);
  }

  tax(tax) {
    if (!tax) throw new Error("Invalid tax object");

    switch (tax.calculationType.id) {
      case 0:
        return `${tax.name} (${(tax.rate * 100).toFixed(2)}%)`;
      case 1:
        return `${tax.name} ($${Number(tax.rate).toFixed(2)})`;
    }
  }

  waterLevel(metricData) {
    if (!metricData) return "--";
    return `${parseFloat(metricData).toFixed(2)}m`;
  }
})();

export default format;

/*
{

    public static function dbDate(DateTime $date) {
        return $date->format('Y-m-d');
    }

    public static function dbDateTime(DateTime $date) {
        return $date->format('Y-m-d H:i:s');
    }

    public static function shortdate($date) {
        return date( 'M d', strtotime($date));
    }

    public static function datetime($date) {
        return date( 'M d, Y g:i A', strtotime($date));
    }


    public static function fulldatetime($date) {
        return date( 'l, M d, Y g:i A', strtotime($date));
    }


    public static function time24($date) {
        return date( 'G:i', strtotime($date));
    }

    public static function currency($amount) {
        return money_format('$%.2n', $amount);
    }    

    
    public static function simoText($simoText){
        // Converts Single Input, Multiple Output text to HTML format
        $htmlText = htmlspecialchars($simoText);
        $htmlText = str_replace(["\r\n", "\r", "\n"], '<br />', $htmlText);

        return $htmlText;
    }

    public static function htmlTextToSimo($htmlText){
        // Converts Single Input, Multiple Output text to HTML format
        $text = htmlspecialchars_decode($htmlText);
        $text = str_replace(["<br />","<br>"], "\n", $text);

        return $text;
    }

    public static function teaserText($str, $len){
        if (strlen($str) > $len)
            $str = substr($str, 0, $len-3)."...";
        return htmlspecialchars($str);
    }
}
*/
