/********************************************************************
 *
 * /routes/Redirect.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { Navigate, useParams } from "react-router";

/**
 * Redirect
 *
 * Wraps the <Navigate> component and replaces "/:<paramName>" with "/<paramValue"
 */
const Redirect = ({ to, ...rest }) => {
  const params = useParams();

  /**
   * InsertParams
   *
   * Replaces the dynamic segments with the parameter values
   *
   * @param {*} to
   * @param {*} params
   * @returns
   */
  const insertParams = (to, params) => {
    const entries = Object.entries(params);
    let path = `${to}`;

    entries.forEach(([key, value]) => {
      path = path.replace(`:${key}`, `${value}`);
    });

    return path;
  };

  return <Navigate to={insertParams(to, params)} {...rest} replace />;
};

export default Redirect;
