/********************************************************************
 *
 * MerchType.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Outlet } from "react-router";
import { Main } from "../../lib";
import { useApp, useAPI } from "../../../providers/AppProvider";

/**
 * MerchType
 *
 * Manages a merchandise category
 *
 */
const MerchType = () => {
  const [merchType, setMerchType] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const { merchTypeId } = useParams();
  const navigate = useNavigate();
  const app = useApp();
  const api = useAPI();

  /**
   * useEffect
   *
   * Fired when the component initially mounts.
   *
   */
  useEffect(() => {
    if (!merchTypeId || merchTypeId === 0) {
      //
      //  New Merch Type Mode - initialize empty object
      //
      return;
    }

    //
    //  If merch type id is a paramter, we are in edit mode, otherwise
    //  we are creating a new merch type
    //
    fetchMerchType(merchTypeId);
  }, [merchTypeId]);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * FetchTourType
   *
   * Calls the API to fetch the requested tour type.
   *
   * If the tour id is null, create a shell tour type.
   *
   * @param {*} tourTypeId
   */
  const fetchMerchType = (tourTypeId) => {
    //
    //  Status message
    //
    // const notifyHandle = app.notify("Loading");
    // api
    //   .fetch(`/api/merchtypes/merchtype/${parseInt(merchTypeId)}/?verbose`)
    //   .then(({ payload: merchType }) => {
    //     setMerchType(merchType);
    //     setLoaded(true);
    //     app.endNotify(notifyHandle);
    //   })
    //   .catch((error) => {
    //     app.error({ error });
    //     setMerchType(null);
    //     setLoaded(true);
    //   });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Main
      header={{
        title: "Some type of merch",
      }}
    >
      TBD
    </Main>
  );
};

export default MerchType;
