/********************************************************************
 *
 * UserExperience.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Outlet, useParams } from "react-router";
import { Avatar } from "@mui/material";
import { PersonOutlined } from "@mui/icons-material";
import { Main } from "../../lib";
import {
  SectionList,
  SectionItemGroup,
  SectionItem,
} from "../../lib/SideBar/Menu";
import { useApp, useAPI } from "../../../providers/AppProvider";

/**
 * UserExperience
 *
 * Renders User Experience information
 */
const UserExperience = () => {
  const [user, setUser] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  const { userId } = useParams();

  useEffect(() => {
    fetchUser(userId);
  }, [userId]);

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  ///////////////////////////////////////////////////////////////////

  const fetchUser = (userId) => {
    if (!userId) return;
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading Customer");

    api
      .fetch(`/api/users/user/${userId}?verbose`)
      .then(({ payload: user }) => {
        setUser(user);
        setLoaded(true);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setUser(null);
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  const onUpdate = () => {
    // fetchUser(userId);
  };

  return (
    <Main
      menu={{
        icon: <PersonOutlined />,
        title: "User Experience",
        subtitle: "Customer Journey",
        nav: <Menu user={user} />,
      }}
      header={{
        icon: !!user && (
          <Avatar>
            {user.fname && user.fname[0]}
            {user.lname && user.lname[0]}
          </Avatar>
        ),
        title:
          user && (user.fname || user.lname)
            ? `${user.fname} ${user.lname}`
            : "Anonymous",
      }}
      loaded={loaded}
    >
      <Outlet context={{ user, onUpdate }} />
    </Main>
  );
};

/**
 * Renders the navigation controls for the functional areas of the
 * product type maagement page.
 *
 * @param {*} props
 */
const Menu = ({ user }) => {
  const { id = 0 } = user || {};

  return (
    <SectionList>
      <SectionItemGroup icon={<PersonOutlined />} name="Sessions" open={true}>
        <SectionItem to={`/app/crm/customer/${id}/details`}>
          Details
        </SectionItem>
        <SectionItem to={`/app/crm/customer/${id}/history`}>
          History
        </SectionItem>
      </SectionItemGroup>
      <SectionItemGroup icon={<PersonOutlined />} name="Sales" open={true}>
        <SectionItem to={`/app/crm/customer/${id}/communication`}>
          Communication
        </SectionItem>
        <SectionItem to={`/app/crm/customer/${id}/account`}>
          Account
        </SectionItem>
      </SectionItemGroup>
    </SectionList>
  );
};

export default UserExperience;
