/********************************************************************
 *
 * TicketReducer.jsx
 *
 * Implements state management for the Ticket page and
 * components.
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import { useReducer } from "react";

/////////////////////////////////////////////////////////////////////
//
//  OBJECT DEFINITIONS
//
/////////////////////////////////////////////////////////////////////

/**
 * ACTIONS
 *
 * Defines the set of valid actions on the reducer function
 *
 */
const ACTIONS = {
  HELLO: "hello",
  INITIALIZE_TICKET: "initialize_ticket",
  UPDATE_TICKET: "update_ticket",
  INITIALIZE_EVENTS: "initialize_events",
  TICKET_EVENT: "ticket_event",
};

/**
 * InitialState
 *
 * Defines the shape and initial values of the state object
 */
const initialState = {
  ticket: null,
  initialized: false,
  dirty: false,
  loaded: false,
  active: false,
  completed: false,
};

/////////////////////////////////////////////////////////////////////
//
//  HELPER FUNCTIONS
//
/////////////////////////////////////////////////////////////////////

/**
 * IsActive
 *
 * Returns true if the ticket is active
 *
 * @param {*} ticket
 * @returns
 */
const isActive = (ticket) => ticket.status.id != 0 && ticket.status.id != 3;

/**
 * IsComplete
 *
 * Returns true if the ticket is complete
 *
 * @param {*} ticket
 * @returns
 */
const isComplete = (ticket) => false; //ticket.completed != null;

/**
 * Reducer
 *
 * Manages actions on the state object
 *
 * @param {*} state
 * @param {*} action
 *
 * @returns
 */

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.HELLO: {
      console.log("Hello World");
      return state;
    }
    case ACTIONS.INITIALIZE_TICKET: {
      //
      //  Payload is the ticket
      //
      return {
        ...state,
        ticket: action.payload,
        initialized: true,
        loaded: true,
        active: isActive(action.payload),
        completed: isComplete(action.payload),
      };
    }
    case ACTIONS.UPDATE_TICKET: {
      //
      //  Payload is the updated ticket.
      //
      return {
        ...state,
        ticket: action.payload,
        dirty: true,
        active: isActive(action.payload),
        completed: isComplete(action.payload),
      };
    }
    case ACTIONS.INITIALIZE_EVENTS: {
      //
      //  Payload is the initial collection of ticket events
      //
      return { ...state, events: action.payload };
    }
    case ACTIONS.TICKET_EVENT: {
      //
      //  Payload is the updated ticket and new ticket event
      //
      return {
        ...state,
        ticket: action.payload.ticket,
        events: [...(state.events || []), action.payload.event],
        active: isActive(action.payload.ticket),
        completed: isComplete(action.payload.ticket),
      };
    }
    default: {
      throw Error("Unknown reducer action: " + action.type);
    }
  }
};

export default () => {
  return [...useReducer(reducer, initialState), ACTIONS];
};
