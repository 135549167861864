/********************************************************************
 *
 * TravelDetailsEdit.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import {
  Box,
  Button,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { HorizontalGroup } from "../../../lib";
import ModalDialog, { DialogContent } from "../../../lib/ModalDialog";
import { useApp, useAPI } from "../../../../providers/AppProvider";

/**
 * TravelDetailsEdit
 *
 * Component for adding a new, or editting the descriptive information
 * an exiting customer.
 *
 */
const TravelDetailsEdit = ({ customer, show, onSaved, onCancelled }) => {
  const app = useApp();
  const api = useAPI();

  ///////////////////////////////////////////////////////////////////
  //
  //  Utiltiy Methods
  //
  ///////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnSave
   *
   * Fired when the user saves the changes
   *
   * @param {*} values
   */
  const onSave = (values) => {
    //
    //  Save
    //
    const notifyHandle = app.notify("Saving Travel Details");

    api
      .update(`/api/customers/customer/${values.id ? values.id : ""}`, values)
      .then(({ payload: customer }) => {
        onSaved && onSaved({ customer });
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  /**
   * onCancel
   *
   * Fired when the user aborts changes.
   *
   */
  const onCancel = () => {
    if (
      !window.confirm(
        "You have unsaved changes. Are you sure you want to close this dialog and loose your changes?"
      )
    )
      return;

    onCancelled && onCancelled();
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  return (
    <ModalDialog title="Manage Travel Details" subtitle="TBD" show={show}>
      <DialogContent>
        <FormBody customer={customer} onSave={onSave} onCancel={onCancel} />
      </DialogContent>
    </ModalDialog>
  );
};

/**
 * FormBody
 *
 * @param {*} props
 */
const FormBody = ({ customer, onSave, onCancel }) => {
  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  return (
    <Formik
      initialValues={customer}
      enableReinitialize={true}
      onSubmit={(customer) => {
        onSave(customer);
      }}
      validationSchema={yup.object().shape({})}
    >
      {({
        values,
        touched,
        errors,
        dirty,
        isSubmitting,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Box display="flex" justifyContent="flex-end">
              <HorizontalGroup gap={1}>
                <Button
                  type="submit"
                  variant="outlined"
                  color="primary"
                  disabled={!dirty || isSubmitting}
                >
                  Save
                </Button>
                <Button onClick={() => onCancel(dirty)}>Cancel</Button>
              </HorizontalGroup>
            </Box>
            <CustomerForm
              customer={values}
              touched={touched}
              errors={errors}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </form>
        );
      }}
    </Formik>
  );
};

const CustomerForm = ({
  customer,
  touched,
  errors,
  setFieldValue,
  onBlur,
  onChange,
}) => {
  return (
    <Box>
      <FormControlLabel
        label="Minor"
        control={
          <Switch
            id="isMinor"
            checked={customer.isMinor}
            onChange={(e) => {
              setFieldValue("isMinor", !customer.isMinor);
            }}
          />
        }
      />
      <TextField
        id="age"
        label="Age"
        value={customer.age}
        disabled={!customer.isMinor}
        onBlur={onBlur}
        onChange={onChange}
        fullWidth
        required
        error={errors.age && touched.age}
        helperText={errors.age || " "}
      />
      <TextField
        id="height"
        label="Height"
        value={customer.height}
        disabled={!customer.isMinor}
        onBlur={onBlur}
        onChange={onChange}
        fullWidth
        required
        error={errors.height && touched.height}
        helperText={errors.height || " "}
      />
      <TextField
        id="weight"
        label="Weight"
        value={customer.weight}
        disabled={!customer.isMinor}
        onBlur={onBlur}
        onChange={onChange}
        fullWidth
        required
        error={errors.weight && touched.weight}
        helperText={errors.weight || " "}
      />
      <TextField
        id="allergies"
        label="Allergies"
        value={customer.allergies}
        onBlur={onBlur}
        onChange={onChange}
        fullWidth
        required
        error={errors.allergies && touched.allergies}
        helperText={errors.allergies || " "}
      />
      <TextField
        id="medical"
        label="Medical Information"
        value={customer.medical}
        onBlur={onBlur}
        onChange={onChange}
        fullWidth
        required
        error={errors.medical && touched.medical}
        helperText={errors.medical || " "}
      />
      <TextField
        id="requests"
        label="Requests"
        value={customer.requests}
        onBlur={onBlur}
        onChange={onChange}
        fullWidth
        multiline
        rows="2"
        variant="outlined"
        required
        error={errors.requests && touched.requests}
        helperText={errors.requests || " "}
      />
      <TextField
        id="comments"
        label="Comments"
        value={customer.comments}
        onBlur={onBlur}
        onChange={onChange}
        fullWidth
        multiline
        rows="2"
        variant="outlined"
        required
        error={errors.comments && touched.comments}
        helperText={errors.comments || " "}
      />
    </Box>
  );
};

export default TravelDetailsEdit;
