import App from "../App";
import Dashboard from "../components/Dashboard";
import appCatalog from "./app.catalog";
import appGoods from "./app.goods";
import appReports from "./app.reports";
import appAdmin from "./app.admin";
import appSales from "./app.sales";
import appCRM from "./app.CRM";

export default {
  path: "/app/",
  element: <App />,
  children: [
    {
      path: "/app/",
      element: <Dashboard />,
    },
    { ...appCatalog },
    { ...appGoods },
    { ...appCRM },
    { ...appSales },
    { ...appReports },
    { ...appAdmin },
  ],
};
