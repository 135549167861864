/********************************************************************
 *
 * Index.jsx
 *
 * Renders a control that manages the list of tickets.
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router";
import { Avatar, Box, Tooltip, Typography } from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import format from "../../../../../utils/format";
import { WidgetFrame } from "../../../../lib";
import { SEVERITY_ICONS } from "../../lib/iconLib";

const PAGE_SIZE = 5;

const columns = [
  { field: "id", headerName: "ID", width: 60 },
  {
    field: "createdBy",
    headerName: "Created By",
    width: 200,
    renderCell: (value) => (
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          height: "100%",
          gap: 1,
        }}
      >
        <Avatar
          alt={`${value.row.createdBy.fname} ${value.row.createdBy.lname}`}
          src={value.row.createdBy.imageUrl}
        />
        <Box>
          <Typography variant="body2" component="div">
            {value.row.createdBy.fname} {value.row.createdBy.lname}
          </Typography>
          <Typography
            variant="caption"
            component="div"
            sx={{ color: "#999999" }}
          >{`on ${
            !!value.row.timestamp ? format.date(value.row.timestamp) : "-"
          }`}</Typography>
        </Box>
      </Box>
    ),
  },
  {
    field: "type",
    headerName: "Type",
    valueGetter: (value, row) => row.type.name,
    width: 175,
  },
  {
    headerName: "Name",
    field: "name",
    width: 250,
    renderCell: (value) => (
      <Tooltip title={value.row.description} placement="top">
        <>{value.row.name}</>
      </Tooltip>
    ),
  },
  {
    field: "priority",
    headerName: "Priority",
    renderCell: (value) => value.row.priority.name,
  },
  {
    field: "severity",
    headerName: "Severity",
    renderCell: (value) => (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        {SEVERITY_ICONS[value.row.severity.id]}
      </Box>
    ),
  },
  {
    field: "assigned",
    headerName: "Assigned",
    width: 180,
    renderCell: (value) => (
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          height: "100%",
          gap: 1,
        }}
      >
        <Avatar
          alt={`${value.row.assignedTo.fname} ${value.row.assignedTo.lname}`}
          src={value.row.assignedTo.imageUrl}
        />
        <Box>
          <Typography variant="body2" component="div">
            {value.row.assignedTo.fname} {value.row.assignedTo.lname}
          </Typography>
        </Box>
      </Box>
    ),
  },
];

/**
 * CustomToolbar
 *
 * Renders a custom toolbar for the Datagrid
 *
 * @returns
 */
const CustomToolbar = () => (
  <GridToolbarContainer sx={{ p: 2 }}>
    <GridToolbarQuickFilter />
    <Box sx={{ flexGrow: 1 }} />
    <GridToolbarFilterButton slotProps={{ button: { color: "text" } }} />
    <GridToolbarExport
      slotProps={{
        tooltip: { title: "Export data" },
        button: { variant: "outlined" },
      }}
    />
  </GridToolbarContainer>
);

/**
 * TicketsCollection
 *
 * @returns
 */
const Collection = ({ tickets }) => {
  const navigate = useNavigate();

  if (!tickets) return;

  return (
    <WidgetFrame title="Tickets">
      <DataGrid
        loading={!tickets}
        rows={tickets}
        columns={columns}
        slots={{ toolbar: CustomToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
          loadingOverlay: {
            variant: "skeleton",
            noRowsVariant: "skeleton",
          },
        }}
        onRowClick={(params) => {
          navigate(`/app/admin/ticket/${params.row.id}`);
        }}
        initialState={{
          pagination: {
            paginationModel: {
              page: 0,
              pageSize: PAGE_SIZE,
            },
          },
          pinnedColumns: {
            left: ["id"],
          },
        }}
        pageSizeOptions={[5, 10, 25]}
      />
    </WidgetFrame>
  );
};

/**
 * PropTypes
 */
Collection.propTypes = {
  tickets: PropTypes.array,
};

export default Collection;
