/********************************************************************
 *
 * Details.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Typography, IconButton, Avatar } from "@mui/material";
import { Edit, MailOutline } from "@mui/icons-material";
import { WidgetFrame, StatCard } from "../../lib";
import DetailsEdit from "./DetailsEdit";

/**
 *
 * @param {*} param0
 * @returns
 */
const Details = ({ employee }) => {
  const [edit, setEdit] = useState(false);

  const onSaved = () => {};
  const onUpdate = () => {};

  if (!employee) return null;

  return (
    <WidgetFrame
      action={
        !!onUpdate && (
          <IconButton
            onClick={() => setEdit(true)}
            color="secondary"
            size="large"
          >
            <Edit />
          </IconButton>
        )
      }
      background="dark"
    >
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            alignItems: "center",
          }}
        >
          <Avatar
            src={employee.imageUrl}
            sx={{
              height: 150,
              width: 150,
              border: "solid 2px #ffffff",
            }}
          />
          <Typography variant="h3">
            {employee.fname} {employee.lname}
          </Typography>
          <Typography variant="h6">{employee.phone}</Typography>
          <IconButton
            href={`mailto:${employee.email}`}
            color="secondary"
            size="large"
          >
            <MailOutline />
          </IconButton>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Box>
              <StatCard label="Started" value={`May 1, 2022`} />
            </Box>
            <Box>
              <StatCard label="Hours Worked" value={`37.5`} />
            </Box>
          </Box>
        </Box>
        <DetailsEdit
          employee={employee}
          show={edit}
          onSaved={onSaved}
          onClose={() => {
            setEdit(false);
          }}
        />
      </>
    </WidgetFrame>
  );
};

/**
 * PropTypes
 *
 */
Details.propTypes = {
  employee: PropTypes.object,
};

export default Details;
