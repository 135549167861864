/********************************************************************
 *
 * Index.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { AccountBalance } from "@mui/icons-material";
import { Outlet } from "react-router";
import { Main } from "../../lib";
import {
  SectionList,
  SectionItemGroup,
  SectionItem,
} from "../../lib/SideBar/Menu";

/**
 * Finance
 *
 * Container component for managing financial reports.
 *
 */
const Finance = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  ///////////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Main
      menu={{
        icon: <AccountBalance />,
        title: "Financial Reports",
        subtitle: "Revenues and Accounts",
        nav: <Menu />,
      }}
      header={{
        icon: <AccountBalance />,
        title: "Finance",
      }}
      loaded={loaded}
    >
      <Outlet />
    </Main>
  );
};

///////////////////////////////////////////////////////////////////////
//
//  Functional Components
//
///////////////////////////////////////////////////////////////////////

/**
 * Renders the navigation controls for the functional areas of the
 * order management page.
 *
 * @param {*} props
 */
const Menu = () => (
  <SectionList>
    <SectionItemGroup icon={<AccountBalance />} name="Reports" open={true}>
      <SectionItem to={`/app/reports/finance/accounts`}>Accounts</SectionItem>
      <SectionItem to={`/app/reports/finance/sales`}>Sales</SectionItem>
    </SectionItemGroup>
  </SectionList>
);

export default Finance;
