/********************************************************************
 *
 * Index.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router";
import { Box } from "@mui/material";
import {
  AttachMoney,
  Edit,
  AssignmentTwoTone,
  SettingsTwoTone,
} from "@mui/icons-material";
import { Main } from "../../lib";
import Rates from "../RateClasses/RateClassRates";
import { RateClassSummary } from "./Identity/RateClassSummaryHeader";
import {
  SectionList,
  SectionItemGroup,
  SectionItem,
} from "../../lib/SideBar/Menu";
import format from "../../../utils/format";
import { useApp, useAPI } from "../../../providers/AppProvider";

/**
 * RateClass
 *
 * Renders a rate type.
 *
 */
const Index = () => {
  const [loaded, setLoaded] = useState(false);
  const [rateClass, setRateClass] = useState(null);
  const { id } = useParams();
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    fetchRateClass(id);
  }, [id]);

  ///////////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////////

  const fetchRateClass = (id) => {
    api
      .fetch(`/api/rateclasses/rateclass/${id}?verbose`)
      .then(({ payload: rateClass }) => {
        setRateClass(rateClass);
        setLoaded(true);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnUpdate
   *
   * Fired when a child updates the data object.
   *
   * @param {*} event
   */
  const onUpdate = () => {
    //
    //  If product type id is a paramter, we are in edit mode, otherwise
    //  we are creating a new product type
    //
    fetchRateClass(id);
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Main
      menu={{
        icon: <AttachMoney />,
        title: "Rate Class Management",
        subtitle: "Edit and assign rate classes",
        nav: <Menu rateClass={rateClass} />,
      }}
      header={{
        icon: <AttachMoney />,
        title: !!rateClass && rateClass.name,
      }}
      loaded={loaded}
    >
      <Outlet context={{ rateClass, onUpdate }} />
      {loaded && rateClass && (
        <Box
          sx={{
            display: "grid",
            gap: 4,
            gridTemplateColumns: "repeat(12, 1fr)",
          }}
        >
          {/*<Rates rateClass={rateClass} />
          </div>
          <div className="col-12 col-md-3 small">
            <Taxes taxes={rateClass.taxes} /> */}
        </Box>
      )}
    </Main>
  );
};

const Taxes = ({ taxes, onEdit }) => {
  ///////////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////////
  const onEditTaxes = (e) => {
    alert("Edit Taxes");
  };

  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
          <h5 className="card-title">Taxes &amp; Fees</h5>
          <div className="mb-0 mb-md-3">
            <button className="btn btn-sm" onClick={onEdit} title="Edit Taxes">
              <Edit />
            </button>
          </div>
        </div>

        <ul className="list-group list-group-flush">
          {taxes
            ? taxes.map((tax) => (
                <li key={tax.id} className="list-group-item">{`${
                  tax.calculationType.id === 0
                    ? format.percent(tax.rate)
                    : format.currency(tax.rate)
                } ${tax.name}`}</li>
              ))
            : ""}
        </ul>
      </div>
    </div>
  );
};

/**
 * Menu
 *
 * Renders the navigation controls for the functional areas of the
 * product type management page.
 *
 * @param {*} props
 */
const Menu = ({ rateClass }) => {
  const { id = 0 } = rateClass || {};

  return (
    <SectionList>
      <SectionItemGroup
        icon={<AssignmentTwoTone />}
        name="Inventory"
        open={true}
      >
        <SectionItem to={`/app/catalog/rateclass/${id}/identity`}>
          Identity
        </SectionItem>
      </SectionItemGroup>
      <SectionItemGroup
        icon={<SettingsTwoTone />}
        name="Configuration"
        open={true}
      >
        <SectionItem to={`/app/catalog/rateclass/${id}/policies`}>
          Policies
        </SectionItem>
        <SectionItem to={`/app/catalog/rateclass/${id}/partners`}>
          Partners
        </SectionItem>
      </SectionItemGroup>
    </SectionList>
  );
};

export default Index;
