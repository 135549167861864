/********************************************************************
 *
 * ShiftStatus.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import {
  ErrorOutline,
  CheckCircle,
  PersonAddDisabled,
} from "@mui/icons-material";

const ShiftStatus = ({ status }) => {
  return (
    <>
      {status === 0 && <ErrorOutline />}
      {status === 1 && <CheckCircle />}
      {status === 2 && <PersonAddDisabled />}
    </>
  );
};

export default ShiftStatus;
