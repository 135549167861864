/********************************************************************
 *
 * Section.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { Box, Typography, Divider } from "@mui/material";

const Section = ({ icon, title, children }) => {
  return (
    <section>
      <Box sx={{ display: "flex", pb: 1 }}>
        <Box
          component="span"
          sx={{
            lineHeight: "20px",
            display: { xs: "none", sm: "block" },
            pr: { sm: 1 },
            "& svg": { width: "20px", height: "auto" },
          }}
        >
          {icon}
        </Box>
        <Typography
          variant="h2"
          sx={{
            height: "20px",
            fontSize: "20px",
          }}
        >
          {title}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ py: 3 }}>{children}</Box>
    </section>
  );
};

export default Section;
