/********************************************************************
 *
 * App.jsx
 *
 * Application page router.
 *
 * Routes requests related to application functionality.
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import "./app.css";

import React, { useEffect } from "react";
import { Outlet } from "react-router";
import { SnackbarProvider } from "notistack";
import { AppProvider, AuthProvider } from "./providers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Header, Footer } from "./components/lib";
import DefaultThemeProvider from "./themes/default";
import CustomAdapterLuxon from "./components/lib/CustomAdapterLuxon";

const App = () => {
  useEffect(() => {
    document.body.classList.add("app");
  }, []);

  return (
    <AuthProvider>
      <SnackbarProvider
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <AppProvider>
          <div className="app-wrapper">
            <DefaultThemeProvider>
              <LocalizationProvider dateAdapter={CustomAdapterLuxon}>
                <Header />
                <div
                  style={{
                    position: "static",
                    display: "block",
                    overflowX: "auto",
                    overflowY: "scroll",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <Outlet />
                  <Footer />
                </div>
              </LocalizationProvider>
            </DefaultThemeProvider>
          </div>
        </AppProvider>
      </SnackbarProvider>
    </AuthProvider>
  );
};

export default App;
