/********************************************************************
 *
 * GoodMiniEdit.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import moment from "moment-timezone";
import { useApp, useAPI } from "../../../providers/AppProvider";

const GOODTYPE_PRIVATE = 0;
const GOODTYPE_SHARED = 1;

/**
 * GoodMiniEdit
 *
 * Widget for quickly viewing/editting/creating a Good.
 *
 */
const GoodMiniEdit = ({ good: goodExt, goodType, onSaved, onCancelled }) => {
  const [good, setGood] = useState(null);
  const [validated, setValidated] = useState(null);
  const [dirty, setDirty] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    //
    //  New good mode
    //
    if (goodExt) {
      setGood(goodExt);
      setValidated(null);
      setDirty(good.id == null);
    }
  }, [goodExt]);

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnChangeStartTime
   *
   * Fired when the user updates the start time of the good.
   *
   * Update the good's start and acquire times, and, if the new start
   * is past the end time, update the end and release times.
   *
   * @param {*} event
   *
   */
  const onChangeStartTime = (event) => {
    //
    //  Compute date and time
    //
    let _date = moment.tz(good.start, good.tz).startOf("day");

    let _dateTime = moment(_date).add(
      moment.duration(event.target.value, "HH:mm")
    );

    good.start = _dateTime.toISOString();
    good.displayStart = good.start;

    if (_dateTime.isAfter(moment(good.end))) {
      good.end = _dateTime.toISOString();
      good.displayEnd = good.end;
    }

    setGood({ ...good });
    setDirty(true);
  };

  /**
   * OnChangeEndTime
   *
   * Fired when the user updates the end time of the good.
   *
   * Update the good's end and release times, and, if the new end
   * is before the start time, update the start and acquire times.
   *
   * @param {*} event
   *
   */
  const onChangeEndTime = (event) => {
    let _date = moment.tz(good.end, good.tz).startOf("day");

    let _dateTime = moment(_date).add(
      moment.duration(event.target.value, "HH:mm")
    );

    good.end = _dateTime.toISOString();
    good.displayEnd = good.end;

    if (_dateTime.isBefore(moment(good.start))) {
      good.start = _dateTime.toISOString();
      good.displayStart = good.start;
    }

    setGood({ ...good });
    setDirty(true);
  };

  /**
   * OnChangeGoodType
   *
   * @param {*} e
   */
  const onChangeGoodType = (e) => {
    good.shared = e.target.checked ? GOODTYPE_PRIVATE : GOODTYPE_SHARED;

    setGood({ ...good });
    setDirty(true);
  };

  /**
   * OnSave
   *
   * @param {*} event
   */
  const onSave = (event) => {
    const { currentTarget: form } = event;

    event.preventDefault();

    //
    //  Validate form
    //
    if (form.checkValidity() === false) {
      setValidated(false);
      event.stopPropagation();
      return;
    }

    //
    //  Save
    //
    const notifyHandle = app.notify("Saving");

    api
      .update(`/api/suppliergoods/suppliergood/${good.id ? good.id : ""}`, good)
      .then((good) => {
        onSaved && onSaved({ good: good });
        setGood(good);
        setValidated(null);
        setDirty(false);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  /**
   * onCancel
   *
   * Fired when the user aborts changes.
   *
   */
  const onCancel = () => {
    if (
      !dirty ||
      window.confirm(
        "You have unsaved changes. Are you sure you want to close this dialog and loose your changes?"
      )
    ) {
      onCancelled && onCancelled();
      setGood(null);
      setValidated(null);
      setDirty(false);
    }
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  return (
    <form validated={validated} onSubmit={onSave}>
      <div className="flexRowPushRight" style={{ alignItems: "baseline" }}>
        <Link
          className="mr-2"
          to={{
            pathname: `/app/goods/supplier/${good && good.id ? good.id : ""}`,
            good: good,
          }}
        >
          More Options
        </Link>
        <button className="btn btn-outline-cc" type="submit" disabled={!dirty}>
          Save
        </button>
        <button
          type="button"
          className="btn btn-secondary mx-3"
          onClick={onCancel}
        >
          Cancel
        </button>
      </div>
      <Editor
        goodType={goodType}
        good={good}
        onChangeStartTime={onChangeStartTime}
        onChangeEndTime={onChangeEndTime}
      />
    </form>
  );
};

const Editor = ({ goodType, good, onChangeStartTime, onChangeEndTime }) => {
  return (
    <>
      <h3>
        {good && good.id
          ? `${goodType.name} ${good.id}`
          : `New ${goodType.name}`}
      </h3>
      {good && (
        <h5>{`Duration: ${moment
          .utc(moment(good.end).diff(moment(good.start)))
          .format("HH:mm")} Hrs`}</h5>
      )}
      <div className="form-group">
        <label htmlFor="startTime">Depart</label>
        <input
          id="startTime"
          className="form-control"
          type="time"
          format="HH:mm"
          onChange={onChangeStartTime}
          value={
            good && good.start
              ? moment.tz(good.start, good.tz).format("HH:mm")
              : ""
          }
          required
        />
        <div className="invalid-feedback">Please choose a good time.</div>
        <small>{good && moment.tz(good.start, good.tz).format()}</small>
      </div>
      <div className="form-group">
        <label htmlFor="endTime">Return</label>
        <input
          id="endTime"
          className="form-control"
          type="time"
          onChange={onChangeEndTime}
          value={
            good && good.end ? moment.tz(good.end, good.tz).format("HH:mm") : ""
          }
          required
        />
        <div className="invalid-feedback">Please choose a return time.</div>
        <small>{good && moment.tz(good.end, good.tz).format()}</small>
      </div>
      {good && (
        <>
          <div className="small">Quantity: {good.quantity}</div>
          <div className="small">Timezone: {good.tz}</div>
        </>
      )}
    </>
  );
};

GoodMiniEdit.propTypes = {
  goodType: PropTypes.object.isRequired,
  good: PropTypes.object,
  onSaved: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
};

export default GoodMiniEdit;
