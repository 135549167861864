import React from "react";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";

var theme = createTheme({
  palette: {
    primary: {
      light: "#66c1b9",
      main: "#01899f",
      dark: "#0c4964",
      contrastText: "#eeeeee",
    },
    secondary: {
      light: "#ffffff",
      main: "#66c1b9",
      dark: "#01899f",
      contrastText: "#666666",
    },
    text: {
      primary: "#484848",
      secondary: "#999999",
    },
    action: {
      active: "#666666",
      hover: "#eeeeee",
      selected: "#01899f",
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(0, 0, 0, 0.12)",
    },
    background: {
      paper: "#fff",
      default: "#fafafa",
    },
    priorityVeryLow: {
      light: "#ffffff",
      main: "#66c1b9",
      dark: "#01899f",
      contrastText: "#666666",
    },
    priorityLow: {
      light: "#66c1b9",
      main: "#01899f",
      dark: "#0c4964",
      contrastText: "#eeeeee",
    },
    priorityNormal: {
      light: "#f7efdb",
      main: "#dbb04d",
      dark: "#83692e",
      contrastText: "#fbf7ed",
    },
    priorityHigh: {
      light: "#eeab8d",
      main: "#e37342",
      dark: "#713921",
      contrastText: "#fcf1ec",
    },
    priorityVeryHigh: {
      light: "#ed4657",
      main: "#e9182e",
      dark: "#8b0e1b",
      contrastText: "#fce7ea",
    },
    severityInformation: {
      light: "#ffffff",
      main: "#66c1b9",
      dark: "#01899f",
      contrastText: "#666666",
    },
    severityPlanned: {
      light: "#66c1b9",
      main: "#01899f",
      dark: "#0c4964",
      contrastText: "#eeeeee",
    },
    severityWarning: {
      light: "#f7efdb",
      main: "#dbb04d",
      dark: "#83692e",
      contrastText: "#fbf7ed",
    },
    severityMajor: {
      light: "#eeab8d",
      main: "#e37342",
      dark: "#713921",
      contrastText: "#fcf1ec",
    },
    severityCritical: {
      light: "#ed4657",
      main: "#e9182e",
      dark: "#8b0e1b",
      contrastText: "#fce7ea",
    },
    statusPostponed: {
      light: "#f6eeec",
      main: "#ad5641",
      dark: "#562b20",
      contrastText: "#f6eeec",
    },
    statusOpened: {
      light: "#f7efdc",
      main: "#ad8c41",
      dark: "#675427",
      contrastText: "#f6f3ec",
    },
    statusCompleted: {
      light: "#e5f3f5",
      main: "#01899f",
      dark: "#00525f",
      contrastText: "#e5f3f5",
    },
    statusClosed: {
      light: "#e5f3f5",
      main: "#687f83",
      dark: "#48585b",
      contrastText: "#eff2f2",
    },
  },
  typography: {
    fontWeight: 400,
    fontSize: 14,
    color: "#666",
    body1: {},
    body2: {},
    body3: { fontSize: "0.75rem" },
    h1: { fontSize: 30, fontWeight: 300 },
    h2: { fontSize: 28, fontWeight: 300 },
    h3: { fontSize: 22, fontWeight: 500 },
    h4: { fontSize: 18, fontWeight: 400 },
    h5: { fontSize: 14, fontWeight: 500 },
    h6: { fontSize: 12 },
    smallChar: {
      fontSize: "12px",
      lineHeight: "12px",
      height: "1em",
      padding: 0,
      margin: 0,
    },
    largeChar: {
      fontSize: "30px",
      lineHeight: "30px",
      height: "30px",
      padding: 0,
      margin: 0,
      marginTop: "-4px",
    },
    currency: {
      fontSize: "0.875rem",
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: "8px",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontFamily: ["Arial"],
          textDecoration: "none",
          color: "inherit",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        title: { fontWeight: 500, fontSize: "1.25em" },
        subheader: { fontSize: "0.95em" },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
  },
  overrides: {
    MuiTypography: {
      gutterBottom: { marginBottom: "2rem" },
      h1: {
        lineHeight: "1.25em",
      },
      h2: {
        lineHeight: "1.25em",
      },
      h3: {
        lineHeight: "1.25em",
      },
    },
    MuiTextField: {
      root: {
        margin: "6px",
      },
    },
    MuiInput: {
      root: {
        margin: "8px",
      },
    },
  },
});

//theme = responsiveFontSizes(theme, { factor: 10 });

export default ({ children }) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
};
