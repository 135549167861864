/********************************************************************
 *
 * /components.sales/leads/notes.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import {
  Box,
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Chip,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from "@mui/lab";
import { Comment } from "@mui/icons-material";
import format from "../../../utils/format";

/**
 * Notes
 *
 * Lists notes for a lead
 *
 * @param {*} props
 */
const Notes = ({ notes }) => {
  const classes = useStyles();

  if (!notes) return null;

  return (
    <Timeline>
      {notes.map((note, index) => (
        <TimelineItem
          classes={{
            missingOppositeContent: classes.missingOppositeContent,
          }}
        >
          <TimelineSeparator>
            {index === 0 ? (
              <TimelineDot color="primary">
                <Comment />
              </TimelineDot>
            ) : (
              <TimelineDot color="primary" variant="outlined">
                <Comment color="primary" />
              </TimelineDot>
            )}
            {index < notes.length - 1 && (
              <TimelineConnector classes={{ root: classes.connector }} />
            )}
          </TimelineSeparator>
          <TimelineContent>
            <Note note={note} isCustomer={index === 0} />
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

const Note = ({ note, isCustomer }) => {
  console.log(note);
  return (
    <Card>
      <CardContent>
        <Box sx={{ pb: 2 }}>
          <Typography variant="caption">
            {`On ${format.dateTime(note.timestamp)} ${note.user.fname} ${
              note.user.lname
            } wrote:`}
          </Typography>
        </Box>
        <Typography variant="body2">{note.message}</Typography>
      </CardContent>
      <CardActions></CardActions>
    </Card>
  );
};

/*
 **  Styles
 */
const useStyles = makeStyles((theme) => ({
  controls: { padding: theme.spacing(2), marginBottom: theme.spacing(2) },
  missingOppositeContent: { "&:before": { display: "none" } },
  connector: { backgroundColor: "rgba(0,0,0,0.1)" },
}));

export default Notes;
