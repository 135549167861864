/********************************************************************
 *
 * Index.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { RequestQuote } from "@mui/icons-material";
import { Main } from "../../lib";
import RateClassEdit from "../RateClass/Identity/RateClassEdit";
import { useApp, useAPI } from "../../../providers/AppProvider";
import Collection from "./Collection";

/**
 * RateClasses
 *
 * Renders a list of Rate Classes
 *
 */
const Index = () => {
  const [rateClasses, setRateClasses] = useState(null);
  const [showAddRateClass, setShowAddRateClass] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    fetchRateClasses();
  }, []);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * FetchRateClasses
   *
   * Calls the api to fetch rate types
   */
  const fetchRateClasses = () => {
    const notifyHandle = app.notify("Loading Rate Classes");

    //
    //  Request rate types
    //
    api
      .fetch(`/api/rateclasses/?verbose`)
      .then(({ payload: rateClasses }) => {
        setRateClasses(rateClasses);
        setLoaded(true);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnSaved
   *
   * Fired once the IdentiyEdit dialog has created and saved a new
   * rate class.
   *
   * Reload the rate classes and hide the dialog.
   *
   * @param {*} event
   */
  const onSaved = () => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Saving");

    fetchRateClasses();
    setShowAddRateClass(false);
  };

  /**
   * OnDelete
   *
   * Fired when a user clicks the delete button on a rate class.
   *
   * Deletes the requested rate class and reloads the updated set
   * of rate classes.
   *
   * @param {*} rateClass
   */
  const onDelete = (rateClass) => {
    if (
      !rateClass ||
      !window.confirm("Are you sure you want to delete this rate class?")
    )
      return;

    //
    //  Status message
    //
    const notifyHandle = app.notify(`Deleting ${rateClass.name}`);

    api
      .delete(`/api/rateclasses/rateclass/${rateClass.id}`)
      .then(() => {
        app.endNotify(notifyHandle);
        fetchRateClasses();
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle Methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Main
      header={{
        icon: <RequestQuote />,
        title: `Rate Classes for ${process.env.REACT_APP_ORG_NAME}`,
        bonus: (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setShowAddRateClass(true)}
          >
            New Rate Class
          </Button>
        ),
      }}
      loaded={loaded}
    >
      {loaded && !rateClasses ? (
        "No Rate Classes Available"
      ) : (
        <Collection rateClasses={rateClasses} onDelete={onDelete} />
      )}
      <RateClassEdit
        open={showAddRateClass}
        onClose={() => setShowAddRateClass(false)}
      />
    </Main>
  );
};

export default Index;
