/********************************************************************
 *
 * MainMenu.jsx
 *
 * Copyright 2020 Canadian Coastal Sailing, Inc. All rights reserved.
 *
 ********************************************************************/

import React from "react";
import PropTypes from "prop-types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { List, ListItem, Link, Menu, MenuItem } from "@mui/material";

/**
 * MainMenu
 *
 * Renders the side menu for smaller displays
 *
 * @param {*} props
 */
const MenuMenu = ({ items }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <List
      component="nav"
      sx={{
        display: {xs:"none", md:"flex"},
        border: "0",
        padding: "0 1.2em",
      }}
    >
      {items.map(({ label, hide, path, children }, index) => (
        <React.Fragment key={index}>
          {!hide && (
            <ListItem key={index} component="div">
              <Link
                id={`menuItem${index}`}
                variant="button"
                href={path}
                onClick={handleClick}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  whiteSpace: "nowrap",
                  cursor: "pointer",
                  color: "text.primary",
                  "&:hover": {
                    color: "primary.main",
                  },
                }}
              >
                <div>{label}</div> {children && <ExpandMoreIcon />}
              </Link>
              {children && (
                <Menu
                  id={`menu${index}`}
                  anchorEl={anchorEl}
                  keepMounted
                  open={!!anchorEl && anchorEl.id == `menuItem${index}`}
                  anchorReference="anchorEl"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  onClose={handleClose}
                >
                  {children.map(({ label, hide, path }, subindex) => {
                    if (hide) return null;
                    return (
                      <MenuItem
                        key={subindex}
                        sx={
                          ({
                            display: "flex",
                            alignItems: "center",
                            whiteSpace: "nowrap",
                            cursor: "pointer",
                            color: "text.primary",
                            "&:hover": {
                              color: "primary.main",
                            },
                          },
                          {
                            "&:hover": {
                              backgroundColor: "background.default",
                            },
                          })
                        }
                      >
                        <Link color="textPrimary" href={path}>
                          {label}
                        </Link>
                      </MenuItem>
                    );
                  })}
                </Menu>
              )}
            </ListItem>
          )}
        </React.Fragment>
      ))}
    </List>
  );
};

/*
 **  PropTypes
 */
MenuMenu.propTypes = {
  items: PropTypes.array.isRequired,
};

export default MenuMenu;
