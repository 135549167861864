/********************************************************************
 *
 * Tour.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router";
import { Box, Button } from "@mui/material";
import { DirectionsBoat } from "@mui/icons-material";
import { Main } from "../../lib";
import WaterLevels from "../../lib/WaterLevels";
import TourStatus from "../libs/TourStatus";
import Summary from "./Summary";
import Products from "./Products";
import Orders from "./Orders";
import Tickets from "./Tickets";
import Shifts from "./Shifts";
import { useApp, useAPI } from "../../../providers";

/**
 * Tour
 *
 * Adds or edits a tour. Even though we are editting a
 * tour, we send the product id as a parameter. There are
 * constraints on the assets and employees that are assigned to
 * a specific product type, so we therefore need to be aware of the
 * context of the tour.
 *
 */
const Tour = () => {
  const [tour, setTour] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();
  const app = useApp();
  const api = useAPI();

  const { id: tourId } = useParams();

  /**
   * Tour State Management
   *
   * If tour id is a paramter, we are in edit mode, otherwise
   * we are creating a new tour
   */
  useEffect(() => {
    //
    //  Initilize Assets available for the tour
    //
    fetchTour(tourId);
  }, [tourId]);

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * FetchTour
   *
   * @param {*} tourId
   */
  const fetchTour = (tourId) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading Tour");

    api
      .fetch(`/api/tours/tour/${tourId}?verbose`)
      .then(({ payload: tour }) => {
        setTour(tour);
        setLoaded(true);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnUpdate
   *
   * Fired when a child updates the data object.
   *
   * @param {*} event
   */
  const onUpdate = () => {
    fetchTour(tourId);
  };

  /**
   * OnDelete
   *
   * Fired when the user deletes the tour
   *
   * @param {*} event
   */
  const onDelete = (event) => {
    event.preventDefault();

    if (!window.confirm("Are you sure you want to delete this tour?")) return;

    const notifyHandle = app.notify("Deleting");

    if (!tour.id) {
      //
      //  Nothing to delete
      //
      navigate(`/app/goods/tours/`);
    }
    api
      .delete(`/api/tours/tour/${tour.id}`)
      .then(() => {
        setTour(null);
        app.endNotify(notifyHandle);

        navigate(`/app/goods/tours/`);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Main
      header={{
        icon: <DirectionsBoat />,
        title: `${!tour ? "New" : ""} Tour ${tour && tour.id}`,
        bonus: (
          <Box sx={{ display: "flex", gap: 2 }}>
            <TourStatus
              tour={tour}
              onTourUpdated={(e) => {
                setTour(e.tour);
              }}
            />
            {true && (
              <Button
                variant="outlined"
                color="primary"
                onClick={onDelete}
                disabled={!tour || !tour.id}
              >
                Delete
              </Button>
            )}
          </Box>
        ),
      }}
      loaded={loaded}
    >
      {!tour ? (
        "Tour information not available"
      ) : (
        <Box
          sx={{
            display: "grid",
            gap: 4,
            gridTemplateColumns: "repeat(12, 1fr)",
          }}
        >
          <Box
            sx={{
              gridColumn: { xs: "1/span 12", lg: "1/span 4" },
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: 4,
            }}
          >
            <Summary tour={tour} onUpdate={onUpdate} />
            <Products tour={tour} />
          </Box>
          <Box sx={{ gridColumn: { xs: "1/span 12", lg: "5/span 8" } }}>
            <WaterLevels
              date={tour ? tour.start : null}
              showDate={false}
              tz={tour && tour.tz}
              departureTime={tour ? tour.start : null}
              returnTime={tour ? tour.end : null}
            />
          </Box>
          <Box sx={{ gridColumn: { xs: "1/span 12", lg: "1/span 8" } }}>
            <Orders tour={tour} />
          </Box>
          <Box sx={{ gridColumn: { xs: "1/span 12", lg: "9/span 4" } }}>
            <Shifts tour={tour} />
          </Box>
          <Box sx={{ gridColumn: { xs: "1/span 12" } }}>
            <Tickets tour={tour} />
          </Box>
        </Box>
      )}
    </Main>
  );
};

export default Tour;
