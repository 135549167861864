/********************************************************************
 *
 * /Catalog/Products/Identity/Edit.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2021 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { Box, FormControlLabel, Switch } from "@mui/material";
import * as yup from "yup";
import { EditorPane, useEditorContext, WidgetFrame } from "../../../lib";
import format from "../../../../utils/format";
import { useApp, useAPI } from "../../../../providers";

import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

/**
 * IdentityEdit
 *
 * Edits product details.
 *
 * @param {*} props
 */
const IdentityEdit = ({ product, show, onSaved, onCancelled }) => {
  const app = useApp();
  const api = useAPI();

  ///////////////////////////////////////////////////////////////////
  //
  //  Utiltiy Methods
  //
  ///////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnSave
   *
   * Fired when the user commits the change.
   *
   * @param {*} values
   */
  const onSave = (values) => {
    //
    //  Save
    //
    const notifyHandle = app.notify("Saving Product");

    api
      .update(`/api/products/product/${values.id ? values.id : ""}`, values)
      .then(({ payload: product }) => {
        onSaved && onSaved({ product });
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  /**
   * OnCancel
   *
   * @param {*} dirty
   */
  const onCancel = (dirty) => {
    if (
      !dirty ||
      window.confirm(
        "You have unsaved changes. Are you sure you want to close this dialog and loose your changes?"
      )
    ) {
      onCancelled && onCancelled();
    }
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <EditorPane
      title="Manage Product"
      initialValues={product}
      onSubmit={(product) => {
        onSave(product);
      }}
      validationSchema={yup.object().shape({})}
      open={!!show}
      onClose={onCancelled}
    >
      <FormBody product={product} onSave={onSave} onCancel={onCancel} />
    </EditorPane>
  );
};

/**
 * FormBody
 *
 * @param {*} props
 */
const FormBody = () => {
  const {
    values,
    touched,
    errors,
    dirty,
    setFieldValue,
    setValues,
    handleChange,
    handleBlur,
  } = useEditorContext();

  console.log(values);
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <WidgetFrame>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Box sx={{ pb: 2 }}>
            <FormControlLabel
              control={<Switch label />}
              label="Active (not impl)"
            />
          </Box>
          <Box
            sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}
          >
            <DateTimePicker
              id="start"
              label="Start"
              value={format.toDateTime(values.start, values.timezone)}
              onChange={(data) => {
                setFieldValue("start", format.toISO(data));
              }}
              error={!!touched.start && !!errors.start}
              helperText={errors.start || " "}
              sx={{ width: "100%" }}
            />
            <DateTimePicker
              id="end"
              label="End"
              value={format.toDateTime(values.end, values.timezone)}
              onChange={(data) => {
                setFieldValue("end", format.toISO(data));
              }}
              error={!!touched.end && !!errors.end}
              helperText={errors.end || " "}
              sx={{ width: "100%" }}
            />
          </Box>
        </Box>
      </WidgetFrame>
    </Box>
  );
};

export default IdentityEdit;
