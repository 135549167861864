/********************************************************************
 *
 * Product.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { DateTime } from "luxon";
import { Box, Avatar } from "@mui/material";
import { Main } from "../../lib";
import Skus from "./SKUs";
import Orders from "./Orders";
import Features from "./Features";
import Identity from "./Identity";
import { useApp, useAPI } from "../../../providers/AppProvider";

/**
 * Product
 *
 * Renders a product.
 */
const Product = () => {
  const [product, setProduct] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();
  const canBook =
    true ||
    (product &&
      !product.deleted &&
      product.available > 0 &&
      DateTime.fromISO(product.start) > DateTime.now());
  const { id: productId } = useParams();

  useEffect(() => {
    fetchProduct(productId);
  }, [productId]);

  ///////////////////////////////////////////////////////////////////////
  //
  //  Helper methods
  //
  ///////////////////////////////////////////////////////////////////////

  /**
   * FetchProduct
   *
   * @param {*} productId
   */
  const fetchProduct = (productId) => {
    if (!productId) throw new Error("Parameter 'id' is required.");

    api
      .fetch(`/api/products/product/${productId}`)
      .then(({ payload: product }) => {
        setProduct(product);
        setLoaded(true);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnUpdate
   *
   * Fired when a child updates the data object.
   *
   * @param {*} event
   */
  const onUpdate = () => {
    //
    //  If product type id is a paramter, we are in edit mode, otherwise
    //  we are creating a new product type
    //
    fetchProduct(productId);
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle Methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Main
      header={{
        icon: product && product.imageURL && (
          <Avatar alt={product.name} src={product.imageURL} />
        ),
        title: product && `${product.name} (${product.id})`,
        callout: product &&
          product.deleted && { label: "deleted", color: "error" },
      }}
      loaded={loaded}
    >
      {loaded && !product && "Product Not Available"}
      {product && (
        <>
          <Box
            sx={{
              display: "grid",
              gap: 4,
              gridTemplateColumns: "repeat(12, 1fr)",
            }}
          >
            <Box sx={{ gridColumn: { xs: "1/span 12", md: "1/span 8" } }}>
              <Identity product={product} onUpdate={onUpdate} />
            </Box>
            <Box sx={{ gridColumn: { xs: "1/span 12", md: "9/span 4" } }}>
              <Features features={product.features} />
            </Box>
            <Box sx={{ gridColumn: { xs: "1/span 12" } }}>
              <Orders product={product} />
            </Box>
            <Box sx={{ gridColumn: { xs: "1/span 12" } }}>
              <Skus product={product} canBook={canBook} />
            </Box>
          </Box>
        </>
      )}
    </Main>
  );
};

export default Product;
