/********************************************************************
 *
 * Access.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardContent,
  Tooltip,
  IconButton,
} from "@mui/material";
import { RotateLeftOutlined } from "@mui/icons-material";
import { useApp, useAccounts } from "../../../providers/AppProvider";

/**
 * User authentication and authorization component.
 *
 * @param {*} param0
 * @returns
 */
const Access = ({ employee }) => {
  const app = useApp();
  const api = useAccounts();

  if (!employee) return "";

  const reset = (employee) => {
    if (
      !window.confirm(`This will reset ${employee.fname}'s account. Proceed?`)
    )
      return;

    //
    //  Status message
    //
    const notifyHandle = app.notify("Resetting user account");

    api
      .create(`/admin/notify/user/${employee.id}/configure`)
      .then(() => {
        app.endNotify(notifyHandle);
        window.alert("Account reset.");
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  return (
    <Card>
      <CardHeader
        title="Access"
        action={
          <Tooltip title="Reset password">
            <IconButton
              aria-label="settings"
              onClick={() => {
                reset(employee);
              }}
              size="large"
            >
              <RotateLeftOutlined />
            </IconButton>
          </Tooltip>
        }
      />
      <CardContent></CardContent>
    </Card>
  );
};

/**
 * PropTypes
 *
 */
Access.propTypes = {
  employee: PropTypes.object,
};

export default Access;
