/********************************************************************
 *
 * /src/components/sales/details/index.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { useOutletContext } from "react-router";
import { Grid, Box } from "@mui/material";
import NoteEditor from "../../../lib/NoteEditor";
import CustomerCard from "../../../lib/CustomerCard/CustomerCard";
import Charge from "./Charge";
import Register from "./Register";

/**
 * Details
 *
 * Component that displays the information for an order.
 *
 */
const Details = () => {
  const { order, onUpdated, onSave } = useOutletContext();

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnUpdateCustomer
   *
   * Fired when the order's customer is updated.
   *
   * @param {*} event
   */
  const onUpdateCustomer = ({ customer }) => {
    order.customer = customer;
    onSave({ order });
  };

  /**
   * OnUpdateNote
   *
   * Fired when the user leaves the edit text box
   *
   * @param {*} event
   */
  const onUpdateNote = ({ message }) => {
    order.message = message;
    onSave({ order });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  if (!order) return null;

  return (
    <Grid container spacing={4}>
      <Grid item container spacing={4} sm={12} md={8}>
        <Grid item xs={12}>
          {!order.charges && "No items"}
          {order.charges &&
            order.charges.items.map((charge, index) => (
              <Box
                key={charge.id}
                style={{ paddingTop: index === 0 ? 0 : "32px" }}
              >
                <Charge
                  key={charge.id}
                  order={order}
                  charge={charge}
                  onUpdated={onUpdated}
                />
              </Box>
            ))}
        </Grid>
        <Grid item xs={12}>
          <CustomerCard
            customer={order.customer}
            onCustomerSelect={onUpdateCustomer}
          />
        </Grid>
        <Grid item xs={12}>
          <NoteEditor message={order.message} onSave={onUpdateNote} />
          <small className="text-muted">
            Notes for order (customer does not see these notes)
          </small>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4}>
        <Register order={order} onUpdated={onUpdated} />
      </Grid>
    </Grid>
  );
};

export default Details;
