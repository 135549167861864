/********************************************************************
 *
 * Notifications.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { useOutletContext } from "react-router";
import { Grid2 as Grid } from "@mui/material";
import { WidgetFrame, Property } from "../../lib";

const TEMPLATES = {
  "bookingMobileTour.hbs": "Tour - Mobile",
  "bookingEmailXfer.hbs": "Transfer - Email",
};

/**
 * Notifications
 *
 * Component that displays supplier notification information.
 *
 */
const Notifications = () => {
  const { tourType: goodType } = useOutletContext();

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * Render
   *
   * Renders the descriptive information for a good type and a
   * modal edit dialog (hidden).
   *
   */

  if (!goodType) return null;

  return (
    <WidgetFrame
      title={`Booking notification details for ${goodType.supplier.name}`}
    >
      <Property>
        <Grid container>
          <Grid item xs={4} md={2}>
            <b>Email:</b>
          </Grid>
          <Grid item xs={8} md={10}>
            {!goodType.supplier.bookingEmails &&
              "Email has not been initialized."}
            {goodType.supplier.bookingEmails}
          </Grid>
          <Grid item xs={4} md={2}>
            <b>Template:</b>
          </Grid>
          <Grid item xs={8} md={10}>
            {!goodType.supplier.template && "Template has not been seleceted."}
            {TEMPLATES[goodType.supplier.template]
              ? TEMPLATES[goodType.supplier.template]
              : goodType.supplier.template}
          </Grid>
        </Grid>
      </Property>
    </WidgetFrame>
  );
};

export default Notifications;
