/********************************************************************
 *
 * Alerts.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useEffect, useState } from "react";
import { Chip } from "@mui/material";
import moment from "moment";
import format from "../../../utils/format";
import { HorizontalGroup } from "../../lib/Groups";
import { useApp, useAPI } from "../../../providers/AppProvider";

/**
 * Alerts
 *
 * Renders daily alerts
 *
 */
const Alerts = ({ date }) => {
  const [alerts, setAlerts] = useState(null);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    fetchAlerts(date);
  }, [date]);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * FetchAlerts
   *
   * Calls the api to fetch alerts
   *
   * @param {*} date
   */
  const fetchAlerts = (date) => {
    const _date = moment(date);

    if (!date || !_date.isValid()) return;

    // if (!_date.isValid()) throw new Error("Invalid date format");

    //
    //  Request alerts
    //
    api
      .fetch(
        `/api/alerts/daterange/${format.toApiDateTime(
          moment(_date).startOf("day")
        )}/${format.toApiDateTime(moment(_date).endOf("day"))}`
      )
      .then(({ payload: alerts }) => {
        setAlerts(alerts);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle Methods
  //
  ///////////////////////////////////////////////////////////////////

  if (!alerts) return null;

  return (
    <HorizontalGroup>
      {alerts && alerts.map((alert) => <Alert key={alert.id} alert={alert} />)}
    </HorizontalGroup>
  );
};

/**
 * Alert
 *
 * @param {Array} props
 */
const Alert = ({ alert }) => {
  if (!alert) return null;

  return (
    <Chip
      label={alert.message}
      variant="outlined"
      color={alert.severity}
      size="small"
    />
  );
};

export default Alerts;
