/********************************************************************
 *
 * /lib/StatCard.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { Card, Box, Typography } from "@mui/material";

const StatCard = ({ label, value }) => {
  return (
    <Card
      sx={{
        overflow: "hidden",
        padding: 2,
        backgroundColor: "#e5f3f5",
      }}
      elevation={1}
    >
      <Typography variant="h2" noWrap>
        {value}
      </Typography>
      <Box
        sx={{
          display: "block",
          width: "25%",
          borderRadius: "0.65rem!important",
          borderColor: "#f4772e!important",
          backgroundColor: "#263055!important",
          border: "2px solid",
          background: "#eeeff8",
          height: "0px",
          overflow: "hidden",
          marginTop: 1,
          marginBottom: 1,
        }}
      />
      <Typography variant="overline" color="textPrimary" noWrap gutterBottom>
        {label}
      </Typography>
    </Card>
  );
};

export default StatCard;
