/********************************************************************
 *
 * /Sales/Campaigns/Campaigns.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2021 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import { Announcement } from "@mui/icons-material";
import { Main, CardList, LinkCard2, CardAction } from "../../lib";
import Pagination from "../../lib/Pagination";
import {
  CampaignSummaryHeader,
  CampaignSummary,
  CampaignEdit,
} from "./Identity";
import { useApp, useAPI } from "../../../providers/AppProvider";

const PAGE_SIZE = 15;

/**
 * Campaigns
 *
 * Renders a list of Campaigns.
 */
const Campaigns = () => {
  const [campaigns, setCampaigns] = useState(null);
  const [campaignCount, setCampaignCount] = useState(null);
  const [currPage, setCurrPage] = useState(1);
  const [pageSize] = useState(PAGE_SIZE);
  const [loaded, setLoaded] = useState(false);
  const [edit, setEdit] = useState(null);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    fetchCampaigns(currPage, pageSize);
  }, [currPage, pageSize]);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * FetchCampaigns
   *
   * Calls the api to fetch campaigns
   *
   * @param {*} currPage
   * @param {*} pageSize
   */
  const fetchCampaigns = (currPage, pageSize) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading");

    //
    //  Request campaigns
    //
    api
      .fetch(`/api/campaigns/?page=${currPage}&limit=${pageSize}`)
      .then(({ payload: campaigns, max }) => {
        setCampaigns(campaigns);
        setCampaignCount(max);
        setLoaded(true);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setCampaigns(null);
        setCampaignCount(null);
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////////

  const onDelete = (campaignId) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading");

    //
    //  Request campaigns
    //
    api
      .delete(`/api/campaigns/campaign/${campaignId}`)
      .then(() => {
        app.endNotify(notifyHandle);
        fetchCampaigns(currPage, pageSize);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Main
      header={{
        icon: <Announcement />,
        title: `Campaigns for ${process.env.REACT_APP_ORG_NAME}`,
        bonus: (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setEdit(true)}
          >
            Create a new campaign
          </Button>
        ),
      }}
      loaded={loaded}
    >
      <Box
        display="flex"
        alignItems="baseLine"
        justifyContent="space-between"
        flexWrap="wrap"
        mb={2}
      >
        <div />
        <Pagination
          currPage={currPage}
          totalRecords={campaignCount}
          pageSize={pageSize}
          pageNeighbours={1}
          onPageChanged={(e) => {
            setCurrPage(e.currPage);
          }}
        />
      </Box>
      <CardList
        xs={12}
        md={4}
        spacing={2}
        items={campaigns}
        deleted={(campaign) => campaign.deleted}
        onRenderCard={({ item }) => (
          <CampaignCard campaign={item} onDelete={onDelete} />
        )}
      />
      <CampaignEdit
        show={!!edit}
        onSaved={() => {
          setEdit(null);
          fetchCampaigns(currPage, pageSize);
        }}
        onCancelled={() => setEdit(false)}
      />
    </Main>
  );
};

/**
 * CampaignCard
 *
 * @param {*} props
 */
const CampaignCard = ({ campaign, onDelete }) => (
  <LinkCard2
    link={`/app/sales/campaign/${campaign.id}`}
    header={<CampaignSummaryHeader campaign={campaign} />}
    content={<CampaignSummary campaign={campaign} />}
    actions={
      <>
        <CardAction to={`/app/sales/campaign/${campaign.id}`} label="Edit" />
        <CardAction to="#" label="Publish" />
        <CardAction onClick={() => onDelete(campaign.id)} label="Delete" />
      </>
    }
  />
);

export default Campaigns;
