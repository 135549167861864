/********************************************************************
 *
 * DateTimePicker.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import { DateTimePicker } from "@mui/x-date-pickers";
import { Box, Typography, FormHelperText } from "@mui/material";

/**
 * DateTimePicker
 *
 * Component to manage dates and times within the context of a
 * specified timezone. If a timezone is not provided, the browser's
 * default timezone is used.
 *
 */
const DTPicker = ({
  value,
  helperText,
  error,
  tz,
  label,
  showUTC,
  onChange,
}) => {
  const dateTime = DateTime.fromISO(value).setZone(tz);

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////
  return (
    <Box>
      <DateTimePicker
        label={label}
        ampm={false}
        value={dateTime}
        onChange={onChange}
        sx={{ width: "100%" }}
      />
      {showUTC && (
        <Typography variant="caption" component="div">
          {dateTime.toUTC().toString()}
        </Typography>
      )}
      {tz && (
        <Typography variant="caption" component="div">
          {dateTime.setLocale().toLocaleString({
            ...DateTime.DATETIME_SHORT,
            weekday: "short",
            timeZoneName: "short",
          })}
        </Typography>
      )}
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </Box>
  );
};

/*
 **  PropTypes
 */
DTPicker.propTypes = {
  value: PropTypes.instanceOf(DateTime).isRequired,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  tz: PropTypes.string,
  label: PropTypes.string,
  showUTC: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default DTPicker;
