import CustomerSection from "../components/CRM";
import Customers from "../components/CRM/Customers";
import Customer from "../components/CRM/Customer";
import Identity from "../components/CRM/Customer/Identity";
import History from "../components/CRM/Customer/History";
import Communications from "../components/CRM/Customer/Communications/Communications";
import Communication from "../components/CRM/Customer/Communications/Communication";
import Account from "../components/CRM/Customer/Account";
import UserExperience from "../components/CRM/UserExperience";
import Sessions from "../components/lib/Analytics/Sessions/UserSessions";
import Reviews from "../components/CRM/Reviews/Reviews";
import Review from "../components/CRM/Reviews/Review";
import Redirect from "./Redirect";

export default {
  path: "/app/crm/",
  element: <CustomerSection />,
  children: [
    {
      path: "/app/crm/customers/",
      element: <Customers />,
    },
    {
      path: "/app/crm/customer/:id",
      element: <Customer />,
      children: [
        {
          path: "/app/crm/customer/:id/details",
          element: <Identity />,
        },
        {
          path: "/app/crm/customer/:id/history",
          element: <History />,
        },
        {
          path: "/app/crm/customer/:id/communication/:communicationId",
          element: <Communication />,
        },
        {
          path: "/app/crm/customer/:id/communication",
          element: <Communications />,
        },
        {
          path: "/app/crm/customer/:id/account",
          element: <Account />,
        },
        {
          path: "/app/crm/customer/:id",
          element: <Redirect to={`/app/crm/customer/:id/details`} />,
        },
      ],
    },
    {
      path: "/app/crm/userexperience/",
      element: <UserExperience />,
      children: [
        {
          path: "/app/crm/userexperience/:userId",
          element: <UserExperience />,
          children: [
            {
              path: "/app/crm/userexperience/:userId",
              element: (
                <Redirect to={`/app/crm/userexperience/:userId/details`} />
              ),
            },
            {
              path: "/app/crm/userexperience/:userId/details",
              element: <Sessions />,
            },
          ],
        },
      ],
    },
    {
      path: "/app/crm/reviews/",
      element: <Reviews />,
    },
    {
      path: "/app/crm/review/:id",
      element: <Review />,
    },
    {
      path: "/app/crm/review",
      element: <Review />,
    },
  ],
};
