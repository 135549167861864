/********************************************************************
 *
 * Analytics/TotalPAX.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2021 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Avatar } from "@mui/material";
import { PeopleAlt } from "@mui/icons-material";
import { SuperStat } from "../../lib";
import { useApp, useAnalyticsAPI } from "../../../providers/AppProvider";

const TotalPAX = () => {
  const [totalTickets, setTotalTickets] = useState(null);
  const app = useApp();
  const analyticsApi = useAnalyticsAPI();

  useEffect(() => {
    fetchTotalTicketCount();
  }, []);

  const fetchTotalTicketCount = () => {
    analyticsApi
      .fetch("/api/tickets/earned/")
      .then(({ payload: count }) => {
        setTotalTickets(count);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  return (
    <SuperStat
      icon={
        <Avatar style={{ backgroundColor: "blue" }}>
          <PeopleAlt />
        </Avatar>
      }
      label="Guests Served"
      value={totalTickets}
    />
  );
};

export default TotalPAX;
