/********************************************************************
 *
 * WorkOrder.jsx
 *
 * Renders a work item.
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Engineering } from "@mui/icons-material";
import { Avatar, Box, Chip, Typography } from "@mui/material";
import { Main } from "../../../lib";
import format from "../../../../utils/format";
import Summary from "./Summary";
import WorkOrderEdit from "../lib/WorkOrderEdit";
import WorkOrderItems from "./Items";
import WorkOrderNotes from "./Notes";
import { useApp, useMaintenanceAPI } from "../../../../providers/AppProvider";
import useWorkOrderReducer from "./workOrderReducer";

const PRIORITY = ["#aaaaaa", "#66c1b9", "#01899f", "#cccc33", "#cc3333"];

/**
 * Index
 *
 * @returns
 */
const Index = () => {
  const [timezone, setTimezone] = useState("America/Vancouver");
  const [showEditWorkOrder, setShowEditWorkOrder] = useState(false);
  const maintenanceAPI = useMaintenanceAPI();
  const app = useApp();
  const [state, dispatch, actions] = useWorkOrderReducer();
  const { id } = useParams();

  useEffect(() => {
    fetchWorkOrder(id);
  }, [id]);

  ///////////////////////////////////////////////////////////////////////
  //
  //  Helper methods
  //
  ///////////////////////////////////////////////////////////////////////

  /**
   * FetchWorkOrder
   *
   * @param {*} id
   */
  const fetchWorkOrder = (id) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading Work Order");

    maintenanceAPI
      .fetch(`/api/workorders/workorder/${id}?verbose`)
      .then(({ payload: workOrder }) => {
        return Promise.all([
          workOrder,
          maintenanceAPI.fetch(
            `/api/workorders/workorder/${workOrder.id}/notes`
          ),
        ]);
      })
      .then(([workOrder, { payload: notes }]) => {
        workOrder.notes = notes;
        dispatch({ type: actions.INITIALIZE_WORKORDER, payload: workOrder });
      })
      .catch((error) => {
        app.error({ error });
      })
      .finally(() => {
        app.endNotify(notifyHandle);
      });
  };

  /**
   * UpdateWorkOrder
   *
   * @param {*} id
   * @param {*} mask
   */
  const updateWorkOrder = (id, mask) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Updating Work Order");

    maintenanceAPI
      .update(`/api/workorders/workorder/${id}`, mask)
      .then(({ payload: workOrder }) => {
        dispatch({ type: actions.UPDATE_WORKORDER, payload: workOrder });
      })
      .catch((error) => {
        app.error({ error });
      })
      .finally(() => {
        app.endNotify(notifyHandle);
      });
  };

  return (
    <Main
      header={{
        icon: <Engineering />,
        title: !!state.workOrder && state.workOrder.name,
        subtitle:
          !!state.workOrder &&
          `Due: ${
            !!state.workOrder.due ? format.date(state.workOrder.due) : "--"
          }`,
        callout: {
          label: (
            <Chip
              variant="filled"
              label={`${
                !!state.workOrder ? state.workOrder.priority.name : "UNKNOWN"
              }`}
              size="small"
              sx={{
                fontSize: "0.8em",
                mb: 1,
                color: "#ffffff",
                backgroundColor: !!state.workOrder
                  ? PRIORITY[state.workOrder.priority.id]
                  : undefined,
              }}
            />
          ),
        },
        bonus: (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "centered",
              alignItems: "flex-end",
              gap: 2,
              maxWidth: "200px",
            }}
          >
            <Box>
              <Typography variant="body2" sx={{ textAlign: "right" }}>
                Assigned to
              </Typography>
              <Typography variant="body2" sx={{ textAlign: "right" }}>
                {!!state.workOrder &&
                  `${state.workOrder.assignedTo.fname} ${state.workOrder.assignedTo.lname}}`}
              </Typography>
            </Box>
            <Avatar>
              {!!state.workOrder && state.workOrder.assignedTo.fname[0]}
            </Avatar>
          </Box>
        ),
      }}
      loaded={state.loaded}
    >
      <Box
        sx={{
          display: "flex",
          gap: 4,
          flexDirection: { xs: "column", md: "row" },
          width: "100%",
        }}
      >
        <Box sx={{ flexBasis: "30%" }}>
          <Summary
            workOrder={state.workOrder}
            timezone={timezone}
            reducer={{ state, dispatch, actions }}
            onEdit={() => {
              setShowEditWorkOrder(true);
            }}
            onUpdate={(mask) => {
              updateWorkOrder(!!state.workOrder && state.workOrder.id, mask);
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
            flexBasis: "70%",
          }}
        >
          <WorkOrderItems
            workOrder={state.workOrder}
            reducer={{ state, dispatch, actions }}
          />
          <WorkOrderNotes
            workOrder={state.workOrder}
            reducer={{ state, dispatch, actions }}
          />
        </Box>
      </Box>
      <WorkOrderEdit
        workOrder={state.workOrder}
        open={showEditWorkOrder}
        reducer={{ dispatch, actions }}
        onClose={() => setShowEditWorkOrder(false)}
      />
    </Main>
  );
};

export default Index;
