import Public from "../components/Public";
import Schedule from "../components/Public/Schedule";

export default {
  path: "public",
  element: <Public />,
  children: [
    {
      path: "/public/schedule/:encodedId",
      element: <Schedule />,
    },
  ],
};
