/********************************************************************
 *
 * /components/goods/goods/tour/shifts.jsx
 *
 * Manages the shifts assigned to the tour.
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Avatar, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Timelapse, DeleteOutlined, MailOutlined } from "@mui/icons-material";
import {
  WidgetFrame,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Badge,
} from "../../lib";
import { useApp, useAPI } from "../../../providers/AppProvider";
import NewShift from "./NewShift";

const Shifts = ({ tour, onUpdate }) => {
  const [shifts, setShifts] = useState(null);
  const [showNewShift, setShowNewShift] = useState(false);
  const classes = useStyles();
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    if (tour) fetchShifts(tour.id);
  }, [tour]);

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * FetchShifts
   *
   * @param {*} tourId
   */
  const fetchShifts = (tourId) => {
    api
      .fetch(`/api/tours/tour/${tourId}/shifts`)
      .then(({ payload: shifts }) => {
        if (shifts && shifts.length > 0) setShifts(shifts);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  const onDeleteShift = (shiftId) => {
    if (!window.confirm("Are you sure you want to delete this shift?")) return;

    api
      .delete(`/api/tours/tour/${tour.id}/shifts/shift/${shiftId}`)
      .then(() => {
        fetchShifts(tour.id);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  /**
   * OnSaved
   *
   * Fired once the New Shift Dialog has saved a shift
   *
   * @param {*} shift
   */
  const onSaved = () => {
    fetchShifts(tour.id);
    setShowNewShift(false);
  };

  /**
   * OnCancelled
   *
   * Fired once the New Shift has aborted
   *
   */
  const onCancelled = () => {
    setShowNewShift(null);
  };

  return (
    <WidgetFrame
      avatar={
        <Avatar classes={{ root: classes.avatarRoot }}>
          <Timelapse />
        </Avatar>
      }
      title="Shifts"
      action={
        <IconButton onClick={() => setShowNewShift(true)} size="large">
          <MailOutlined />
        </IconButton>
      }
    >
      <>
        <Table>
          <TableBody>
            {!!shifts &&
              shifts.map((shift) => (
                <TableRow key={shift.id}>
                  <TableCell>
                    <IconButton
                      onClick={() => onDeleteShift(shift.id)}
                      size="large"
                    >
                      <DeleteOutlined />
                    </IconButton>
                    {`${shift.employee.fname} ${shift.employee.lname}`}
                  </TableCell>
                  <TableCell align="right">
                    {shift.status == 0 ? (
                      <Badge label="Unconfirmed" color="error" />
                    ) : shift.status == 1 ? (
                      <Badge label="Accepted" color="default" />
                    ) : (
                      <Badge label="Declined" color="warning" />
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {showNewShift && (
          <NewShift
            tour={tour}
            shifts={shifts}
            show={showNewShift}
            onSaved={onSaved}
            onCancelled={onCancelled}
          />
        )}
      </>
    </WidgetFrame>
  );
};

const useStyles = makeStyles((theme) => ({
  avatarRoot: { backgroundColor: theme.palette.primary.main },
}));

export default Shifts;
