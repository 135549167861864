/********************************************************************
 *
 * TourType.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Outlet } from "react-router";
import { Extension } from "@mui/icons-material";
import { Main } from "../../lib";
import {
  SectionList,
  SectionItemGroup,
  SectionItem,
} from "../../lib/SideBar/Menu";
import { useApp, useAPI } from "../../../providers/AppProvider";

/**
 * TourType
 *
 * Container component for managing a tour type.
 *
 * The tour type id is passed in the URI and the object initialized
 * though a call to the API.
 *
 */
const TourType = () => {
  const [tourType, setTourType] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const { goodTypeId } = useParams();
  const navigate = useNavigate();
  const app = useApp();
  const api = useAPI();

  /**
   * useEffect
   *
   * Fired when the component initially mounts.
   *
   */
  useEffect(() => {
    if (!goodTypeId || goodTypeId === 0) {
      //
      //  New Tour Type Mode - initialize empty object
      //
      return;
    }

    //
    //  If tour type id is a paramter, we are in edit mode, otherwise
    //  we are creating a new tour type
    //
    fetchTourType(goodTypeId);
  }, [goodTypeId]);

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * FetchTourType
   *
   * Calls the API to fetch the requested tour type.
   *
   * If the tour id is null, create a shell tour type.
   *
   * @param {*} tourTypeId
   */
  const fetchTourType = (tourTypeId) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading");

    api
      .fetch(`/api/tourtypes/tourtype/${parseInt(tourTypeId)}/?verbose`)
      .then(({ payload: tourType }) => {
        setTourType(tourType);
        setLoaded(true);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setTourType(null);
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnUpdate
   *
   * Fired when a child updates the data object.
   *
   * @param {*} event
   */
  const onUpdate = () => {
    //
    //  If tour type id is a paramter, we are in edit mode, otherwise
    //  we are creating a new tour type
    //
    fetchTourType(goodTypeId);
  };

  /**
   * onDelete
   *
   * Handles a request in display mode to delete a feature type.
   *
   * @param {*} event
   */
  const onDelete = (event) => {
    if (!window.confirm("Are you sure you want to delete this tour type?"))
      return;

    //
    //  Status message
    //
    const notifyHandle = app.notify("Deleting");

    api
      .delete(`/api/goodtypes/tour/${tourType.id}`)
      .then(() => {
        app.endNotify(notifyHandle);
        navigate(`/app/goods/`);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  /**
   * Render
   *
   * Renders the container for the tour type.
   *
   * Most functionality is delegated to subcomponents
   * servered by routing to different URIs.
   */

  if (!tourType) return null;

  return (
    <Main
      header={{
        icon: tourType && tourType.supplier && tourType.supplier.logoURL && (
          <img alt={tourType.supplier.name} src={tourType.supplier.logoURL} />
        ),
        title: tourType && tourType.name,
      }}
      menu={{
        icon: <Extension />,
        title: "Manage Tours",
        subtitle:
          "Add, modify and delete tour times and manage booking notifications.",
        nav: <Menu tourType={tourType} />,
      }}
      loaded={loaded}
    >
      <Outlet context={{ tourType, onUpdate }} />
    </Main>
  );
};

/**
 * Menu
 *
 * Renders the navigation controls for the functional areas of the
 * tour type management page.
 *
 * @param {*} props
 */
const Menu = ({ tourType }) => {
  const { id = 0 } = tourType || {};

  return (
    <SectionList>
      <SectionItemGroup icon={<Extension />} name="Setup" open={true}>
        <SectionItem to={`/app/goods/tourtype/${id}/schedule`}>
          Schedule
        </SectionItem>
        <SectionItem to={`/app/goods/tourtype/${id}/notifications`}>
          Notifications
        </SectionItem>
      </SectionItemGroup>
    </SectionList>
  );
};

export default TourType;
