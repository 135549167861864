/********************************************************************
 *
 * OrderProvider.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { CancelOutlined } from "@mui/icons-material";
import { useApp, useAPI } from "../providers/AppProvider";

const OrderContext = React.createContext({
  order: null,
  setOrder: (order) => null,
  clearOrder: () => null,
});

/**
 * OrderProvider
 *
 * @param {*} params
 */
const OrderProvider = ({ orderId, children }) => {
  const [_order, _setOrder] = useState(null);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    if (orderId) fetchOrder(orderId);
  }, [orderId]);

  /**
   * FetchAssets
   *
   */
  const fetchOrder = (orderId) => {
    api
      .fetch(`/api/orders/order/${orderId}`)
      .then(({ payload: order }) => {
        _setOrder(order);
      })
      .catch((error) => {
        _setOrder(null);
        app.error({ error });
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  API Methods
  //
  ///////////////////////////////////////////////////////////////////////

  const setOrder = (order) => {
    _setOrder(order);
  };

  const clearOrder = () => {
    _setOrder(null);
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <OrderContext.Provider value={{ order: _order, setOrder, clearOrder }}>
      {!!_order && (
        <OrderBar
          order={_order}
          onCancel={() => {
            _setOrder(null);
          }}
        />
      )}

      {children}
    </OrderContext.Provider>
  );
};

const OrderBar = ({ order, onCancel }) => (
  <Box
    sx={{
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      zIndex: 1200,
      height: "100px",
      // marginBottom: "-30px",
      display: "flex",
      bgcolor: "primary.main",
      color: "primary.contrastText",
    }}
  >
    <Box
      sx={{
        display: "flex",
        flex: "1",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
        paddingLeft: 3,
        paddingRight: 3,
      }}
    >
      <Typography>Updating Order # {order.id}</Typography>
      <IconButton onClick={onCancel} size="large">
        <CancelOutlined
          sx={{
            color: "primary.contrastText",
            fontSize: "20px",
          }}
        />
      </IconButton>
    </Box>
  </Box>
);

/*
 **  Hooks
 */
const useOrder = () => {
  return React.useContext(OrderContext);
};

export default OrderProvider;
export { useOrder };
