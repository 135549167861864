import { Navigate } from "react-router";

//
//  Entry
//
import SalesSection from "../components/Sales";

//
//  Orders
//
import Orders from "../components/Sales/Orders";
import Sales from "../components/Sales/Orders/Sales";
import Abandoned from "../components/Sales/Orders/Abandoned";

//
//  Order
//
import Order from "../components/Sales/Order";
import Details from "../components/Sales/Order/Details";
import Accounting from "../components/Sales/Order/Accounting";
import ReceiptDetails from "../components/Sales/Order/ReceiptDetails";
import Reviews from "../components/Sales/Order/Reviews";

import Partner from "../components/Sales/Partners/Partner";
import Partners from "../components/Sales/Partners/Partners";
import Lead from "../components/Sales/Lead";
import DiscountTypes from "../components/Sales/Discounts/DiscountTypes";
import { Campaigns, Campaign } from "../components/Sales/Campaigns";
import Identity from "../components/Sales/Campaigns/Identity";
import CouponGenerator from "../components/Sales/Campaigns/CouponGenerator";
import Ticket from "../components/Sales/Tickets/Ticket";
import Redirect from "./Redirect";

export default {
  path: "/app/sales/",
  element: <SalesSection />,
  children: [
    {
      path: "/app/sales/",
      element: <Navigate to={`/app/sales/orders/`} />,
    },

    {
      path: "/app/sales/orders/",
      element: <Orders />,
      children: [
        {
          path: "/app/sales/orders/sales/",
          element: <Sales />,
        },
        {
          path: "/app/sales/orders/abandoned/",
          element: <Abandoned />,
        },
        {
          path: "/app/sales/orders/",
          element: <Navigate to={`/app/sales/orders/sales`} />,
        },
      ],
    },
    {
      path: "/app/sales/order/:id/",
      element: <Order />,
      children: [
        {
          path: "/app/sales/order/:id/details",
          element: <Details />,
        },
        {
          path: "/app/sales/order/:id/accounting",
          element: <Accounting />,
        },
        {
          path: "/app/sales/order/:id/receipts",
          element: <ReceiptDetails />,
        },
        {
          path: "/app/sales/order/:id/reviews",
          element: <Reviews />,
        },
        {
          path: "/app/sales/order/:id/",
          element: <Redirect to={`/app/sales/order/:id/details`} />,
        },
      ],
    },
    {
      path: "/app/sales/ticket/:id",
      element: <Ticket />,
    },
    {
      path: "/app/sales/partners/",
      element: <Partners />,
    },
    {
      path: "/app/sales/partner/:id",
      element: <Partner />,
    },
    {
      path: "/app/sales/leads/lead/",
      element: <Lead />,
    },
    {
      path: "/app/sales/leads/lead/:id",
      element: <Lead />,
    },
    {
      path: "/app/sales/discounts/",
      element: <DiscountTypes />,
    },
    {
      path: "/app/sales/campaigns/",
      element: <Campaigns />,
    },
    {
      path: "/app/sales/campaign/:id",
      element: <Campaign />,
      children: [
        {
          path: "/app/sales/campaign/:id/description",
          element: <Identity />,
        },
        {
          path: "/app/sales/campaign/:id/coupons",

          element: <CouponGenerator />,
        },
        {
          path: "/app/sales/campaign/:id/",
          element: <Redirect to={`/app/sales/campaign/:id/description`} />,
        },
      ],
    },
  ],
};
