/********************************************************************
 *
 * Customer.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Outlet, useParams } from "react-router";
import { Avatar, Badge } from "@mui/material";
import { PersonOutlined } from "@mui/icons-material";
import { Main } from "../../lib";
import {
  SectionList,
  SectionItemGroup,
  SectionItem,
} from "../../lib/SideBar/Menu";

import { useApp, useAPI } from "../../../providers/AppProvider";

/**
 * Customer
 *
 * Renders Customer information
 */
const Customer = () => {
  const [customer, setCustomer] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  const { id: customerId } = useParams();

  useEffect(() => {
    fetchCustomer(customerId);
  }, [customerId]);

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  ///////////////////////////////////////////////////////////////////

  const fetchCustomer = (id) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading Customer");

    api
      .fetch(`/api/customers/customer/${id}?verbose`)
      .then(({ payload: customer }) => {
        setCustomer(customer);
        setLoaded(true);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setCustomer(null);
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  const onUpdate = () => {
    fetchCustomer(customerId);
  };

  return (
    <Main
      menu={{
        icon: <PersonOutlined />,
        title: "Customer Management",
        subtitle: "Information and Relationship",
        nav: <Menu customer={customer} />,
      }}
      header={{
        icon: !!customer && (
          <Avatar>
            {customer.fname && customer.fname[0]}{" "}
            {customer.lname && customer.lname[0]}
          </Avatar>
        ),
        title: customer ? (
          customer.isMinor ? (
            <Badge badgeContent="Minor" color="error">
              {`${customer.minorName ? customer.minorName : ""} Age: ${
                customer.age ? customer.age : "?"
              }`}
            </Badge>
          ) : (
            `${customer.fname} ${customer.lname}`
          )
        ) : (
          "New Customer"
        ),
      }}
      loaded={loaded}
    >
      <Outlet context={{ customer, onUpdate }} />
    </Main>
  );
};

/**
 * Renders the navigation controls for the functional areas of the
 * product type maagement page.
 *
 * @param {*} props
 */
const Menu = ({ customer }) => {
  const { id = 0 } = customer || {};

  return (
    <SectionList>
      <SectionItemGroup
        icon={<PersonOutlined />}
        name="Information"
        open={true}
      >
        <SectionItem to={`/app/crm/customer/${id}/details`}>
          Details
        </SectionItem>
        <SectionItem to={`/app/crm/customer/${id}/history`}>
          History
        </SectionItem>
      </SectionItemGroup>
      <SectionItemGroup icon={<PersonOutlined />} name="Sales" open={true}>
        <SectionItem to={`/app/crm/customer/${id}/communication`}>
          Communication
        </SectionItem>
        <SectionItem to={`/app/crm/customer/${id}/account`}>
          Account
        </SectionItem>
      </SectionItemGroup>
    </SectionList>
  );
};

export default Customer;
