/********************************************************************
 *
 * ConfigSwitchTextField.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, Switch, TextField } from "@mui/material";

const ConfigSwitchTextField = ({
  title,
  switchId,
  switchValue,
  valueId,
  value,
  helperText,
  onChangeConfig,
}) => {
  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Box sx={{ pb: 2 }}>
      <Typography>{title}</Typography>
      <Box display="flex" alignItems="baseline">
        <Switch
          name={switchId}
          checked={switchValue}
          value="1"
          onChange={({ target }) => {
            onChangeConfig({
              key: target.name,
              value: target.checked ? 1 : 0,
            });
          }}
          sx={{
            mr: 2,
          }}
        />
        <TextField
          name={valueId}
          value={value}
          onBlur={({ target }) =>
            onChangeConfig({ key: target.name, value: target.value })
          }
          helperText={helperText}
          sx={{ width: "100%" }}
        />
      </Box>
    </Box>
  );
};

/*
 **  PropTypes
 */
ConfigSwitchTextField.propTypes = {
  title: PropTypes.string,
  switchId: PropTypes.string.isRequired,
  switchValue: PropTypes.any.isRequired,
  valueId: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  helperText: PropTypes.string,
  onChangeConfig: PropTypes.func.isRequired,
};

export default ConfigSwitchTextField;
