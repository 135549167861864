/********************************************************************
 *
 * CardAction.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import { Link } from "@mui/material";

/**
 * CardAction
 *
 * @param {*} param0
 *
 * @returns
 */
const CardAction = ({ to = "#", label, highlight, onClick }) => (
  <Link
    href={to}
    onClick={onClick}
    variant="h6"
    sx={[
      { padding: "0.25em 0.5em" },
      highlight
        ? {
            color: "primary.main",
            textTransform: "uppercase",
            borderStyle: "solid",
            borderWidth: "1px",
            borderRadius: "8px",
            borderColor: "primary.light",
          }
        : null,
    ]}
  >
    {label}
  </Link>
);

/*
 **  PropTypes
 */
CardAction.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
  label: PropTypes.string.isRequired,
};

export default CardAction;
