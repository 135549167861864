/********************************************************************
 *
 * ProductFactory.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Typography, Button } from "@mui/material";

import format from "../../../../utils/format";
import Template from "./Template";
import ModalDialog, {
  DialogContent,
  DialogActions,
} from "../../../lib/ModalDialog";
import { useApp, useAPI } from "../../../../providers/AppProvider";

/**
 * ProductFactory
 *
 * Dialog for creating new products
 *
 */
const ProductFactory = ({ productType, start, end, show, onClose }) => {
  const [templates, setTemplates] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * FetchTemplates
   *
   * Calls the api to fetch the product templates generated from the
   * goods available to theproduct type
   *
   */
  useEffect(() => {
    if (!productType) throw new Error("'ProductType' property cannot be null.");

    fetchTemplates(productType, start, end);
  }, [productType, start, end]);

  ///////////////////////////////////////////////////////////////////
  //
  //  Helper Functions
  //
  ///////////////////////////////////////////////////////////////////

  const fetchTemplates = (productType, start, end) => {
    if (!productType || !start || !end) {
      setLoaded(true);
      return;
    }

    setLoaded(false);

    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading");

    api
      .fetch(
        `/api/producttypes/producttype/${
          productType.id
        }/products/build/daterange/${format.toApiDateTime(
          start
        )}/${format.toApiDateTime(end)}`
      )
      .then(({ payload: templates }) => {
        if (templates && templates.length == 0) setTemplates(null);
        else setTemplates(templates);
        setLoaded(true);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  return (
    <ModalDialog
      title={`${productType.name} Templates from ${format.date(
        start
      )} to ${format.date(end)}`}
      show={show}
      onClose={onClose}
      loading={!loaded}
    >
      <DialogContent>
        {!templates ? (
          <>
            <Typography variant="h5" paragraph>
              No product templates available for this date range.
            </Typography>
            <Typography variant="caption">
              Product templates couple not be built for this date range. If no
              error occured, check to see that goods exist for the product
              features and that the goods have the capacity to meet the
              features' consumption amounts.
            </Typography>
          </>
        ) : (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {templates.map((template, index) => (
              <Template key={index} template={template} />
            ))}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onClose}>
          Done
        </Button>
      </DialogActions>
    </ModalDialog>
  );
};

ProductFactory.propTypes = {
  productType: PropTypes.object.isRequired,
  start: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  end: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ProductFactory;
