/********************************************************************
 *
 * DetailsEdit.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { Box, TextField } from "@mui/material";
import * as yup from "yup";
import { EditorPane, useEditorContext } from "../../lib";
import { useApp, useAPI } from "../../../providers/AppProvider";

/**
 * ContactEdit
 *
 * Component for adding a new, or editting the descriptive information
 * an exiting employee.
 *
 */
const ContactEdit = ({ employee, show, onSaved, onClose }) => {
  const app = useApp();
  const api = useAPI();

  ///////////////////////////////////////////////////////////////////
  //
  //  Utiltiy Methods
  //
  ///////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnSave
   *
   * Fired when the user saves the changes
   *
   * @param {*} values
   */
  const onSave = (values) => {
    //
    //  Save
    //
    const notifyHandle = app.notify("Saving Contact Information");

    api
      .update(`/api/customers/customer/${values.id ? values.id : ""}`, values)
      .then(({ payload: customer }) => {
        onSaved && onSaved({ customer });
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  return (
    <EditorPane
      title="Manage Employee Details"
      initialValues={
        employee || { fname: "", lname: "", phone: "", email: "", imageUrl: "" }
      }
      onSubmit={(values) => {
        onSave(values);
      }}
      validationSchema={yup.object().shape({})}
      open={show}
      onClose={onClose}
    >
      <FormBody />
    </EditorPane>
  );
};

/**
 * FormBody
 *
 * @param {*} props
 */
const FormBody = ({}) => {
  const {
    values,
    touched,
    errors,
    dirty,
    setFieldValue,
    setValues,
    handleChange,
    handleBlur,
  } = useEditorContext();

  return (
    <Box>
      <TextField
        name="fname"
        label="First Name"
        value={values.fname}
        onBlur={handleBlur}
        onChange={handleChange}
        fullWidth
        error={!!touched.fname && !!errors.fname}
        helperText={(!!touched.fname && errors.fname) || " "}
      />
      <TextField
        name="lname"
        label="Last Name"
        value={values.lname}
        onBlur={handleBlur}
        onChange={handleChange}
        fullWidth
        error={!!touched.lname && !!errors.lname}
        helperText={(!!touched.lname && !!errors.lname) || " "}
      />
      <TextField
        name="email"
        label="Email"
        value={values.email}
        onBlur={handleBlur}
        onChange={handleChange}
        fullWidth
        error={!!touched.email && !!errors.email}
        helperText={(!!touched.email && !!errors.email) || " "}
      />
      <TextField
        id="phone"
        label="Phone"
        value={values.phone}
        onBlur={handleBlur}
        onChange={handleChange}
        fullWidth
        error={!!touched.phone && !!errors.phone}
        helperText={(!!touched.phone && errors.phone) || " "}
      />
      <TextField
        id="imageURL"
        type="url"
        label="Image URL"
        value={values.imageUrl}
        onBlur={handleBlur}
        onChange={handleChange}
        fullWidth
        error={!!touched.imageUrl && !!errors.imageUrl}
        helperText={(!!touched.imageUrl && !!errors.imageUrl) || " "}
      />
    </Box>
  );
};

export default ContactEdit;
