/********************************************************************
 *
 * Footer.jsx
 *
 * Standard footer that contains branding, nav and authentication
 * credentials for the application.
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 ********************************************************************/

import React from "react";
import { Container, Typography } from "@mui/material";

const Footer = () => (
  <Container
    className="app-footer"
    component="footer"
    disableGutters
    maxWidth={false}
    sx={{
      color: "text.primary",
    }}
  >
    <Typography variant="body1" gutterBottom>
      © {new Date().getFullYear()} Canadian Coastal Sailing Inc. All rights
      reserved.
    </Typography>
  </Container>
);

export default Footer;
