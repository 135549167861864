/********************************************************************
 *
 * /components/goods/goods/tour/newShift.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2021 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Button,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { ModalDialog, DialogContent, HorizontalGroup } from "../../lib";
import { useApp, useAPI } from "../../../providers/AppProvider";

/**
 * NewShift
 *
 * Component for adding a new, or editting the descriptive information
 * an existing Tour.
 *
 */
const NewShift = ({ tour, shifts, show, onSaved, onCancelled }) => {
  const app = useApp();
  const api = useAPI();

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnSave
   *
   * Fired when the user commits the change.
   *
   * @param {*} values
   */
  const onSave = (values) => {
    //
    //  Save
    //
    const notifyHandle = app.notify("Saving Shift");

    api
      .create(`/api/tours/tour/${tour.id}/shifts/shift/`, values)
      .then(({ payload: shift }) => {
        onSaved && onSaved({ shift });
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  /**
   * OnCancel
   *
   * @param {*} dirty
   */
  const onCancel = (dirty) => {
    if (
      !dirty ||
      window.confirm(
        "You have unsaved changes. Are you sure you want to close this dialog and loose your changes?"
      )
    ) {
      onCancelled && onCancelled();
    }
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <ModalDialog title="Add Shift" subtitle="TBD" show={show} size="sm">
      <DialogContent>
        <FormBody
          tour={tour}
          shifts={shifts}
          onSave={onSave}
          onCancel={onCancel}
        />
      </DialogContent>
    </ModalDialog>
  );
};

/**
 * FormBody
 *
 * @param {*} props
 */
const FormBody = ({ tour, shifts, onSave, onCancel }) => {
  return (
    <Formik
      initialValues={{ tour, role: 0, status: 0, employee: null }}
      onSubmit={(shift) => {
        onSave(shift);
      }}
      validationSchema={yup
        .object()
        .shape({ employee: yup.object().required("Employee is required") })}
    >
      {({
        values,
        touched,
        errors,
        dirty,
        isSubmitting,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Box display="flex" justifyContent="flex-end" mb={3}>
              <HorizontalGroup gap={1}>
                <Button
                  type="submit"
                  variant="outlined"
                  color="primary"
                  disabled={!dirty || isSubmitting}
                >
                  Save
                </Button>
                <Button onClick={() => onCancel(dirty)}>Cancel</Button>
              </HorizontalGroup>
            </Box>
            <ShiftForm
              shift={values}
              tour={tour}
              shifts={shifts}
              touched={touched}
              errors={errors}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </form>
        );
      }}
    </Formik>
  );
};

const ShiftForm = ({
  shift,
  tour,
  shifts,
  touched,
  errors,
  setFieldValue,
  onBlur,
  onChange,
}) => {
  const [employees, setEmployees] = useState([]);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    if (tour) fetchEmployeesForTour(tour.id, shifts);
  }, [tour, shifts]);

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * FetchEmployees
   *
   * Initializes the list of employees
   */
  const fetchEmployeesForTour = (tourId, shifts) => {
    if (!tourId) return;

    api
      .fetch(`/api/tours/tour/${tourId}/employees`)
      .then(({ payload: employees }) => {
        if (employees && employees.length > 0) {
          const availableEmployees = employees.filter(
            (employee) =>
              !(
                shifts &&
                shifts.find((shift) => shift.employee.id == employee.id)
              )
          );
          setEmployees(availableEmployees);
        }
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  return (
    <>
      <Typography variant="h4">Select an employee to join the tour</Typography>
      <Box mt={4} mb={6}>
        <FormControl fullWidth>
          <InputLabel id="employee-label">Employee</InputLabel>
          <Select
            inputProps={{
              name: "employee",
              id: "employee",
            }}
            labelId="employee-label"
            value={!!shift.employee ? shift.employee.id : -1}
            onChange={(e) => {
              setFieldValue(
                "employee",
                employees.find((employee) => (employee.id = e.target.value))
              );
            }}
            fullWidth
            required
          >
            <MenuItem value="-1" disabled>
              -- Select --
            </MenuItem>
            {employees &&
              employees.map((employee) => (
                <MenuItem key={employee.id} value={employee.id}>
                  {employee.fname} {employee.lname}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    </>
  );
};

export default NewShift;
