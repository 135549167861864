/********************************************************************
 *
 * Month.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { Box } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import moment from "moment";
import Week from "./Week";

/**
 * Month
 *
 * Renders a row of seven CalandarDays on the calendar grid.
 *
 */
const Month = ({
  numWeeks,
  startDate,
  selectedDate,
  onDateRender,
  onClickDate,
}) => {
  const classes = useStyles();

  if (!startDate || !moment(startDate).isValid()) return null;

  return (
    <div>
      <div className={classes.dow}>
        <div>Su</div>
        <div>Mo</div>
        <div>Tu</div>
        <div>We</div>
        <div>Th</div>
        <div>Fr</div>
        <div>Sa</div>
      </div>
      <Box
        className={classes.container}
        display="flex"
        flexDirection="column"
        alignItems="stretch"
      >
        {[...Array(numWeeks).keys()].map((key, index) => (
          <Week
            key={key}
            startDate={moment(startDate).add(index, "w")}
            selectedDate={selectedDate}
            onDateRender={onDateRender}
            onClickDate={onClickDate}
          />
        ))}
      </Box>
    </div>
  );
};

const useStyles = makeStyles({
  dow: {
    width: "100% !important",
    height: "1.65em !important",
    borderBottom: "1px solid rgb(235, 235, 235) !important",
    "& div": {
      width: "14.2857% !important",
      height: "100% !important",
      display: "inline-block !important",
      position: "relative !important",
      paddingLeft: "5px",
    },
  },
  container: {
    borderRight: "solid 1px rgb(235, 235, 235)",
  },
});

export default Month;
