/********************************************************************
 *
 * Assets.jsx
 *
 * Renders a control that allows a user to select from a collection
 * of maintainable assets.
 *
 * TODO: Decide on how maintenance assets relate to assets in the
 * scheduling system. Do these assets need to be merged into a
 * master set of assets.
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { TextField, MenuItem } from "@mui/material";
import { useApp, useMaintenanceAPI } from "../../../../providers/AppProvider";

/**
 * Assets
 *
 * @param {*} param0
 */
const Assets = ({
  name,
  label = "Asset",
  value = "",
  disabled,
  onChange,
  onBlur,
  error = false,
  helperText,
  sx,
}) => {
  const [loaded, setLoaded] = useState(true);
  const [assets, setAssets] = useState(null);
  const maintenanceAPI = useMaintenanceAPI();
  const app = useApp();

  useEffect(() => {
    fetchWorkOrderAssets();
  }, []);

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * FetchWorkOrderAssets
   */
  const fetchWorkOrderAssets = () => {
    maintenanceAPI
      .fetch(`/api/workorders/workorder/assets`)
      .then(({ payload: assets }) => {
        setAssets(assets);
        setLoaded(true);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  return (
    <TextField
      name={name}
      label={label}
      select
      value={value != null ? value : ""}
      disabled={disabled}
      onChange={onChange}
      onBlur={onBlur}
      error={error}
      helperText={helperText}
      sx={[{ width: "100%" }, sx]}
    >
      {!assets ? (
        <MenuItem>Loading...</MenuItem>
      ) : (
        assets.map((asset) => (
          <MenuItem key={asset.id} value={asset.id}>
            {asset.name}
          </MenuItem>
        ))
      )}
    </TextField>
  );
};

/**
 * PropTypes
 */
Assets.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  sx: PropTypes.object,
};

export default Assets;
