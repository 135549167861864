/********************************************************************
 *
 * Leads.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  Link,
  Button,
} from "@mui/material";
import format from "../../utils/format";
import moment from "moment-timezone";
import { WidgetFrame, ExpansionCard } from "../lib";
import Pagination from "../lib/Pagination";
import { useApp, useAPI } from "../../providers/AppProvider";

const PAGE_SIZE = 6;

/**
 * Leads
 *
 * Displays a paged widge of leads
 */
const Leads = ({ pageSize: extPageSize }) => {
  const [items, setItems] = useState(null);
  const [itemCount, setItemCount] = useState(null);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [currPage, setCurrPage] = useState(1);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    if (extPageSize) setPageSize(extPageSize);
  }, [extPageSize]);

  useEffect(() => {
    fetchLeads(currPage, pageSize);
  }, [currPage, pageSize]);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * FetchLeads
   *
   * Calls the api to fetch recent searches
   *
   * @param currPage
   * @param pageSize
   *
   */
  const fetchLeads = (currPage, pageSize) => {
    const notifyHandle = app.notify("Loading Leads");

    //
    //  Clear the loading flag
    //
    setLoaded(false);

    //
    //  Request searches
    //
    api
      .fetch(`/api/leads/?page=${currPage}&limit=${pageSize}`)
      .then(({ payload: items, max }) => {
        setItems(items);
        setItemCount(max);
        setLoaded(true);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  const onTurnPage = ({ currPage }) => {
    setCurrPage(currPage);
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  if (!loaded)
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );

  if (!items) return <Typography>Leads not available</Typography>;

  return (
    <WidgetFrame
      title={`${itemCount} Active Leads`}
      action={
        <Button
          href="/app/sales/leads/lead/"
          variant="outlined"
          color="primary"
        >
          New Lead
        </Button>
      }
    >
      <>
        {items.map((item) => (
          <LeadItem key={item.id} lead={item} />
        ))}
        <br />
        <Pagination
          className={"small"}
          currPage={currPage}
          totalRecords={itemCount}
          pageSize={pageSize}
          pageNeighbours={1}
          onPageChanged={onTurnPage}
        />
      </>
    </WidgetFrame>
  );
};

const LeadItem = ({ lead }) => {
  if (!lead) return;

  return (
    <ExpansionCard
      key={lead.id}
      header={
        <div style={{ width: "100%" }}>
          <Typography variant="h6">
            {lead.user && `${lead.user.fname} ${lead.user.lname}`}
          </Typography>
          <Grid container>
            <Grid item xs={2}>
              <Typography variant="caption">{lead.status.name}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="caption">{lead.source.name}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="caption">{lead.shortDesc}</Typography>
            </Grid>
            <Grid item xs={3} style={{ textAlign: "right" }}>
              <Typography variant="caption">
                {format.shortDateTime(moment(lead.timestamp))}
              </Typography>
            </Grid>
          </Grid>
        </div>
      }
      content={
        <Grid container>
          <Grid item xs={2}>
            <Typography variant="body2" style={{ color: "#999999" }}>
              {lead.type.name}
            </Typography>
          </Grid>
          <Grid
            item
            xs={10}
            style={{
              borderLeft: "solid 2px #999999",
              paddingLeft: "2em",
            }}
          >
            <Typography variant="body2">
              {lead.notes && lead.notes[0].message}
            </Typography>
          </Grid>
        </Grid>
      }
      actions={<Link href={`/app/sales/leads/lead/${lead.id}`}>Edit</Link>}
    />
  );
};

export default Leads;
