/********************************************************************
 *
 * Pagination.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState } from "react";
import { Pagination as Pager } from '@mui/material';
import PropTypes from "prop-types";

const NEIGHBOURS = 2;

/**
 * Pagination
 *
 * Properties:
 *       defaultPage     - Set the default page
 *       totalRecords    - The total number of records
 *       pageSize        - The number of records on a page
 *       pageNeighbours  - The number of buttons adjacent to the current
 *       onPageChanged   - The callback handler
 *
 */

const Pagination = ({
  currPage,
  totalRecords,
  pageSize,
  pageNeighbours,
  onPageChanged,
}) => {
  const [page, setPage] = useState(currPage ? currPage : 1);

  const onChange = (event, value) => {
    setPage(value);
    onPageChanged && onPageChanged({ currPage: value });
  };

  return (
    <Pager
      page={page}
      count={Math.ceil(totalRecords / pageSize)}
      siblingCount={pageNeighbours ? pageNeighbours : NEIGHBOURS}
      onChange={onChange}
      variant="outlined"
      shape="rounded"
    />
  );
};

Pagination.propTypes = {
  totalRecords: PropTypes.number,
  pageSize: PropTypes.number.isRequired,
  currPage: PropTypes.number,
  pageNeighbours: PropTypes.number,
  onPageChanged: PropTypes.func,
};

export default Pagination;
