/********************************************************************
 *
 * PostEditor.jsx
 *
 * Renders a control that resembles Facebook's post control. A user
 * can post, edit and delete a message.
 *
 * TODO: Add Edit functionality
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardContent,
  Box,
  TextField,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { Send, Menu as Hamburger } from "@mui/icons-material";
import format from "../../utils/format";

/**
 * PostEditor
 *
 * Component that displays and edits posts.
 *
 */
const PostEditor = ({
  id,
  value,
  label = "",
  placeholder = "Write something",
  author = "anonymous",
  date,
  disabled = false,
  edit = false,
  onPost,
  onEdit,
  onDelete,
}) => {
  const [message, setMessage] = useState(value || "");
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        "& .postMenu": {
          visibility: "hidden",
        },
        "&:hover .postMenu": {
          visibility: "visible",
        },
      }}
    >
      <Card
        elevation={0}
        sx={{
          borderRadius: 4,
          bgcolor: "#f6f6f6",
          width: "100%",
          py: edit ? 0 : 2,
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
            }}
          >
            <TextField
              fullWidth
              disabled={disabled}
              label={
                !edit && (!!date || !!author)
                  ? `${
                      !!date ? "On " + format.date(date) : ""
                    } ${author} wrote:`
                  : label
              }
              placeholder={!disabled ? placeholder : "Posting Not Available"}
              multiline={edit}
              minRows={2}
              value={message}
              onChange={(event) => {
                setMessage(event.target.value);
              }}
              sx={{
                "& .MuiInputBase-root": {
                  p: 0,
                  m: 0,
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "0 !important",
                },
                flexGrow: 1,
                flexShrink: 1,
              }}
            ></TextField>
            <Box
              sx={{
                alignSelf: "flex-end",
                minWidth: "50px",
                flexShrink: 0,
              }}
            >
              <IconButton
                variant="contained"
                disabled={disabled || !message || message == ""}
                onClick={() => {
                  !!onPost && onPost({ message });
                  setMessage("");
                }}
                sx={{
                  display: edit ? "inline-flex" : "none",
                  color: "#aaaaaa",
                }}
              >
                <Send />
              </IconButton>
            </Box>
          </Box>
        </CardContent>
      </Card>
      <Box
        sx={{
          display: edit || !onDelete ? "none" : "block",
          alignSelf: "center",
          width: "100px",
        }}
      >
        <IconButton
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
          className="postMenu"
        >
          <Hamburger />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={() => {
            setAnchorEl(null);
          }}
          //   MenuListProps={{
          //     "aria-labelledby": "basic-button",
          //   }}
        >
          <MenuItem
            disabled={!onDelete}
            onClick={() => {
              if (window.confirm("Are you sure you want to delete this post?"))
                onDelete(id);

              setAnchorEl(null);
            }}
          >
            Delete
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

PostEditor.propTypes = {
  id: PropTypes.number,
  value: PropTypes.string,
  author: PropTypes.string,
  date: PropTypes.string,
  edit: PropTypes.bool,
  onPost: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

export default PostEditor;
