/********************************************************************
 *
 * Tours.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { useOutletContext } from "react-router";
import { Schedule, DirectionsBoat } from "@mui/icons-material";
import Section from "../../lib/Section";
import ConfigSwitchTextField from "./lib/ConfigSwitchTextField";
import ConfigSwitchSelect from "./lib/ConfigSwitchSelect";
import WidgetFrame from "../../lib/WidgetFrame";

/**
 * Tours
 *
 * Page to set tour configuration settings.
 *
 */
const Tours = () => {
  const { config, onChangeConfig } = useOutletContext();

  if (!config) return null;

  return (
    <WidgetFrame>
      <Section icon={<Schedule />} title="Scheduling">
        <ConfigSwitchTextField
          title="Receives a response to a staff shift invitation"
          switchId="shift_invitation_response_switch"
          switchValue={
            parseInt(config["shift_invitation_response_switch"]) === 1
          }
          valueId="shift_invitation_response"
          value={config["shift_invitation_response"]}
          helperText="Seperate emails with commas"
          onChangeConfig={onChangeConfig}
        />
      </Section>
      <Section icon={<DirectionsBoat />} title="Departures">
        <ConfigSwitchSelect
          title="Send reminder to staff in advance of scheduled tour"
          switchId="tour_departure_staff_advance_notice_switch"
          switchValue={
            parseInt(config["tour_departure_staff_advance_notice_switch"]) === 1
          }
          valueId="tour_departure_staff_advance_notice"
          value={config["tour_departure_staff_advance_notice"]}
          options={[
            { value: 1, label: "1" },
            { value: 2, label: "2" },
            { value: 3, label: "3" },
            { value: 4, label: "4" },
            { value: 5, label: "5" },
          ]}
          metric="days"
          onChangeConfig={onChangeConfig}
        />
        <ConfigSwitchSelect
          title="Send reminder to passengers in advance of scheduled tour"
          switchId="tour_departure_pax_advance_notice_switch"
          switchValue={
            parseInt(config["tour_departure_pax_advance_notice_switch"]) === 1
          }
          valueId="tour_departure_pax_advance_notice"
          value={config["tour_departure_pax_advance_notice"]}
          options={[
            { value: 1, label: "1" },
            { value: 2, label: "2" },
            { value: 3, label: "3" },
            { value: 4, label: "4" },
            { value: 5, label: "5" },
          ]}
          metric="days"
          onChangeConfig={onChangeConfig}
        />
      </Section>
    </WidgetFrame>
  );
};

export default Tours;
