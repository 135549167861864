/********************************************************************
 *
 * Skus.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { WidgetFrame, CardList } from "../../../lib";
import ProductSKU from "./SKU";
import { useApp, useAPI } from "../../../../providers/AppProvider";

/**
 * ProductSKSkusUs
 *
 * Renders skus for a product.
 */
const Skus = ({ product, canBook }) => {
  const [skus, setSkus] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    if (!product) return;

    fetchSkus(product.id);
  }, []);

  ///////////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////////

  const fetchSkus = (productId) => {
    api
      .fetch(`/api/products/product/${productId}/skus`)
      .then(({ payload: skus }) => {
        setSkus(skus);
        setLoaded(true);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <WidgetFrame title="SKUs">
      {loaded && !skus ? (
        "No Product Skus Available"
      ) : (
        <CardList
          items={skus}
          xs={1}
          sm={2}
          md={3}
          lg={2}
          xl={2}
          onRenderCard={({ item: sku }) =>
            !sku.deleted &&
            !sku.rateClass.deleted && (
              <ProductSKU key={sku.id} sku={sku} canBook={canBook} />
            )
          }
        />
      )}
    </WidgetFrame>
  );
};

//
//  PropTypes
//
Skus.propTypes = {
  product: PropTypes.object.isRequired,
  canBook: PropTypes.bool,
};

export default Skus;
