/********************************************************************
 *
 * TipChip.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { Chip, Tooltip } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const TipChip = (props) => {
  const { tip } = props;
  const classes = useStyles();
  return (
    <Tooltip title={tip} arrow>
      <Chip
        className={classes.chip}
        variant="outlined"
        size="small"
        color="primary"
        {...props}
      />
    </Tooltip>
  );
};

/*
 **  Styles
 */
const useStyles = makeStyles(() => ({
  chip: {
    padding: "0 0.5em",
  },
}));

export default TipChip;
