import React, { useState, useEffect } from "react";
import {
  useParams,
  useNavigate,
  useSearchParams,
  redirect,
} from "react-router";
import { AccountBalance, CalendarMonth } from "@mui/icons-material";
import { RateClassSummary } from "../RateClass/Identity/RateClassSummaryHeader";
import Rates from "./RateClassRates";
import { Main } from "../../lib";
import format from "../../../utils/format";
import { useApp, useAPI } from "../../../providers/AppProvider";

/**
 * RateClassRateEdit
 *
 * Adds or edits a rate class.
 *
 * @param {*} param0
 */
const RateClassRateEdit = () => {
  const [rateClass, setRateClass] = useState(null);
  const [productType, setProductType] = useState(null);
  const [accounts, setAccounts] = useState(null);
  const [rate, setRate] = useState(null);
  const [dirty, setDirty] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const app = useApp();
  const api = useAPI();
  const { id } = useParams();

  useEffect(() => {
    //
    //  If product id is a paramter, we are in edit mode, otherwise
    //  we are creating a new product
    //
    const rc = searchParams.get("rc");
    if (!rc) throw new Error("Rate class parameter not set in URL");
    fetchRateClass(rc);

    const pt = searchParams.get("pt");
    if (!pt) throw new Error("Product type parameter not set in URL");
    fetchProductType(pt);

    fetchAccounts();

    if (!id) {
      //
      //  New rate class mode
      //
      return;
    }

    //
    //  Edit mode
    //
    fetchRate(id);
  }, [id, searchParams]);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * FetchRate
   *
   * Initializes the rate
   *
   * @param {*} id
   */
  const fetchRate = (id) => {
    api
      .fetch(`/api/rates/rate/${id}`)
      .then(({ payload: rate }) => {
        setRate(rate);
        setLoaded(true);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  /**
   * FetchRateClass
   *
   * Initializes the rate class
   *
   * @param {*} id
   */
  const fetchRateClass = (id) => {
    api
      .fetch(`/api/rateclasses/rateclass/${id}`)
      .then(({ payload: rateClass }) => {
        setRateClass(rateClass);
        setLoaded(true);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  /**
   * FetchProductType
   *
   * Initializes the product type
   *
   * @param {*} id
   */
  const fetchProductType = (id) => {
    api
      .fetch(`/api/producttypes/producttype/${id}`)
      .then(({ payload: productType }) => {
        setProductType(productType);
        setLoaded(true);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  /**
   * FetchAccounts
   *
   * Retrieves the accounts
   */
  const fetchAccounts = () => {
    api
      .fetch(`/api/accounts`)
      .then(({ payload: accounts }) => {
        setAccounts(accounts);
        setLoaded(true);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////////

  /**
   * OnUpdate
   *
   * @param {*} key
   * @param {*} value
   */
  const onUpdate = (key, value) => {
    let path = key.split(".");

    let _rate = rate;

    if (!_rate) _rate = {};

    let i = 0;
    let obj = _rate;
    for (i = 0; i < path.length - 1; i++) obj = obj[path[i]];

    obj[path[i]] = value;

    setDirty(true);
    setRate({ ..._rate });
  };

  /**
   * OnDelete
   *
   * Fired when the user deletes the rate.
   *
   * @param {*} event
   */
  const onDelete = (event) => {
    if (!window.confirm("Are you sure you want to delete this rate?")) return;

    api
      .delete(`/api/rates/rate/${rate.id}`)
      .then(() => {
        return redirect(`/app/catalog/rateClass/${rate.rateClassId}`);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  /**
   * OnSave
   *
   * Fired when the user commits the change.
   *
   * @param {*} event
   */
  const onSave = (event) => {
    event.preventDefault();

    api
      .update(`/api/rates/rate/${rate.id ? rate.id : ""}`, rate)
      .then(() => {
        return redirect(`/app/catalog/rateClass/${rate.rateClassId}`);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  const onCancel = (event) => {
    event.preventDefault();
    navigate(-1);
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Main
      className="col-md-9 ml-sm-auto col-lg-10 px-4 pb-4"
      loaded={!id || rate}
    >
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <div className="page-title">
          <AccountBalance />
          Rate Editor
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <button className={`btn btn-outline-primary mb-2`} onClick={onDelete}>
            Delete
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col col-lg-8">
          <RateClassSummary rateClass={rateClass} readonly="true" />
          <EditRate
            rate={rate}
            accounts={accounts}
            dirty={dirty}
            onUpdate={onUpdate}
            onSave={onSave}
            onCancel={onCancel}
          />
        </div>
        <div className="d-none d-lg-block col-lg-4">
          <Rates
            rateClass={rateClass}
            productType={productType}
            highlightRate={rate}
            readonly="true"
          />
        </div>
      </div>
    </Main>
  );
};

/**
 * Edit Rate
 *
 * @param {*} param0
 */
const EditRate = ({ rate, accounts, dirty, onUpdate, onSave, onCancel }) => {
  return (
    <form className="needs-validation" onSubmit={onSave}>
      <div className="form-group row">
        <div className="col col-md-6">
          <label htmlFor="amount">Amount</label>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                $
              </span>
            </div>
            <input
              id="amount"
              name="amount"
              className="form-control"
              type="number"
              min="0"
              step="0.01"
              pattern="^\d*(\.\d{0,2})?$"
              defaultValue={rate && rate.amount.toFixed(2)}
              onBlur={(e) => onUpdate(e.target.id, parseFloat(e.target.value))}
              required
            />
          </div>
          <small className="form-text text-muted">The rate amount.</small>
          <div className="invalid-feedback">Please enter a rate amount.</div>
        </div>
      </div>
      <div className="form-group row">
        <div className="col">
          <label htmlFor="start">Start</label>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                <CalendarMonth />
              </span>
            </div>
            <input
              id="start"
              name="start"
              className="form-control"
              type="date"
              defaultValue={rate && format.toApiDate(rate.start)}
              onChange={(e) => onUpdate(e.target.id, e.target.value)}
              required
            />
          </div>
          <small className="form-text text-muted">
            The date of the first product for this rate.
          </small>
          <div className="invalid-feedback">Please enter a start date.</div>
        </div>
        <div className="col">
          <label htmlFor="end">End</label>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                <CalendarMonth />
              </span>
            </div>
            <input
              id="end"
              name="end"
              className="form-control"
              type="date"
              defaultValue={rate && format.toApiDate(rate.end)}
              onChange={(e) => onUpdate(e.target.id, e.target.value)}
              required
            />
          </div>
          <small className="form-text text-muted">
            The date of the last product for this rate.
          </small>
          <div className="invalid-feedback">Please enter an end date.</div>
        </div>
      </div>
      <div className="form-group row">
        <div className="col">
          <label htmlFor="open">Open</label>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                <CalendarMonth />
              </span>
            </div>
            <input
              id="open"
              name="open"
              className="form-control"
              type="date"
              defaultValue={rate && format.toApiDate(rate.open)}
              onChange={(e) => onUpdate(e.target.id, e.target.value)}
              required
            />
          </div>
          <small className="form-text text-muted">
            The first date the products at this rate can be booked.
          </small>
          <div className="invalid-feedback">Please enter an open date.</div>
        </div>
        <div className="col">
          <label htmlFor="close">Close</label>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                <CalendarMonth />
              </span>
            </div>
            <input
              id="close"
              name="close"
              className="form-control"
              type="date"
              defaultValue={rate && format.toApiDate(rate.close)}
              onChange={(e) => onUpdate(e.target.id, e.target.value)}
              required
            />
          </div>
          <small className="form-text text-muted">
            The date of the last product for this rate.
          </small>
          <div className="invalid-feedback">Please enter an end date.</div>
        </div>
      </div>
      <div className="form-group row">
        <div className="col col-md-6">
          <label htmlFor="account.id">Account</label>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                $
              </span>
            </div>
            <select
              id="account.id"
              name="account.id"
              className="form-control"
              onChange={(e) => onUpdate(e.target.id, e.target.value)}
              defaultValue={rate && rate.account ? rate.account.id : "-1"}
              required
            >
              <option value="-1" disabled>
                -- Select --
              </option>
              {accounts.map((account, index) => (
                <option key={index} value={account.id}>
                  {account.name}
                </option>
              ))}
            </select>
          </div>
          <small className="form-text text-muted">The rate amount.</small>
          <div className="invalid-feedback">Please enter a rate amount.</div>
        </div>
      </div>
      <button type="submit" className="btn btn-primary" disabled={!dirty}>
        Save
      </button>
      <button className="btn btn-secondary mx-3" onClick={onCancel}>
        Cancel
      </button>
    </form>
  );
};

export default RateClassRateEdit;
