/********************************************************************
 *
 * /components/goods/goods/tour/tickets.jsx
 *
 * Renders the tickets assigned to the tour.
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import Tickets from "../../lib/Tickets";
import { useApp, useAPI } from "../../../providers/AppProvider";

const TicketsContainer = ({ tour }) => {
  const [tickets, setTickets] = useState(null);
  const app = useApp();
  const api = useAPI();

  /**
   *  Orders State Management
   */
  useEffect(() => {
    if (tour) fetchTickets(tour.id);
  }, [tour]);

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * FetchTickets
   *
   * @param {*} tourId
   */
  const fetchTickets = (tourId) => {
    api
      .fetch(`/api/tours/tour/${tourId}/tickets`)
      .then(({ payload: tickets }) => {
        setTickets(tickets);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  return (
    <Box>
      <Typography variant="h3" paragraph>
        {tickets && tickets.length ? tickets.length : "No"} Tickets
      </Typography>
      <Tickets tickets={tickets} tz={tour && tour.tz} />
    </Box>
  );
};

export default TicketsContainer;
