/********************************************************************
 *
 * SessionEvents/ProductSearchEvents.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import {
  useApp,
  useAPI,
  useAnalyticsAPI,
} from "../../../../providers/AppProvider";

/**
 * ProductSearchEvents
 *
 * Renders product search events for a session
 *
 */
const ProductSearchEvents = ({ session }) => {
  const [productSearchEvents, setproductSearchEvents] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAnalyticsAPI();

  useEffect(() => {
    if (!session) return;
    fetchProductSearchEvents(session);
  }, [session]);

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * FetchProductSearchEvents
   *
   * @param {*} session
   */
  const fetchProductSearchEvents = (session) => {
    if (!session) throw new Error("Parameter 'session' cannot be null");

    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading Product Search Events");

    api
      .fetch(`/api/sessions/session/${session.id}/events/productsearches/`)
      .then(({ payload: events }) => {
        setproductSearchEvents(events);
        setLoaded(true);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setproductSearchEvents(null);
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  if (!loaded)
    return <div className="spinner-border text-secondary" role="status" />;

  if (!productSearchEvents)
    return (
      <Box style={{ padding: "12px" }}>
        <Typography>No Events</Typography>
      </Box>
    );

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Client</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Product</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {productSearchEvents.map((event) => (
            <TableRow key={event.id}>
              <TableCell>{event.id}</TableCell>
              <TableCell>
                {moment(event.timestamp).format("MMM Do YYYY, h:mm:ss a")}
              </TableCell>
              <TableCell>{event.clientId}</TableCell>
              <TableCell>
                {moment(event.date).format("MMM Do YYYY, h:mm:ss a")}
              </TableCell>
              <TableCell>{event.productTypeId}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProductSearchEvents;
