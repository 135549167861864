/********************************************************************
 *
 * Ticket.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { LocalActivity } from "@mui/icons-material";
import ProductSummary from "../../Catalog/Product/Identity";
import { Main } from "../../lib";
import { useApp, useAPI } from "../../../providers/AppProvider";

/**
 * Ticket
 *
 * Displays information for a Ticket
 */
const Ticket = () => {
  const [ticket, setTicket] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  const { id } = useParams();

  useEffect(() => {
    fetchTicket(id);
  }, []);

  ///////////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////////

  const fetchTicket = (ticketId) => {
    api
      .fetch(`/api/pnrs/pnr/${ticketId}`)
      .then(({ payload: ticket }) => {
        setTicket(ticket);
        setLoaded(true);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Main className="container" loaded={loaded}>
      {!ticket && <h4>Ticket not available</h4>}

      {ticket && (
        <>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <div className="page-title">
              <LocalActivity />
              Ticket {ticket ? ticket.id : ""}
            </div>
            <div className="btn-toolbar mb-2 mb-md-0" />
          </div>
          <div className="row my-lg-4">
            <div className="col-12 col-lg-8">
              <ProductSummary product={ticket.product} />
              <hr />
            </div>
            <div className="col-12 col-lg-4" />
          </div>
        </>
      )}
    </Main>
  );
};

export default Ticket;
