/********************************************************************
 *
 * /src/components/catalog/products/rates/RateEdit.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { Box, Button, TextField, InputAdornment } from "@mui/material";
import moment from "moment-timezone";
import { Formik } from "formik";
import * as yup from "yup";
import { ModalDialog, DialogContent, HorizontalGroup } from "../../../lib";
import format from "../../../../utils/format";
import { useApp, useAPI } from "../../../../providers/AppProvider";

/**
 * RateEdit
 *
 * Widget for quickly viewing/editting/creating a Rate
 *
 * A rate is applied to a rateclass and a product type for certain
 * time periods.
 *
 */
const RateEdit = ({
  productType,
  rateClass,
  rate,
  show,
  onSaved,
  onCancelled,
}) => {
  const app = useApp();
  const api = useAPI();

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnSave
   *
   * Fired when the user saves the changes
   *
   * @param {*} event
   */
  const onSave = (rate) => {
    //
    //  Save
    //
    const notifyHandle = app.notify("Saving");

    api
      .update(
        `/api/producttypes/producttype/${
          productType.id
        }/rateclasses/rateclass/${rateClass.id}/rates/rate${
          rate && rate.id ? `/${rate.id}` : ""
        }`,
        rate
      )
      .then(() => {
        onSaved && onSaved({ rate: { ...rate } });
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  /**
   * OnCancel
   *
   * Fired when the user aborts changes.
   *
   */
  const onCancel = (dirty) => {
    if (
      !dirty ||
      window.confirm(
        "You have unsaved changes. Are you sure you want to close this dialog and loose your changes?"
      )
    ) {
      onCancelled && onCancelled();
    }
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  return (
    <ModalDialog
      title="Manage Rate"
      subtitle="A rate is valid for the rate class of a specific product type for products of certain dates booked in a certain window."
      show={show}
    >
      <DialogContent>
        <FormBody
          rate={rate}
          productType={productType}
          onSave={onSave}
          onCancel={onCancel}
        />
      </DialogContent>
    </ModalDialog>
  );
};

/**
 * FormBody
 *
 * @param {*} props
 */
const FormBody = ({ rate, productType, onCancel, onSave }) => (
  <Formik
    initialValues={
      rate
        ? { ...rate, amount: parseFloat(rate.amount / 100).toFixed(2) }
        : {
            amount: 0,
            start: "",
            end: "",
            open: "",
            close: "",
          }
    }
    enableReinitialize={false}
    onSubmit={(rate) => {
      rate.start = format.toApiDateTime(
        moment.tz(rate.start, productType.timezone).startOf("day")
      );
      rate.end = format.toApiDateTime(
        moment.tz(rate.end, productType.timezone).endOf("day")
      );
      rate.open = format.toApiDateTime(
        moment.tz(rate.open, productType.timezone).startOf("day")
      );
      rate.close = format.toApiDateTime(
        moment.tz(rate.close, productType.timezone).endOf("day")
      );
      rate.amount = rate.amount * 100;

      onSave(rate);
    }}
    validationSchema={yup.object().shape({
      amount: yup
        .number()
        .min(0, "Amount must be greater than zero")
        .required("Amount is required"),
      start: yup
        .date()
        .max(yup.ref("end"), "Start date must occur before end date")
        .required("Start date is required"),
      end: yup
        .date()
        .min(yup.ref("start"), "End date must occur after start date")
        .required("End date is required"),
      open: yup
        .date()
        .max(yup.ref("close"), "Open date must occur before close date")
        .required("Open date is required"),
      close: yup
        .date()
        .min(yup.ref("open"), "Close date must occur after open date")
        .required("Close date is required"),
    })}
  >
    {({
      values,
      touched,
      errors,
      dirty,
      isSubmitting,
      setFieldValue,
      handleChange,
      handleBlur,
      handleSubmit,
    }) => {
      return (
        <form onSubmit={handleSubmit}>
          <Box display="flex" justifyContent="flex-end">
            <HorizontalGroup gap={1}>
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                disabled={!dirty || isSubmitting}
              >
                Save
              </Button>
              <Button onClick={() => onCancel(dirty)}>Cancel</Button>
            </HorizontalGroup>
          </Box>
          <RateForm
            rate={values}
            touched={touched}
            errors={errors}
            setFieldValue={setFieldValue}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </form>
      );
    }}
  </Formik>
);

/**
 * RateForm
 *
 * Functional component for the rate input fields.
 *
 * @param {*} props
 */
const RateForm = ({
  rate,
  touched,
  errors,
  setFieldValue,
  onBlur,
  onChange,
}) => {
  const { amount, start, end, open, close } = rate || {};

  return (
    <Box>
      <TextField
        id="amount"
        type="number"
        min="0"
        label="Amount"
        placeholder="100.00"
        value={rate.amount}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        onBlur={onBlur}
        onChange={onChange}
        fullWidth
        required
        error={errors.amount && touched.amount}
        helperText={errors.amount || " "}
      />
      <TextField
        id="start"
        type="date"
        label="Rate Starts"
        value={format.toApiDate(rate.start)}
        onBlur={onBlur}
        onChange={onChange}
        fullWidth
        required
        error={errors.start && touched.start}
        helperText={errors.start || " "}
      />
      <TextField
        id="end"
        type="date"
        label="Rate Ends"
        value={format.toApiDate(rate.end)}
        onBlur={onBlur}
        onChange={onChange}
        fullWidth
        required
        error={errors.end && touched.end}
        helperText={errors.end || " "}
      />
      <TextField
        id="open"
        type="date"
        label="Rate Opens"
        value={format.toApiDate(rate.open)}
        onBlur={onBlur}
        onChange={onChange}
        fullWidth
        required
        error={errors.open && touched.open}
        helperText={errors.open || " "}
      />
      <TextField
        id="close"
        type="date"
        label="Rate Closes"
        value={format.toApiDate(rate.close)}
        onBlur={onBlur}
        onChange={onChange}
        fullWidth
        required
        error={errors.close && touched.close}
        helperText={errors.close || " "}
      />
    </Box>
  );
};

export default RateEdit;
