/********************************************************************
 *
 * Cards.jsx
 *
 * Former file that contained all Card components. Most have been
 * transitioned to their own files. Time to get rid of the original
 * LinkCard.
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import {
  Card,
  CardHeader,
  CardContent,
  CardActionArea,
  CardActions,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const LinkCard = ({ header, subheader, link, onClick, content, actions }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      {link ? (
        <Link className={classes.contentLink} to={link} onClick={onClick}>
          {header && <CardHeader title={header} subheader={subheader} />}
          <CardContent className={classes.content}>{content}</CardContent>
        </Link>
      ) : (
        <>
          {header && <CardHeader title={header} subheader={subheader} />}
          <CardContent className={classes.content}>{content}</CardContent>
        </>
      )}
      {actions && (
        <CardActionArea>
          <CardActions className={classes.actions}>{actions}</CardActions>
        </CardActionArea>
      )}
    </Card>
  );
};

/*
 **  Styles
 */
const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1,
    padding: theme.spacing(1.5),
    border: "1px solid rgba(122,123,151,.3)",
    height: "100%",
  },
  contentLink: {
    display: "block",
    "&:hover": {
      backgroundColor: theme.palette.background.default,
      color: "#666666",
      textDecoration: "none",
    },
  },
  content: { flex: "1 1 auto" },
  link: { padding: "0.25em 0.5em" },
  highlight: {
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "8px",
    borderColor: theme.palette.primary.light,
  },
  actions: {
    fontSize: "0.85em",
    borderTop: "solid 1px #eeeeee",
    padding: "0.5em 0.5em 0em 0.5em",
  },
}));

/*
 **  PropTypes
 */
LinkCard.propTypes = {
  header: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  subheader: PropTypes.string,
  link: PropTypes.string,
  content: PropTypes.object,
  actions: PropTypes.object,
};

export { LinkCard };
