import { Navigate } from "react-router";
import Admin from "../components/Admin";
import Employees from "../components/Admin/Employees";
import Employee from "../components/Admin/Employee";
import Assets from "../components/Admin/Assets";
import Asset from "../components/Admin/Assets/Asset";
import Configuration from "../components/Admin/Configuration";
import Orders from "../components/Admin/Configuration/Sales";
import Tours from "../components/Admin/Configuration/Tours";
import Sandbox from "../components/Admin/Sandbox/Sandbox";
import Tools from "../components/Admin/Tools/Tools";
import Tickets from "../components/Admin/Maintenance/Tickets";
import Ticket from "../components/Admin/Maintenance/Ticket";
import WorkOrders from "../components/Admin/Maintenance/WorkOrders";
import WorkOrder from "../components/Admin/Maintenance/WorkOrder";
import Redirect from "./Redirect";

export default {
  path: "/app/admin/",
  element: <Admin />,
  children: [
    {
      path: "/app/admin/",
      element: <Navigate to={`/app/admin/employees/`} />,
    },
    {
      path: "/app/admin/employees/",
      element: <Employees />,
    },
    {
      path: "/app/admin/employee/:id",
      element: <Employee />,
    },
    {
      path: "/app/admin/assets/",
      element: <Assets />,
    },
    {
      path: "/app/admin/asset/:id",
      element: <Asset />,
    },
    {
      path: "/app/admin/config/",
      element: <Configuration />,
      children: [
        {
          path: "/app/admin/config/",
          element: <Navigate to={`/app/admin/config/orders/`} />,
        },
        {
          path: "/app/admin/config/orders",
          element: <Orders />,
        },
        {
          path: "/app/admin/config/tours",
          element: <Tours />,
        },
      ],
    },
    {
      path: "/app/admin/tickets/",
      element: <Tickets />,
    },
    {
      path: "/app/admin/ticket/:id",
      element: <Ticket />,
    },
    {
      path: "/app/admin/workorders/",
      element: <WorkOrders />,
    },
    {
      path: "/app/admin/workorder/:id",
      element: <WorkOrder />,
    },
    {
      path: "/app/admin/tools/",
      element: <Tools />,
    },
    {
      path: "/app/admin/sandbox/",
      element: <Sandbox />,
    },
  ],
};
