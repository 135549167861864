/********************************************************************
 *
 * Table.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { Paper, Typography, TableContainer as MuiTableContainer, Table as MuiTable } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

export const Table = ({ title, size, elevation = 0, children }) => {
  const classes = styles();

  return (
    <MuiTableContainer component={Paper} elevation={elevation}>
      {!!title && (
        <Typography className={classes.title} variant="h3">
          {title}
        </Typography>
      )}
      <MuiTable size={size} className={classes.table} aria-label="simple table">
        {children}
      </MuiTable>
    </MuiTableContainer>
  );
};

/*
 **  Styles
 */
const styles = makeStyles((theme) => ({
  table: {},
  title: { padding: theme.spacing(2) },
}));

export { TableHead, TableBody, TableRow, TableCell } from "@mui/material";
