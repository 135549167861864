/********************************************************************
 *
 * Sales.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { useOutletContext } from "react-router";
import { Box } from "@mui/material";
import { LiveHelp, Receipt } from "@mui/icons-material";
import Section from "../../lib/Section";
import ConfigSwitchTextField from "./lib/ConfigSwitchTextField";
import WidgetFrame from "../../lib/WidgetFrame";

/**
 * Sales
 *
 * Page to set order configuration settings.
 *
 */
const Sales = () => {
  const { config, onChangeConfig } = useOutletContext();

  if (!config) return null;

  return (
    <WidgetFrame>
      <Box>
        <Section icon={<LiveHelp />} title="Leads">
          <ConfigSwitchTextField
            title="Send new lead notifications"
            switchId="lead_create_notification_switch"
            switchValue={
              parseInt(config["lead_create_notification_switch"]) === 1
            }
            valueId="lead_create_notification_email"
            value={config["lead_create_notification_email"]}
            helperText="Seperate emails with commas"
            onChangeConfig={onChangeConfig}
          />
        </Section>
        <Section icon={<Receipt />} title="Orders">
          <ConfigSwitchTextField
            title="Send merchant new order notifications"
            switchId="order_book_notification_merchant_switch"
            switchValue={
              parseInt(config["order_book_notification_merchant_switch"]) === 1
            }
            valueId="order_book_notification_merchant_email"
            value={config["order_book_notification_merchant_email"]}
            helperText="Seperate emails with commas"
            onChangeConfig={onChangeConfig}
          />
        </Section>
        <Section icon={<Receipt />} title="Cancellations">
          <ConfigSwitchTextField
            title="Send merchant cancellation notifications"
            switchId="cancel_charge_notification_merchant_switch"
            switchValue={
              parseInt(config["cancel_charge_notification_merchant_switch"]) ===
              1
            }
            valueId="cancel_charge_notification_merchant_email"
            value={config["cancel_charge_notification_merchant_email"]}
            helperText="Seperate emails with commas"
            onChangeConfig={onChangeConfig}
          />
        </Section>
      </Box>
    </WidgetFrame>
  );
};

export default Sales;
