/********************************************************************
 *
 * Customers.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Box, Button } from "@mui/material";
import { PeopleAlt } from "@mui/icons-material";
import { Main } from "../../lib";
import Pagination from "../../lib/Pagination";
import LiveSearch from "../../lib/LiveSearch";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "../../lib/Table";

import { useApp, useAPI } from "../../../providers/AppProvider";

const PAGE_SIZE = 15;

/**
 * Customers
 *
 * Renders a list of customers
 */
const Customers = () => {
  const [customers, setCustomers] = useState(null);
  const [customerCount, setCustomerCount] = useState(null);
  const [currPage, setCurrPage] = useState(1);
  const [pageSize] = useState(PAGE_SIZE);
  const [match, setMatch] = useState("");
  const [loaded, setLoaded] = useState(false);
  let navigate = useNavigate();
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    fetchCustomers(currPage, pageSize, match);
  }, [currPage, pageSize, match]);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * FetchCustomers
   *
   * @param {*} currPage
   * @param {*} pageSize
   * @param {*} match
   */
  const fetchCustomers = (currPage, pageSize, match = "") => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading Customers");

    api
      .fetch(
        `/api/customers/?match=${match}&page=${currPage}&limit=${pageSize}`
      )
      .then(({ payload: customers, max: count }) => {
        setCustomers(customers);
        setCustomerCount(count);
        setLoaded(true);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setCustomers(null);
        setCustomerCount(null);
        setLoaded(true);
      });
  };

  const PaginatedTable = (props) => {
    let { customers } = props;

    if (!customers) return <div className="h4">No Customers Available</div>;

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {customers.map((customer) => (
            <TableRow
              key={customer.id}
              hover
              onClick={() => {
                navigate(`/app/crm/customer/${customer.id}`);
              }}
            >
              <TableCell>
                {customer.lname}, {customer.fname}
              </TableCell>
              <TableCell>{customer.email}</TableCell>
              <TableCell>{customer.phone}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////////

  const onSearch = ({ match }) => {
    setCurrPage(1);
    setMatch(match);
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Main
      header={{
        icon: <PeopleAlt />,
        title: `Customers for ${process.env.REACT_APP_ORG_NAME}`,
        bonus: (
          <Button variant="outlined" color="primary" href="/app/crm/customer">
            Create a new customer
          </Button>
        ),
      }}
      loaded={loaded}
    >
      <>
        <Box display="flex" alignItems="baseLine" flexWrap="wrap" mb={2}>
          <Box flexGrow={1}>
            <LiveSearch match={match} onSearch={onSearch} />
          </Box>
          <Box>
            <Pagination
              currPage={currPage}
              totalRecords={customerCount}
              pageSize={pageSize}
              pageNeighbours={1}
              onPageChanged={(e) => {
                setCurrPage(e.currPage);
              }}
            />
          </Box>
        </Box>
        <PaginatedTable customers={customers} />
      </>
    </Main>
  );
};

export default Customers;
