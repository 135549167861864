/********************************************************************
 *
 * RateClassSummary.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { Avatar, Box, Chip, Typography } from "@mui/material";
import { AttachMoney } from "@mui/icons-material";
import { CardContentHeader } from "../../../lib";

/**
 * RateClassSummaryHeader
 *
 * Renders a summary of the rate class
 *
 * @param {*} param0
 */
const RateClassSummaryHeader = ({ rateClass }) => {
  if (!rateClass) return "";

  return (
    <CardContentHeader
      avatar={
        <Avatar>
          <AttachMoney />
        </Avatar>
      }
      content={
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 2,
            width: "100%",
          }}
        >
          <Box sx={{ flex: "0 1 40%" }}>
            <Typography variant="h4">{rateClass.name}</Typography>
          </Box>
          <Box>
            {rateClass.deleted && (
              <Box sx={{ pb: 1, justifySelf: "flex-end" }}>
                <Chip
                  color="primary"
                  size="small"
                  label="Deleted"
                  sx={{ mr: 1 }}
                />
              </Box>
            )}

            <Chip
              color="primary"
              size="small"
              variant="outlined"
              label={
                rateClass.term &&
                rateClass.term.id === 0 &&
                rateClass.commission.rate === 0
                  ? "No Fee"
                  : rateClass.term.name
              }
              sx={{ mr: 1 }}
            />
            <Chip
              color="primary"
              size="small"
              variant="outlined"
              label={
                rateClass.commission &&
                rateClass.commission.rate !== 0 &&
                rateClass.commission.display
              }
              sx={{ mr: 1 }}
            />
          </Box>
        </Box>
      }
    />
  );
};

/**
 * RateClassSummary
 *
 * Renders a summary of the rate class
 *
 * @param {*} param0
 */
const RateClassSummary = ({ rateClass }) => {
  if (!rateClass) return "";

  return (
    <Typography sx={{ fontSize: "0.80em" }}>{rateClass.description}</Typography>
  );
};

export { RateClassSummaryHeader, RateClassSummary };
