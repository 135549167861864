/********************************************************************
 *
 * Accounts.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Receipt } from "@mui/icons-material";
import { Button } from "@mui/material";
import { DirectionsBoat } from "@mui/icons-material";
import moment from "moment-timezone";
import { Main } from "../../lib";
import format from "../../../utils/format";
import { useApp, useAPI } from "../../../providers/AppProvider";

/**
 * COA
 *
 * Renders the company's chart of accounts
 *
 */
const COA = () => {
  const [accounts, setAccounts] = useState(null);
  const [start, setStart] = useState(moment().endOf("day"));
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading");

    fetchAccounts(start)
      .then((accounts) => {
        setAccounts(accounts);
        setLoaded(true);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setAccounts(null);
        setLoaded(true);
      });
  }, []);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * SortAccounts
   *
   * @param {*} accounts
   * @param {*} parentId
   */
  const sortAccounts = (accounts, parentId = null) => {
    let root = [];
    let children = [];

    accounts.forEach((account) => {
      if (account.parentId === parentId) root.push(account);
      else children.push(account);
    });

    root.forEach((account) => {
      account.children = sortAccounts(children, account.id);
      account.children.forEach(
        (child) =>
          (account.balance =
            parseFloat(account.balance) + parseFloat(child.balance))
      );
    });

    return root;
  };

  /**
   * FetchAccounts
   *
   * Calls the api to fetch the partners assigned to the product type
   *
   */
  const fetchAccounts = (date) => {
    return new Promise((resolve, reject) => {
      api
        .fetch(`/api/accounts/${format.toApiDateTime(date)}`)
        .then((envelope) => {
          resolve(sortAccounts(envelope.payload));
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  return "ACCOUNTS";
  return (
    <Main
      header={{
        icon: <DirectionsBoat />,
        title: "Accounts",
        bonus: (
          <Button
            variant="outlined"
            color="primary"
            href="/app/admin/finance/account"
          >
            Create a new account
          </Button>
        ),
      }}
      loaded={loaded}
    >
      {!accounts && "No accounts available"}
      {accounts && (
        <>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <div className="page-title">
              <Receipt />
              Chart of Accounts
              <div className="small">Balance as of {format.date(start)}</div>
            </div>
            <div className="btn-toolbar mb-2 mb-md-0">
              <div className="btn-group mr-2" />
            </div>
          </div>
          {/* <ChartOfAccounts accounts={accounts} /> */}
        </>
      )}
    </Main>
  );
};

/**
 * ChartOfAccounts
 *
 * @param {*} props
 */
const ChartOfAccounts = ({ accounts }) => {
  return (
    <table className="table table-striped table-hover table-sm small">
      <colgroup>
        <col />
        <col style={{ width: "150px" }} />
        <col style={{ width: "150px" }} />
      </colgroup>
      <tbody>
        <Accounts accounts={accounts} />
      </tbody>
    </table>
  );
};

/**
 * Accounts
 *
 * @param {*} props
 */
const Accounts = ({ accounts, margin = 0 }) => {
  return (
    <>
      {accounts.map((account, index) => (
        <React.Fragment key={index}>
          {parseInt(account.balance) !== 0 && (
            <>
              <tr
                key={account.id}
                className="pointer"
                onClick={(e) => {
                  window.location.href = `/app/reports/finance/account/${account.id}`;
                }}
              >
                <td style={{ paddingLeft: margin, width: "60%" }}>
                  {account.name}
                </td>
                <td>{account.type.name}</td>
                <td style={{ textAlign: "right", paddingRight: margin * 3 }}>
                  {format.currency(account.balance)}
                </td>
              </tr>

              {account.children && (
                <Accounts accounts={account.children} margin={margin + 20} />
              )}
            </>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default COA;
