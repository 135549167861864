/********************************************************************
 *
 * Index.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { Collections } from "@mui/icons-material";
import { Main } from "../../lib";
import ModalDialog from "../../lib/ModalDialog";
import { useApp, useAPI } from "../../../providers/AppProvider";
import IdentityEdit from "../ProductType/Identity/Edit";
import Collection from "./Collection";

/**
 * ProductTypes
 *
 * Renders a list of Product Types
 *
 */
const Index = () => {
  const [productTypes, setProductTypes] = useState(null);
  const [showAddProductType, setShowAddProductType] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    fetchProductTypes();
  }, []);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * FetchProductTypes
   *
   * Calls the api to fetch product types.
   *
   */
  const fetchProductTypes = () => {
    //
    //  Request product types
    //
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading");

    api
      .fetch(`/api/producttypes/`)
      .then(({ payload: productTypes }) => {
        setProductTypes(productTypes);
        setLoaded(true);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnSaved
   *
   * Fired once the IdentiyEdit dialog has created and saved a new
   * product type.
   *
   * Reload the product types and hide the dialog.
   *
   * @param {*} event
   */
  const onSaved = () => {
    fetchProductTypes();
    setShowAddProductType(false);
  };

  /**
   * OnDelete
   *
   * Fired when a user clicks the delete button on a product type.
   *
   * Deletes the requested product type and reloads the updated set
   * of property types.
   *
   * @param {*} event
   */
  const onDelete = (productType) => {
    if (
      !productType ||
      !window.confirm("Are you sure you want to delete this product type?")
    )
      return;

    //
    //  Status message
    //
    const notifyHandle = app.notify(`Deleting ${productType.name}`);

    api
      .delete(`/api/producttypes/producttype/${productType.id}`)
      .then(() => {
        app.endNotify(notifyHandle);
        fetchProductTypes();
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Main
      header={{
        icon: <Collections />,
        title: `Products for ${process.env.REACT_APP_ORG_NAME}`,
        bonus: (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setShowAddProductType(true)}
          >
            Create a new product
          </Button>
        ),
      }}
      loaded={loaded}
    >
      {loaded && !productTypes ? (
        "No Product Types Available"
      ) : (
        <>
          <IdentityEdit
            open={!!showAddProductType}
            onSaved={onSaved}
            onCancelled={() => setShowAddProductType(false)}
          />
          <Collection productTypes={productTypes} onDelete={onDelete} />
        </>
      )}
    </Main>
  );
};

export default Index;
