/********************************************************************
 *
 * WeeklyCalendar.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import makeStyles from '@mui/styles/makeStyles';
import moment from "moment-timezone";

import WeeklyCalendarHeader from "./WeeklyCalendarHeader";
import WeeklyCalendarContent from "./WeeklyCalendarContent";

/**
 * WeeklyCalendar
 *
 * Renders a seven day view, from Sun-Sat, containing the specified
 * date.
 *
 * @param {*} props
 */
const WeeklyCalendar = ({ date }) => {
  const [startDate, setStartDate] = useState(moment());
  const [tz] = useState(moment.tz.guess());
  const classes = styles();

  useEffect(() => {
    let _date = moment.tz(date, tz);
    if (!_date.isValid()) _date = moment.tz(tz);
    setStartDate(_date.startOf("week"));
  }, [date, tz]);

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <div className={classes.calendar}>
      <div className={classes.container}>
        <WeeklyCalendarHeader start={startDate} />
        <WeeklyCalendarContent />
      </div>
    </div>
  );
};

/*
 **  Styles
 */
const styles = makeStyles((theme) => ({
  calendar: {
    position: "relative",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    color: theme.palette.text.secondary,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "hidden",
  },
}));

/*
 **  PropTypes
 */
WeeklyCalendar.propTypes = {
  date: PropTypes.instanceOf(moment),
};

export default WeeklyCalendar;
