import { Navigate } from "react-router";
import Reports from "../components/Reports";
import Finance from "../components/Reports/Finance";
import Tours from "../components/Reports/Goods/Tours";
import Accounts from "../components/Reports/Finance/Accounts";
import SalesByProduct from "../components/Reports/Finance/SalesByProduct";

export default {
  path: "/app/reports/",
  element: <Reports />,
  children: [
    {
      path: "/app/reports/finance/",
      element: <Finance />,
      children: [
        {
          path: "/app/reports/finance/",
          element: <Navigate to={`/app/reports/finance/accounts`} />,
        },
        { path: "/app/reports/finance/accounts", element: <Accounts /> },
        { path: "/app/reports/finance/sales", element: <SalesByProduct /> },
      ],
    },
    {
      path: "/app/reports/tours/",
      element: <Tours />,
    },
  ],
};
