/********************************************************************
 *
 * Index.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router";
import { Card, CardContent, Typography } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { Main } from "../../../lib";
import { useApp, useAPI } from "../../../../providers/AppProvider";

/**
 * RateClassPartners
 *
 * Renders the partners for a rate type.
 *
 * @returns
 */
const Index = () => {
  const [partners, setPartners] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const app = useApp();
  const api = useAPI();
  const { id } = useParams();

  useEffect(() => {
    if (id)
      api
        .fetch(`/api/rateclasses/rateclass/${id}/partners`)
        .then(({ payload: partners }) => {
          setPartners(partners);
          setLoaded(true);
        })
        .catch((error) => {
          app.error({ error });
          setLoaded(true);
        });
  }, [id]);

  ///////////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  if (!partners) return "Loading Partners...";

  return (
    partners &&
    partners.map((partner) => (
      <Card key={partner.data.id} sx={{ width: 330 }}>
        <Link
          to={`/app/catalog/rateclass/${id}/partner/${partner.data.id}/edit`}
        >
          <CardContent>
            <img
              className={`img-fluid float-right d-none ${
                partner.data.logoURL && "d-md-block"
              } mb-3`}
              width="75"
              src={partner.data.logoURL}
              alt={partner.data.name}
            />
            <Typography>{partner.data.name}</Typography>
          </CardContent>
        </Link>
      </Card>
    ))
  );
};

export default Index;
