import React, { useEffect, useState } from "react";
import { Outlet, useSearchParams } from "react-router";
import OrderProvider from "../../providers/OrderProvider";

const Index = () => {
  const [orderId, setOrderId] = useState(null);
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    //
    //  If order id is a paramter, we are in edit mode, otherwise
    //  we are creating a new product
    //
    setOrderId(searchParams.get("orderId"));
  }, [searchParams]);

  //
  //  Create the section router.
  //
  return (
    <OrderProvider orderId={orderId}>
      <Outlet />
    </OrderProvider>
  );
};

export default Index;
