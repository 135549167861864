/********************************************************************
 *
 * Tour.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { useParams } from "react-router";

/**
 * Ticket
 *
 * @param {*} param0
 */
const Ticket = () => {
  const { id: tourId } = useParams();

  return tourId;
};

export default Ticket;
