/********************************************************************
 *
 * IconLib.js
 *
 * Exports icons used in the rendering of maintenance objects.
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import { Tooltip, Chip } from "@mui/material";
import {
  InfoOutlined,
  AssignmentOutlined,
  WarningAmber,
  Error,
  Dangerous,
} from "@mui/icons-material";

export const SEVERITY_ICONS = [
  <Tooltip title="Information" placement="top-end">
    <InfoOutlined color="severityInformation" />
  </Tooltip>,
  <Tooltip title="Planned" placement="top-end">
    <AssignmentOutlined color="severityPlanned" />
  </Tooltip>,
  <Tooltip title="Warning" placement="top-end">
    <WarningAmber color="severityWarning" />
  </Tooltip>,
  <Tooltip title="Major" placement="top-end">
    <Error color="severityMajor" />
  </Tooltip>,
  <Tooltip title="Critical" placement="top-end">
    <Dangerous color="severityCritical" />
  </Tooltip>,
];

export const PRIORITY_COLOURS = [
  "priorityVeryLow",
  "priorityLow",
  "priorityNormal",
  "priorityHigh",
  "priorityVeryHigh",
];

export const STATUS_CHIPS = [
  <Chip label="Postponed" variant="filled" color="statusPostponed" />,
  <Chip label="In Progress" variant="outlined" color="statusOpened" />,
  <Chip label="Resolved" variant="filled" color="statusCompleted" />,
  <Chip label="Closed" variant="filled" color="statusClosed" />,
];
