/********************************************************************
 *
 * /src/components/catalog/products/rates/RateClasses.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router";
import { Card, CardContent, CardActions, Button } from "@mui/material";
import { CardList } from "../../../lib";
import { RateClassSummaryHeader } from "../../RateClass/Identity/RateClassSummaryHeader";
import Rates from "./Rates";
import RateEdit from "./RateEdit";
import { useApp, useAPI } from "../../../../providers/AppProvider";

/**
 * RateClasses
 *
 * Renders a list of Rate Classes for the Product Type.
 *
 * Rates can be added for any rate class.
 *
 */
const RateClasses = () => {
  const [rateClasses, setRateClasses] = useState(null);
  const [editRateData, setEditRateData] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const { productType } = useOutletContext();
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    fetchRateClasses();
  }, []);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * FetchRateClasses
   *
   * Calls the api to fetch rate classes
   *
   */
  const fetchRateClasses = () => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading");

    api
      .fetch(
        `/api/productTypes/productType/${productType.id}/rateclasses/?verbose`
      )
      .then(({ payload: rateClasses }) => {
        setRateClasses(rateClasses);
        setLoaded(true);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setRateClasses(null);
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   *  OnEdit
   *
   *  Handles a request for edit mode.
   *
   */
  const onEdit = ({ rateClass, rate }) => {
    setEditRateData({ rateClass, rate });
  };

  /**
   *  OnSaved
   *
   *  Fired once the edit state has saved a rate.
   *
   *  Refreshes the rata data and rturns to display mode.
   *
   * @param {*} event
   */
  const onSaved = () => {
    fetchRateClasses();
    setEditRateData(null);
  };

  /**
   *  OnCancelled
   *
   *  Fired once the edit state has aboorted changes to a rate.
   *
   *  Returns to display mode.
   *
   */
  const onCancelled = () => {
    setEditRateData(null);
  };

  /**
   * OnDelete
   *
   * Handles a request to delete a rate from one of the product
   * type's rate classes
   *
   *  @param {*} event
   */
  const onDelete = ({ rateClass, rate }) => {
    if (!window.confirm("Are you sure you want to delete this rate?")) return;

    //
    //  Status message
    //
    const notifyHandle = app.notify("Reoading");

    api
      .delete(
        `/api/productTypes/productType/${productType.id}/rateclasses/rateclass/${rateClass.id}/rates/rate/${rate.id}`
      )
      .then(() => {
        return fetchRateClasses()
          .then((rateClasses) => {
            setRateClasses(rateClasses);
            setEditRateData(null);
            setLoaded(true);
            app.endNotify(notifyHandle);
          })
          .catch((error) => {
            app.error({ error });
          });
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  return (
    <>
      {loaded && !rateClasses && "No Rate Classes Available"}
      <CardList
        xs={12}
        spacing={2}
        items={rateClasses}
        onRenderCard={({ item: rateClass }) => {
          return (
            !rateClass.deleted && (
              <RateClassCard
                key={rateClass.id}
                productType={productType}
                rateClass={rateClass}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            )
          );
        }}
      />
      {!!editRateData && (
        <RateEdit
          productType={productType}
          rateClass={editRateData.rateClass}
          rate={editRateData.rate}
          show={!!editRateData}
          onSaved={onSaved}
          onCancelled={onCancelled}
        />
      )}
    </>
  );
};

/**
 * RateClassCard
 *
 * Renders a list of Rate Class summaries in card format
 *
 * @param {*} props
 */
const RateClassCard = ({ productType, rateClass, onEdit, onDelete }) => {
  return (
    <Card id={`rc${rateClass.id}`}>
      <CardContent>
        <RateClassSummaryHeader rateClass={rateClass} />
        <Rates
          productType={productType}
          rateClass={rateClass}
          rates={rateClass.rates}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      </CardContent>
      <CardActions>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => {
            onEdit({ rateClass, rate: {} });
          }}
        >
          Add Rate
        </Button>
        <Button
          size="small"
          onClick={() => {
            alert("TBD");
          }}
        >
          Delete
        </Button>
      </CardActions>
    </Card>
  );
};

export default RateClasses;
