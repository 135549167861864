/********************************************************************
 *
 * TextFieldEditor.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Close } from "@mui/icons-material";

const CCDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        margin: 0,
        padding: 2,
      }}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          size="large"
          sx={{
            position: "absolute",
            right: 1,
            top: 1,
            color: "red",
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

/**
 * TextFieldEditor
 *
 * Component that displays and edits textfields.
 *
 */
const TextFieldEditor = ({ title, prefix, content, variant, onSave, sx }) => {
  const [value, setValue] = useState(undefined);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setValue(content);
  }, [content]);

  return (
    <Box sx={sx}>
      <Button
        variant={variant}
        onClick={() => {
          setOpen(true);
        }}
        sx={{
          textTransform: "none",
          height: "36px",
          borderRadius: "18px",
          padding: "8px",
          marginLeft: "-8px",
        }}
      >
        <Typography>{`${prefix ? prefix + " " : ""} ${
          content ? content : "--"
        }`}</Typography>
      </Button>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <CCDialogTitle
          onClose={() => {
            setOpen(false);
          }}
        >
          {title}
        </CCDialogTitle>
        <DialogContent>
          <Box display="flex">
            <Box flexGrow={1}>
              <TextField
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onSave(value);
              setOpen(false);
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

/**
 * PropTypes
 */
TextFieldEditor.propTypes = {
  message: PropTypes.string,
  onSave: PropTypes.func.isRequired,
};

export default TextFieldEditor;
