/********************************************************************
 *
 * /Catalog/Products/Features/Identity/Summary.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { Box, Typography } from "@mui/material";
import { VerticalGroup, Property } from "../../../../lib";

/**
 * Summary
 *
 * Renders a feature type.
 *
 */
const Summary = ({ featureType }) => {
  const { shortDescription, description, fulfillment } = featureType || {};

  return (
    <>
      <VerticalGroup>
        <Property title="Short Description" data={shortDescription} />
        <Property title="Full Description" data={description} />
      </VerticalGroup>
      <Box>
        <Typography sx={{ fontSize: "0.85em", fontWeight: 600 }}>
          Fulfillment
        </Typography>
        {fulfillment ? (
          <Typography
            dangerouslySetInnerHTML={{ __html: fulfillment }}
            sx={{ fontSize: "0.85em", pl: 2 }}
          ></Typography>
        ) : (
          "No fulfillment information available."
        )}
      </Box>
    </>
  );
};

export default Summary;
