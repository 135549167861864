/********************************************************************
 *
 * SupplierGoodType.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Outlet } from "react-router";
import { EventNote } from "@mui/icons-material";
import { Main } from "../../lib";
import {
  SectionList,
  SectionItemGroup,
  SectionItem,
} from "../../lib/SideBar/Menu";
import { useApp, useAPI } from "../../../providers/AppProvider";

/**
 * SupplierGoodType
 *
 * Container component for managing all attributes of a
 * @see SupplierGoodType.
 *
 * The supplier good type id is passed in the URI.
 *
 */
const SupplierGoodType = () => {
  const [goodType, setGoodType] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();
  const { goodTypeId } = useParams();
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    //
    //  If supplier type id is a paramter, we are in edit mode, otherwise
    //  we are creating a new supplier type
    //
    fetchGoodType(goodTypeId);
  }, []);

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * FetchGoodType
   *
   * Calls the API to fetch the requested supplier good type.
   *
   * If the supplier id is null, create a shell supplier good type.
   *
   * @param {*} goodTypeId
   */
  const fetchGoodType = (goodTypeId) => {
    return new Promise((resolve, reject) => {
      if (!goodTypeId || goodTypeId === 0) {
        //
        //  New Supplier Type Mode - initialize empty object
        //
        resolve({});
        return;
      }

      //
      //  Status message
      //
      const notifyHandle = app.notify("Loading");

      api
        .fetch(`/api/goodtypes/goodtype/${goodTypeId}/?verbose`)
        .then(({ payload: goodType }) => {
          setGoodType(goodType);
          setLoaded(true);
          app.endNotify(notifyHandle);
        })
        .catch((error) => {
          app.error({ error });
          setGoodType(null);
          setLoaded(true);
        });
    });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnUpdate
   *
   * Fired when a child updates the data object.
   *
   * @param {*} event
   */
  const onUpdate = () => {
    //
    //  If googTypeId is a paramter, we are in edit mode, otherwise
    //  we are creating a new supplier good type
    //
    fetchGoodType(goodTypeId);
  };

  /**
   * onDelete
   *
   * Handles a request in display mode to delete a supplier good type.
   *
   * @param {*} event
   */
  const onDelete = (event) => {
    if (
      !window.confirm(
        "Are you sure you want to delete this supplier good type?"
      )
    )
      return;

    //
    //  Status message
    //
    const notifyHandle = app.notify("Deleting");

    api
      .delete(`/api/goodtypes/goodtype/${goodType.id}`)
      .then(() => {
        navigate(`/app/goods/`);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  if (!goodType) return null;

  return (
    <Main
      menu={{
        title: "Manage Third Party Goods",
        subtitle: "Goods from another company",
        nav: <Menu goodType={goodType} />,
      }}
      header={{
        icon: (
          <img src={goodType.supplier.logoURL} alt={goodType.supplier.name} />
        ),
        title:
          goodType && goodType.name ? goodType.name : "New Third Party Good",
      }}
      loaded={loaded}
    >
      <Outlet context={{ goodType, onUpdate }} />
    </Main>
  );
};

/**
 * Menu
 *
 * Renders the navigation controls for the functional areas of the
 * supplier type management page.
 *
 * @param {*} props
 */
const Menu = ({ goodType }) => {
  const { id = 0 } = goodType || {};

  return (
    <SectionList>
      <SectionItemGroup icon={<EventNote />} name="Manage" open={true}>
        <SectionItem to={`/app/goods/suppliertype/${id}/schedule`}>
          Schedule
        </SectionItem>
        <SectionItem to={`/app/goods/suppliertype/${id}/notifications`}>
          Notifications
        </SectionItem>
      </SectionItemGroup>
    </SectionList>
  );
};

export default SupplierGoodType;
