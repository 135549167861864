/********************************************************************
 *
 * CustomerCard.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Avatar, IconButton } from "@mui/material";
import { Edit, FindInPage } from "@mui/icons-material";
import { WidgetFrame } from "../../lib";
import Summary from "./Summary";
import Editor from "./Editor";
import Directory from "./Directory";

/**
 * CustomerCard
 *
 * Customer Editor/Picker control.
 *
 * Properties:
 *  customer - customer object.
 *  onCustomerSelected - callback function fired after a customer is
 *      selected and saved. Event contains the selected customer.
 *
 * When the component is in Display mode, the props version of
 * customer is rendered. When the user switches to Edit mode, the
 * property version of customer is copied into the state.
 *
 * Display mode if OuterCustomer is not null and InnerCustomer is null.
 * Edit mode if OuterCustomer is null or InnerCustomer is not null
 *
 * Component needs to store a selected existing customer before it is
 * saved to the parent object.
 *
 */
const CustomerCard = ({ customer, onCustomerSelect }) => {
  const [edit, setEdit] = useState(false);
  const [showDirectory, setShowDirectory] = useState(false);

  /**
   * UseEffect
   *
   * Sets the edit mode based upon the existance of customer supplied
   * by the containing element. If a customer is supplied, default to
   * display mode.
   *
   */
  useEffect(() => {
    setEdit(!customer);
  }, [customer]);

  ///////////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////////

  /**
   * OnSave
   *
   * Fired when the user saves changes to the customer.
   *
   * Calls the API to save the data.
   *
   * @param {*} customer
   */
  const onSave = ({ customer }) => {
    onCustomerSelect({ customer });
    setEdit(false);
  };

  return (<>
    <WidgetFrame
      avatar={
        customer && (
          <Avatar src={customer.imageURL}>{`${
            customer.fname && customer.fname[0]
          }${customer.lname && customer.lname[0]}`}</Avatar>
        )
      }
      title={
        customer
          ? `${customer.fname} ${customer.lname}`
          : "New Customer Information"
      }
      action={
        !edit ? (
          <IconButton
            onClick={() => {
              setEdit(true);
            }}
            size="large">
            <Edit />
          </IconButton>
        ) : (
          <IconButton
            onClick={() => {
              setShowDirectory(true);
            }}
            size="large">
            <FindInPage />
          </IconButton>
        )
      }
    >
      {!edit ? (
        <Summary customer={customer} />
      ) : (
        <Editor
          customer={customer}
          onSaved={onSave}
          onCancelled={() => {
            setEdit(!customer);
          }}
        />
      )}
    </WidgetFrame>
    <Directory
      onChangeCustomer={(customer) => {
        onCustomerSelect({ customer });
        setShowDirectory(false);
      }}
      show={showDirectory}
      onHide={() => {
        setShowDirectory(false);
      }}
    />
  </>);
};

/*
 **  PropTypes
 */
CustomerCard.propTypes = {
  customer: PropTypes.object,
  onCustomerSelect: PropTypes.func.isRequired,
};

export default CustomerCard;
