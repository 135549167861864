/********************************************************************
 *
 * /src/components/catalog/products/rates/Rates.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import format from "../../../../utils/format";
import { Edit, DeleteForever } from "@mui/icons-material";

/**
 * Rates
 *
 * Renders the rates for a product type's rate class.
 */
const Rates = ({
  rateClass,
  rates,
  readonly,
  highlightRate,
  onEdit,
  onDelete,
}) => {
  ///////////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  if (!rates) return null;

  return (
    <table className="table table-striped table-sm small">
      <thead>
        <tr>
          <th>Rate</th>
          <th>Applied</th>
          <th>Open</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {rates.map((rate) => (
          <tr
            key={rate.id}
            className={`${
              highlightRate && highlightRate.id === rate.id ? "table-info" : ""
            }`}
          >
            <td>{format.currency(rate.amount)}</td>
            <td>{`${format.date(rate.start)} - ${format.date(rate.end)}`}</td>
            <td>{`${format.date(rate.open)} - ${format.date(rate.close)}`}</td>
            <td>
              <button
                type="button"
                className={`btn btn-link float-right py-0 ${
                  readonly ? "d-none" : ""
                }`}
                onClick={() => onDelete({ rateClass: rateClass, rate: rate })}
              >
                <DeleteForever />
              </button>
              <button
                type="button"
                className={`btn btn-link float-right py-0 ${
                  readonly ? "d-none" : ""
                }`}
                onClick={() => onEdit({ rateClass: rateClass, rate: rate })}
              >
                <Edit />
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Rates;
