/********************************************************************
 *
 * /src/components/sales/reviews/index.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router";
import { Button } from "@mui/material";
import { WidgetFrame, LinkCard2, Property, VerticalGroup } from "../../../lib";
import { ReviewHeader, ReviewEdit } from "../../../CRM/Reviews/Identity";
import ModalDialog, { DialogContent } from "../../../lib/ModalDialog";

import { useApp, useAPI } from "../../../../providers/AppProvider";

/**
 * Reviews
 *
 * Component that displays the reviews for an order.
 *
 */
const Reviews = () => {
  const [reviews, setReviews] = useState(null);
  const [showAddReview, setShowAddReview] = useState(false);
  const { order } = useOutletContext();
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    fetchReviews(order.id);
  }, [order]);

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * fetchReviews
   *
   * Calls the API to fetch reviews for the current order
   *
   * @param {*} orderId
   */
  const fetchReviews = (orderId) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading Reviews");

    api
      .fetch(`/api/orders/order/${orderId}/reviews`)
      .then(({ payload: reviews }) => {
        setReviews(reviews);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setReviews(null);
      });
  };

  /**
   * OnSaved
   *
   * Fired once the edit state has saved the descriptive information.
   *
   * Returns to display mode.
   *
   * @param {*} event
   */
  const onSaved = () => {
    fetchReviews(order.id);
    setShowAddReview(false);
  };

  return (
    <WidgetFrame
      title="Customer Feedback"
      action={
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setShowAddReview(true)}
        >
          Add Review
        </Button>
      }
    >
      <>
        <VerticalGroup>
          {!reviews
            ? "No reviews available"
            : reviews.map((review) => (
                <LinkCard2
                  key={review.id}
                  link={`/app/crm/review/${review.id}`}
                  content={
                    <>
                      <ReviewHeader
                        review={review}
                        onUpdate={() => fetchReviews(order.id)}
                      />
                      <Property>{review.lead}</Property>
                    </>
                  }
                  elevation={0}
                />
              ))}
        </VerticalGroup>
        {!!showAddReview && (
          <ModalDialog
            title="Manage Review"
            subtitle="Reviews are the core of customer trust and company reputation"
            show={!!showAddReview}
          >
            <DialogContent>
              <ReviewEdit
                order={order}
                onSaved={onSaved}
                onCancelled={() => setShowAddReview(false)}
              />
            </DialogContent>
          </ModalDialog>
        )}
      </>
    </WidgetFrame>
  );
};

export default Reviews;
