/********************************************************************
 *
 * /components/lib/analytics/superStat.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2021 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { Card, CardHeader, CardContent, Typography } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const SuperStat = ({ icon, label, value }) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={icon}
        title={label}
        classes={{ root: classes.headerRoot, title: classes.headerTitle }}
      />
      <CardContent style={{ textAlign: "center" }}>
        <Typography variant="h2">{value}</Typography>
      </CardContent>
    </Card>
  );
};

/*
 **  Styles
 */
const useStyles = makeStyles((theme) => ({
  card: {
    height: "100%",
    padding: theme.spacing(0),
  },
  headerRoot: { paddingBottom: 0 },
  headerTitle: {
    color: theme.palette.text.primary,
    fontSize: "0.95em",
    fontWeight: 500,
    textTransform: "uppercase",
  },
}));

export default SuperStat;
