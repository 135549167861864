/********************************************************************
 *
 * /Catalog/Products/Identity/Edit.jsx
 *
 * Renders a control to add or edit the descriptive information of a
 * product type. The control is contained in a drawer and managed by
 * a Formik form.
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, TextField, MenuItem, Typography } from "@mui/material";
import * as yup from "yup";
import { EditorPane, useEditorContext } from "../../../lib";
import { useApp, useAPI } from "../../../../providers/AppProvider";

/**
 * IdentityEdit
 *
 * Component for adding a new Product Type, or editting the
 * descriptive information of an exiting Product Type.
 *
 */
const IdentityEdit = ({ productType, open, onSaved, onCancelled }) => {
  const app = useApp();
  const api = useAPI();

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnSave
   *
   * Fired when the user saves the changes.
   *
   * Updates the product type and notifies the parent.
   *
   * @param {*} values
   */
  const onSave = (values) => {
    //
    //  Save
    //
    const notifyHandle = app.notify("Saving");

    api
      .update(
        `/api/producttypes/producttype/${values.id ? values.id : ""}`,
        values
      )
      .then(({ payload: productType }) => {
        onSaved && onSaved({ productType });
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  /**
   * OnCancel
   *
   * Fired when the user aborts changes.
   *
   */
  const onCancel = (dirty) => {
    if (
      !dirty ||
      window.confirm(
        "You have unsaved changes. Are you sure you want to close this dialog and loose your changes?"
      )
    ) {
      onCancelled && onCancelled();
    }
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  return (
    <EditorPane
      title={`Manage Product Type`}
      initialValues={
        productType || {
          name: "",
          tagLine: "",
          target: "",
          shortDescription: "",
          description: "",
        }
      }
      onSubmit={(values) => {
        onSave(values);
      }}
      validationSchema={yup.object().shape({
        name: yup.string().nullable().required("Name is required"),
        tagLine: yup.string().nullable().required("Tag line is required"),
        target: yup.string().required("Target is required"),
        shortDescription: yup
          .string()
          .nullable()
          .required("Short description is required"),
        description: yup
          .string()
          .nullable()
          .required("Description is required"),
        accountId: yup.number().required("Revenue accouont is required"),
      })}
      open={open}
      onClose={onCancelled}
    >
      <FormBody />
    </EditorPane>
  );
};

/**
 * FormBody
 *
 * @param {*} props
 */
const FormBody = ({}) => {
  const {
    values,
    touched,
    errors,
    dirty,
    isSubmitting,
    setFieldValue,
    setValues,
    handleChange,
    handleBlur,
  } = useEditorContext();
  const [accounts, setAccounts] = useState(null);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    fetchRevenueAccounts();
  }, []);

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////

  const fetchRevenueAccounts = () => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading");

    api
      .fetch(`/api/accounts/type/6`)
      .then(({ payload: accounts }) => {
        setAccounts(accounts);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  return (
    <Box>
      <Typography variant="h4" paragraph>
        Describe the Product
      </Typography>
      <Box sx={{ pl: 2, mb: 2 }}>
        <TextField
          name="name"
          label="Name"
          value={values.name}
          onBlur={handleBlur}
          onChange={handleChange}
          fullWidth
          required
          error={!!touched.name && !!errors.name}
          helperText={(!!touched.name && !!errors.name) || " "}
        />
        <TextField
          name="tagLine"
          label="Tag Line"
          value={values.tagLine}
          onBlur={handleBlur}
          onChange={handleChange}
          multiline
          rows="2"
          variant="outlined"
          fullWidth
          required
          error={!!touched.tagLine && !!errors.tagLine}
          helperText={(!!touched.tagLine && !!errors.tagLine) || " "}
        />
        <TextField
          name="target"
          label="Target"
          value={values.target}
          onBlur={handleBlur}
          onChange={handleChange}
          multiline
          rows="2"
          variant="outlined"
          fullWidth
          required
          error={errors.target && touched.target}
          helperText={errors.target || " "}
        />
        <TextField
          name="shortDescription"
          label="Short Description"
          value={values.shortDescription}
          onBlur={handleBlur}
          onChange={handleChange}
          multiline
          rows="2"
          variant="outlined"
          fullWidth
          required
          error={!!touched.shortDescription && !!errors.shortDescription}
          helperText={
            (!!touched.shortDescription && !!errors.shortDescription) || " "
          }
        />
        <TextField
          name="description"
          label="Description"
          value={values.description}
          onBlur={handleBlur}
          onChange={handleChange}
          multiline
          rows="5"
          variant="outlined"
          fullWidth
          required
          error={!!touched.description && !!errors.description}
          helperText={(!!touched.description && !!errors.description) || " "}
        />
        <TextField
          name="timezone"
          label="What time zone is it in?"
          select
          value={values.timezone != null ? values.timezone : ""}
          onChange={(e) => {
            setFieldValue("timezone", e.target.value);
          }}
          error={!!touched.timezone && !!errors.timezone}
          helperText={(!!touched.timezone && !!errors.timezone) || " "}
          sx={{ width: "100%" }}
        >
          <MenuItem value="UTC">No Timezone</MenuItem>
          <MenuItem value="America/Vancouver">Pacific</MenuItem>
        </TextField>
        <TextField
          name="accountId"
          label="What account does it use?"
          select
          value={values.accountId ? values.accountId : ""}
          onBlur={handleBlur}
          onChange={(e) => {
            setFieldValue("accountId", e.target.value);
          }}
          error={!!touched.accountId && !!errors.accountId}
          helperText={(!!touched.accountId && !!errors.accountId) || " "}
          sx={{ width: "100%" }}
        >
          <MenuItem value="-1" disabled>
            -- Select --
          </MenuItem>
          {accounts &&
            accounts.map((account) => (
              <MenuItem key={account.id} value={account.id}>
                {account.name}
              </MenuItem>
            ))}
        </TextField>
      </Box>
    </Box>
  );
};

/**
 * PropTypes
 */
IdentityEdit.propTypes = {
  productType: PropTypes.object,
  open: PropTypes.bool,
  onSaved: PropTypes.func,
  onCancelled: PropTypes.func,
};

export default IdentityEdit;
