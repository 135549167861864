/********************************************************************
 *
 * /components/goods/goods/tour/edit.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import { Formik } from "formik";
import * as yup from "yup";
import { WidgetFrame } from "../../lib/";
import { HorizontalGroup } from "../../lib/Groups";
import ModalDialog, { DialogContent } from "../../lib/ModalDialog";
import DateTimePicker from "../../lib/Inputs/DateTimePicker";
import { useApp, useAPI } from "../../../providers/AppProvider";

/**
 * Edit
 *
 * Component for adding a new, or editting the descriptive information
 * an existing Tour.
 *
 */
const Edit = ({ tour, show, onSaved, onCancelled }) => {
  const app = useApp();
  const api = useAPI();

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnSave
   *
   * Fired when the user saves the changes.
   *
   * Updates the product type and notifies the parent.
   *
   * @param {*} values
   */
  const onSave = (values) => {
    //
    //  Save
    //
    const notifyHandle = app.notify("Saving");

    api
      .update(`/api/tours/tour/${values.id ? values.id : ""}`, values)
      .then(({ payload: tour }) => {
        onSaved && onSaved({ tour });
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  /**
   * OnCancel
   *
   * Fired when the user aborts changes.
   *
   */
  const onCancel = (dirty) => {
    if (
      !dirty ||
      window.confirm(
        "You have unsaved changes. Are you sure you want to close this dialog and loose your changes?"
      )
    ) {
      onCancelled && onCancelled();
    }
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  return (
    <ModalDialog
      title="Manage Tour"
      subtitle="Update tour information."
      size="md"
      show={show}
    >
      <DialogContent>
        <FormBody tour={tour} onSave={onSave} onCancel={onCancel} />
      </DialogContent>
    </ModalDialog>
  );
};

/**
 * FormBody
 *
 * @param {*} props
 */
const FormBody = ({ tour, onCancel, onSave }) => (
  <Formik
    initialValues={tour}
    enableReinitialize={true}
    onSubmit={(values) => {
      onSave(values);
    }}
    validationSchema={yup.object().shape({
      acquire: yup
        .date("Invalid acquire time format")
        .max(yup.ref("start"), "Acquire time must be before start time")
        .required("Acquire time is required"),
      start: yup
        .date("Invalid departure time format")
        .required("Start time is required"),
      end: yup
        .date("Invalid return time format")
        .min(yup.ref("start"), "Return time must be later than departure time.")
        .required("End time is required"),
      release: yup
        .date("Invalid release time format")
        .min(yup.ref("end"), "Release time must be after end time")
        .required("Release time is required"),
      capacity: yup.number().integer().required("Capacity is required"),
    })}
  >
    {({
      values,
      touched,
      errors,
      dirty,
      isSubmitting,
      isValid,
      setFieldValue,
      setValues,
      handleChange,
      handleBlur,
      handleSubmit,
    }) => {
      return (
        <form onSubmit={handleSubmit}>
          <TourForm
            tour={values}
            touched={touched}
            errors={errors}
            setFieldValue={setFieldValue}
            setValues={setValues}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end", pt: 2 }}>
            <HorizontalGroup gap={1}>
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                disabled={!isValid || !dirty || isSubmitting}
              >
                Save
              </Button>
              <Button onClick={() => onCancel(dirty)}>Cancel</Button>
            </HorizontalGroup>
          </Box>
        </form>
      );
    }}
  </Formik>
);

/**
 * TourForm
 *
 * @param {*} props
 */
const TourForm = ({
  tour,
  touched,
  errors,
  setFieldValue,
  setValues,
  onBlur,
  onChange,
}) => {
  const [assets, setAssets] = useState(null);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    fetchAssets();
  }, []);

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility Functions
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * FetchAssetsForTour
   *
   * Initializes the list of available assets for the tour.
   */
  const fetchAssets = () => {
    api
      .fetch(`/api/tours/tour/${tour.id}/assets/`)
      .then(({ payload: assets }) => {
        setAssets(assets);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <WidgetFrame title="Tour">
        <Box sx={{ display: "flex", flexDirection: "column", gap: 0 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: 2,
            }}
          >
            <DateTimePicker
              id="start"
              label="Depart"
              value={DateTime.fromISO(tour.start)}
              tz={tour.tz}
              onBlur={onBlur}
              onChange={(value) => {
                setValues({
                  ...tour,
                  acquire: value
                    .minus({
                      minutes:
                        process.env
                          .REACT_APP_DEFAULT_TOUR_ASSET_PRETOUR_INTERVAL,
                    })
                    .toUTC()
                    .toISO(),
                  start: value.toUTC().toISO(),
                });
              }}
              fullWidth
              required
            />
            <DateTimePicker
              id="end"
              label="Return"
              value={DateTime.fromISO(tour.end)}
              tz={tour.tz}
              onBlur={onBlur}
              onChange={(value) => {
                setValues({
                  ...tour,
                  end: value.toISO(),
                  release: value
                    .plus({
                      minutes:
                        process.env
                          .REACT_APP_DEFAULT_TOUR_ASSET_POSTTOUR_INTERVAL,
                    })
                    .toISO(),
                });
              }}
              fullWidth
              required
            />
          </Box>
          <Box>
            <FormHelperText error>
              {errors.start || errors.end || " "}
            </FormHelperText>
          </Box>
        </Box>
      </WidgetFrame>
      <WidgetFrame title="Shift">
        <Box sx={{ display: "flex", flexDirection: "column", gap: 0 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: 2,
            }}
          >
            <DateTimePicker
              id="acquire"
              label="Acquire"
              value={DateTime.fromISO(tour.acquire)}
              tz={tour.tz}
              onBlur={onBlur}
              onChange={(value) => {
                const start = DateTime.fromISO(tour.start);
                setValues({
                  ...tour,
                  acquire: value < start ? value.toISO() : start.toISO(),
                });
              }}
              fullWidth
              required
            />
            <DateTimePicker
              id="release"
              label="Release"
              value={DateTime.fromISO(tour.release)}
              tz={tour.tz}
              onBlur={onBlur}
              onChange={(value) => {
                const end = DateTime.fromISO(tour.end);
                setValues({
                  ...tour,
                  release: value > end ? value.toISO() : end.toISO(),
                });
              }}
              fullWidth
              required
            />
          </Box>
          <Box>
            <FormHelperText error>
              {errors.acquire || errors.release || " "}
            </FormHelperText>
          </Box>
        </Box>
      </WidgetFrame>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 2,
          pt: 2,
        }}
      >
        <Box sx={{ width: "50%" }}>
          <TextField
            id="capacity"
            type="number"
            label="Capacity"
            variant="outlined"
            value={tour.capacity}
            inputProps={{ min: 0 }}
            onBlur={onBlur}
            onChange={onChange}
            fullWidth
            error={!!errors.capacity && touched.capacity}
            helperText={errors.capacity || " "}
          />
        </Box>
        <Box sx={{ width: "50%" }}>
          <FormControl fullWidth>
            <InputLabel id="asset-label" shrink>
              Asset
            </InputLabel>
            <Select
              inputProps={{
                name: "asset",
                id: "asset",
              }}
              labelId="asset-label"
              value={!!tour.asset ? tour.asset.id : -1}
              onChange={(e) => {
                const id = e.target.value;

                setFieldValue(
                  "asset",
                  id === -1
                    ? null
                    : assets.find((asset) => asset.id == e.target.value)
                );
              }}
            >
              <MenuItem value={-1}>None</MenuItem>
              {!!assets &&
                assets.map((asset) => (
                  <MenuItem key={asset.id} value={asset.id}>
                    {asset.name}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText> </FormHelperText>
          </FormControl>
        </Box>
      </Box>
      <Box>
        <TextField
          id="notes"
          type="text"
          label="notes"
          value={tour.notes || ""}
          onBlur={onBlur}
          onChange={onChange}
          multiline
          rows="2"
          variant="outlined"
          fullWidth
          error={!!errors.notes && touched.notes}
          helperText={errors.notes || " "}
        />
      </Box>
    </Box>
  );
};

export default Edit;
