/********************************************************************
 *
 * Edit.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import { Typography, Box, Grid, TextField, Button } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { HorizontalGroup } from "../../lib";
import { useApp, useAPI } from "../../../providers";

/**
 * Edit Customer
 *
 * Edit customer information
 *
 * @param {*} props
 */
const Editor = ({ customer, onSaved, onCancelled }) => {
  const app = useApp();
  const api = useAPI();

  ///////////////////////////////////////////////////////////////////
  //
  //  Utiltiy Methods
  //
  ///////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnSave
   *
   * Fired when the user commits the change.
   *
   * @param {*} values
   */
  const onSave = (values) => {
    //
    //  Save
    //
    const notifyHandle = app.notify("Saving Customer");

    api
      .update(`/api/customers/customer/${values.id ? values.id : ""}`, values)
      .then(({ payload: customer }) => {
        onSaved && onSaved({ customer });
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  /**
   * OnCancel
   *
   * @param {*} dirty
   */
  const onCancel = (dirty) => {
    if (
      !dirty ||
      window.confirm(
        "You have unsaved changes. Are you sure you want to close this dialog and loose your changes?"
      )
    ) {
      onCancelled && onCancelled();
    }
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return <FormBody customer={customer} onSave={onSave} onCancel={onCancel} />;
};

/**
 * FormBody
 *
 * @param {*} props
 */
const FormBody = ({ customer, onSave, onCancel }) => {
  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////
  return (
    <Formik
      initialValues={
        customer
          ? customer
          : {
              fname: "",
              lname: "",
              phone: "",
              email: "",
              imageURL: "",
              comments: "",
            }
      }
      enableReinitialize={true}
      onSubmit={(customer) => {
        onSave(customer);
      }}
      validationSchema={yup.object().shape({})}
    >
      {({
        values,
        touched,
        errors,
        dirty,
        isSubmitting,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <CustomerForm
              customer={values}
              touched={touched}
              errors={errors}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <Box display="flex" justifyContent="flex-end">
              <HorizontalGroup gap={1}>
                <Button
                  type="submit"
                  variant="outlined"
                  color="primary"
                  disabled={!dirty || isSubmitting}
                >
                  Save
                </Button>
                {!!values.id && (
                  <Button onClick={() => onCancel(dirty)}>Cancel</Button>
                )}
              </HorizontalGroup>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

const CustomerForm = ({
  customer,
  touched,
  errors,
  setFieldValue,
  onBlur,
  onChange,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <TextField
          id="fname"
          label="First Name"
          value={customer.fname}
          onBlur={onBlur}
          onChange={onChange}
          fullWidth
          required
          error={errors.fname && touched.fname}
          helperText={errors.fname || " "}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          id="lname"
          label="Last Name"
          value={customer.lname}
          onBlur={onBlur}
          onChange={onChange}
          fullWidth
          required
          error={errors.lname && touched.lname}
          helperText={errors.lname || " "}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          id="phone"
          label="Phone Number"
          value={customer.phone}
          onBlur={onBlur}
          onChange={onChange}
          fullWidth
          error={errors.phone && touched.phone}
          helperText={errors.phone || " "}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          id="email"
          type="email"
          label="Email"
          value={customer.email}
          onBlur={onBlur}
          onChange={onChange}
          fullWidth
          error={errors.email && touched.email}
          helperText={errors.email || " "}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="imageURL"
          type="url"
          label="Image URL"
          value={customer.imageURL}
          onBlur={onBlur}
          onChange={onChange}
          fullWidth
          error={errors.imageURL && touched.imageURL}
          helperText={errors.imageURL || " "}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="comments"
          label="Comments"
          value={customer.comments}
          onBlur={onBlur}
          onChange={onChange}
          multiline
          rows="4"
          variant="outlined"
          fullWidth
          error={errors.comments && touched.comments}
          helperText={errors.comments || " "}
        />
        <Typography variant="caption">
          Notes for customer (customer does not see the notes)
        </Typography>
      </Grid>
    </Grid>
  );
};

/*
 **  PropTypes
 */
Editor.propTypes = {
  customer: PropTypes.object,
  onCustomerSelect: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default Editor;
