/********************************************************************
 *
 * /Sales/Campaigns/Identity/Header.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { Box, Avatar, Badge, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { AttachMoney } from "@mui/icons-material";
import { CardContentHeader } from "../../../lib";
import format from "../../../../utils/format";

/**
 * CampaignSummaryHeader
 *
 * @param {*} props
 */
const CampaignSummaryHeader = ({ campaign }) => {
  const classes = useStyles();

  return (
    <CardContentHeader
      avatar={
        <Avatar className={classes.avatar}>
          <AttachMoney />
        </Avatar>
      }
      content={
        <Badge badgeContent={campaign.deleted ? "Deleted" : 0} color="error">
          <Box>
            <Typography variant="h4">{campaign.name}</Typography>
            <Typography variant="caption">
              {format.date(campaign.start)} to {format.date(campaign.end)}
            </Typography>
          </Box>
        </Badge>
      }
    />
  );
};

/*
 **  Styles
 */

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
    width: "50px",
    height: "50px",
  },
}));
export default CampaignSummaryHeader;
