/********************************************************************
 *
 * /Sales/Campaigns/Campaign.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2021 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Outlet, useParams } from "react-router";
import {
  Announcement,
  AssignmentTwoTone,
  SettingsTwoTone,
} from "@mui/icons-material";
import { Main } from "../../lib";
import {
  SectionList,
  SectionItemGroup,
  SectionItem,
} from "../../lib/SideBar/Menu";
import { useApp, useAPI } from "../../../providers/AppProvider";

/**
 * Campaign
 *
 * Displays information for a campaign
 *
 * @param {*} props
 */
const Campaign = () => {
  const [campaign, setCampaign] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  const { id } = useParams();

  useEffect(() => {
    fetchCampaign(id);
  }, []);

  ///////////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////////

  const fetchCampaign = (campaignId) => {
    if (!campaignId) return;

    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading Campaign");

    api
      .fetch(`/api/campaigns/campaign/${campaignId}`)
      .then(({ payload: campaign }) => {
        setCampaign(campaign);
        setLoaded(true);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////////

  /**
   * OnUpdate
   *
   * Fired when a child updates the data object.
   *
   * @param {*} campaign
   */
  const onUpdate = (campaign) => {
    setCampaign(campaign);
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Main
      menu={{
        title: "Campaign Management",
        subtitle: "TBD",
        nav: <Menu campaign={campaign} />,
      }}
      header={{
        icon: <Announcement />,
        title: campaign ? campaign.name : "New Campaign",
        subtitle: campaign && campaign.ref,
        callout: campaign &&
          campaign.deleted && { label: "deleted", color: "error" },
      }}
      loaded={loaded}
    >
      <Outlet context={{ campaign, onUpdate }} />
    </Main>
  );
};

/**
 * Menu
 *
 * Renders the navigation controls for the functional areas of the
 * product type management page.
 *
 * @param {*} props
 */
const Menu = ({ campaign }) => {
  const { id = 0 } = campaign || {};

  return (
    <SectionList>
      <SectionItemGroup icon={<AssignmentTwoTone />} name="Content" open={true}>
        <SectionItem to={`/app/sales/campaign/${id}/description`}>
          Description
        </SectionItem>
      </SectionItemGroup>
      <SectionItemGroup
        icon={<SettingsTwoTone />}
        name="Configuration"
        open={true}
      >
        <SectionItem to={`/app/sales/campaign/${id}/coupons`}>
          Coupons
        </SectionItem>
      </SectionItemGroup>
    </SectionList>
  );
};

export default Campaign;
