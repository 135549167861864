/********************************************************************
 *
 * WorkOrderReducer.jsx
 *
 * Implements state management for the Work Order page and
 * components.
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import { useReducer } from "react";

/////////////////////////////////////////////////////////////////////
//
//  OBJECT DEFINITIONS
//
/////////////////////////////////////////////////////////////////////

/**
 * ACTIONS
 *
 * Defines the set of valid actions on the reducer function
 *
 */
const ACTIONS = {
  HELLO: "hello",
  INITIALIZE_WORKORDER: "initialize_workorder",
  UPDATE_WORKORDER: "update_workorder",
  CREATE_ITEM: "create_item",
  UPDATE_ITEM: "update_item",
  DELETE_ITEM: "delete_item",
  CREATE_NOTE: "create_note",
  DELETE_NOTE: "delete_note",
};

/**
 * InitialState
 *
 * Defines the shape and initial values of the state object
 */
const initialState = {
  workOrder: null,
  initialized: false,
  dirty: false,
  loaded: false,
  openItems: false,
  active: false,
  completed: false,
};

/////////////////////////////////////////////////////////////////////
//
//  HELPER FUNCTIONS
//
/////////////////////////////////////////////////////////////////////

/**
 * HasOpenItems
 *
 * Returns true if all work order items are not open
 *
 * @param {*} workOrder
 * @returns
 */
const hasOpenItems = (workOrder) =>
  workOrder.items == null ||
  workOrder.items.length == 0 ||
  (!!workOrder &&
    !!workOrder.items &&
    !workOrder.items.find((item) => item.status.id == 1));

/**
 * IsActive
 *
 * Returns true if the work order is active
 *
 * @param {*} workOrder
 * @returns
 */
const isActive = (workOrder) =>
  workOrder.started != null && workOrder.completed == null;

/**
 * IsComplete
 *
 * Returns true if the work order is complete
 *
 * @param {*} workOrder
 * @returns
 */
const isComplete = (workOrder) => workOrder.completed != null;

/**
 * Reducer
 *
 * Manages actions on the state object
 *
 * @param {*} state
 * @param {*} action
 *
 * @returns
 */

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.HELLO: {
      console.log("Hello World");
      return state;
    }
    case ACTIONS.INITIALIZE_WORKORDER: {
      //
      //  Payload is the work order
      //
      return {
        ...state,
        workOrder: action.payload,
        openItems: hasOpenItems(action.payload),
        initialized: true,
        loaded: true,
        active: isActive(action.payload),
        completed: isComplete(action.payload),
      };
    }
    case ACTIONS.UPDATE_WORKORDER: {
      //
      //  Payload is the updated work order. Does not include work
      //  order items or notes
      //
      const updatedWorkOrder = {
        ...action.payload,
        items: state.workOrder.items,
        notes: state.workOrder.notes,
      };

      return {
        ...state,
        workOrder: updatedWorkOrder,
        dirty: true,
        active: isActive(action.payload),
        completed: isComplete(action.payload),
      };
    }
    case ACTIONS.CREATE_ITEM: {
      //
      //  Payload is the new work order item
      //
      state.workOrder.items = [
        ...(state.workOrder.items || []),
        action.payload,
      ];

      return { ...state, openItems: hasOpenItems(state.workOrder) };
    }
    case ACTIONS.UPDATE_ITEM: {
      //
      //  Payload is the updated work order item
      //
      state.workOrder.items = state.workOrder.items.map((item) =>
        item.id == action.payload.id ? action.payload : item
      );
      return { ...state, openItems: hasOpenItems(state.workOrder) };
    }
    case ACTIONS.DELETE_ITEM: {
      //
      //  Payload is the deleted work order item
      //
      state.workOrder.items = state.workOrder.items.filter(
        (item) => item.id != action.payload.id
      );
      return { ...state, openItems: hasOpenItems(state.workOrder) };
    }
    case ACTIONS.CREATE_NOTE: {
      //
      //  Payload is the new work order note
      //
      state.workOrder.notes = [
        ...(state.workOrder.notes || []),
        action.payload,
      ];
      return { ...state };
    }
    case ACTIONS.DELETE_NOTE: {
      //
      //  Payload is the deleted work order note
      //
      state.workOrder.notes = state.workOrder.notes.filter(
        (note) => note.id != action.payload.id
      );
      return { ...state, openItems: hasOpenItems(state.workOrder) };
    }
    default: {
      throw Error("Unknown reducer action: " + action.type);
    }
  }
};

export default () => {
  return [...useReducer(reducer, initialState), ACTIONS];
};
