/********************************************************************
 *
 * /components/lib/badge.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";

const Badge = ({ label, color = "error" }) => {
  const [colourClass, setColourClass] = useState(null);

  useEffect(() => {
    switch (color) {
      case "error":
        setColourClass({ color: "#ffffff", bgcolor: "#f83245" });
        break;
      case "warning":
        setColourClass({ color: "#ffffff", bgcolor: "#f4772e" });
        break;
      default:
        setColourClass({
          color: "#ffffff",
          bgcolor: "primary.main",
        });
    }
  }, [color]);

  return (
    <div
      sx={[
        {
          display: "inline-block",
          height: "20px",
          textTransform: "uppercase",
          padding: "2px 6px",
          fontSize: "10px",
          fontWeight: 700,
          textAlign: "center",
          whiteSpace: "nowrap",
          verticalAlign: "baseline",
          borderRadius: ".2rem",
          transition:
            "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out",
        },
        colourClass,
      ]}
    >
      {label}
    </div>
  );
};

export default Badge;
