/********************************************************************
 *
 * Groups.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { Box } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

/**
 * HorizontalGroup
 *
 * @param {*} param0
 */
const HorizontalGroup = ({ gap = 2, children }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      gap: gap,
    }}
  >
    {children}
  </Box>
);

/**
 * VerticalGroup
 *
 * @param {*} param0
 */
const VerticalGroup = ({ margin = "mb2", children }) => {
  const classes = useStyles();

  return (
    <Box
      className={`${classes[margin]}`}
      sx={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        "& > *": {
          mb: 2,
        },
      }}
    >
      {children}
    </Box>
  );
};

/*
 **  Styles
 */
const useStyles = makeStyles((theme) => ({
  ml1: {
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
  ml2: {
    "& > *": {
      marginLeft: theme.spacing(2),
    },
  },
  ml3: {
    "& > *": {
      marginLeft: theme.spacing(3),
    },
  },
  ml4: {
    "& > *": {
      marginLeft: theme.spacing(4),
    },
  },
  mr1: {
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
  mr2: {
    "& > *": {
      marginRight: theme.spacing(2),
    },
  },
  mr3: {
    "& > *": {
      marginRight: theme.spacing(3),
    },
  },
  mr4: {
    "& > *": {
      marginRight: theme.spacing(4),
    },
  },
  mb1: {
    "& > *": {
      marginBottom: theme.spacing(1),
    },
  },
  mb2: {
    "& > *": {
      marginBottom: theme.spacing(2),
    },
  },
  mb3: {
    "& > *": {
      marginBottom: theme.spacing(3),
    },
  },
  mb4: {
    "& > *": {
      marginBottom: theme.spacing(4),
    },
  },
}));

export { HorizontalGroup, VerticalGroup };
