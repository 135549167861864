/********************************************************************
 *
 * WorkOrdersReducer.jsx
 *
 * Implements state management for the Work Order page and
 * components.
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import { useReducer } from "react";
import { DateTime } from "luxon";

/////////////////////////////////////////////////////////////////////
//
//  OBJECT DEFINITIONS
//
/////////////////////////////////////////////////////////////////////

/**
 * ACTIONS
 *
 * Defines the set of valid actions on the reducer function
 *
 */
const ACTIONS = {
  HELLO: "hello",
  INITIALIZE_WORKORDERS: "initialize_workorders",
  CREATE_WORKORDER: "create_workorder",
  UPDATE_WORKORDER: "update_workorder",
};

/**
 * InitialState
 *
 * Defines the shape and initial values of the state object
 */
const initialState = {
  data: [],
  initialized: false,
  dirty: false,
  loaded: false,
};

/**
 * Reducer
 *
 * Manages actions on the state object
 *
 * @param {*} state
 * @param {*} action
 *
 * @returns
 */
const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.HELLO: {
      console.log("Hello World");
      return state;
    }
    case ACTIONS.INITIALIZE_WORKORDERS: {
      //
      //  Payload is an array of one or more work orders.
      //  Sort initial work orders by due date, severity and priority
      //
      !!action.payload &&
        action.payload.sort((a, b) => {
          const _dateA = !!a.due
            ? DateTime.fromISO(a.due).startOf("day").toUnixInteger()
            : DateTime.fromMillis(0).toUnixInteger();
          const _dateB = !!b.due
            ? DateTime.fromISO(b.due).startOf("day").toUnixInteger()
            : DateTime.fromMillis(0).toUnixInteger();

          if (_dateA === _dateB) {
            if (a.severity.id == b.severity.id) {
              return b.priority.id - a.priority.id;
            } else return b.severity.id - a.severity.id;
          }

          return _dateA < _dateB ? 1 : -1;
        });

      return {
        ...state,
        data: action.payload,
        initialized: true,
        loaded: true,
      };
    }
    case ACTIONS.CREATE_WORKORDER: {
      //
      //  Payload is the new work order
      //
      return {
        ...state,
        data: [...(state.data || []), action.payload],
        dirty: true,
      };
    }
    case ACTIONS.UPDATE_WORKORDER: {
      //
      //  Payload is the updated work order
      //
      return {
        ...state,
        data: state.data.map((workOrder) => {
          if (workOrder.id == action.payload.id) return action.payload;
          else return workOrder;
        }),
        dirty: true,
      };
    }
    default: {
      throw Error("Unknown reducer action: " + action.type);
    }
  }
};

export default () => {
  return [...useReducer(reducer, initialState), ACTIONS];
};
