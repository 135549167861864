/********************************************************************
 *
 * FeatureTypeTimeline.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import FeatureType from "../../ProductType/FeatureTypes/FeatureType";

const FeatureTypeTimeline = ({ featureTypes }) => (
  <Timeline sx={{ mb: 0 }}>
    {featureTypes &&
      featureTypes.map((featureType, index) => (
        <TimelineItem
          key={featureType.id}
          sx={{ minHeight: "20px", "&:before": { display: "none" } }}
        >
          <TimelineSeparator sx={{ "& .root": { paddingRight: "0px" } }}>
            <TimelineDot
              variant="outlined"
              color="primary"
              sx={{
                "& root": {
                  marginBottom: "4px",
                  marginTop: "4px",
                  padding: "2px",
                  borderWidth: "1px",
                },
              }}
            />
            {index < featureTypes.length - 1 && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent
            sx={{ "& root": { paddingTop: "0px", paddingBottom: "4px" } }}
          >
            <FeatureType featureType={featureType} />
          </TimelineContent>
        </TimelineItem>
      ))}
  </Timeline>
);

/**
 * PropTypes
 */
FeatureTypeTimeline.propTypes = {
  featureTypes: PropTypes.array.isRequired,
};

export default FeatureTypeTimeline;
