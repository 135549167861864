/********************************************************************
 *
 * CRM/Customers/UserExperience/Sessions.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Paper,
  Dialog,
} from "@mui/material";
import {
  useApp,
  useAPI,
  useAnalyticsAPI,
} from "../../../../providers/AppProvider";
import { SysClientName } from "../../../lib";
import SessionEvents from "../../../CRM/UserExperience/SessionEvents/index";

/**
 * Account
 *
 * Renders the customer's account.
 *
 */
const Sessions = ({ user }) => {
  const [sessions, setSessions] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [showEvents, setShowEvents] = useState(false);
  const [currentSession, setCurrentSession] = useState(null);
  const app = useApp();
  const api = useAnalyticsAPI();

  useEffect(() => {
    fetchSessions(user.id);
  }, [user]);

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * FetchSessions
   *
   * @param {*} userId
   */
  const fetchSessions = (userId) => {
    if (!userId) throw new Error("Parameter 'userId' cannot be null");

    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading Sessions");

    api
      .fetch(`/api/sessions/users/user/${userId}`)
      .then(({ payload: sessions }) => {
        setSessions(sessions);
        setLoaded(true);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setSessions(null);
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  if (!loaded)
    return <div className="spinner-border text-secondary" role="status" />;

  if (!sessions) return "No sessions available";

  return (
    <>
      <h5>Sessions</h5>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Events</TableCell>
              <TableCell>Client</TableCell>
              <TableCell>Expires</TableCell>
              <TableCell>Revoked</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sessions.map((session) => (
              <TableRow
                key={session.id}
                onClick={() => {
                  setCurrentSession(session);
                  setShowEvents(true);
                }}
              >
                <TableCell>{session.id}</TableCell>
                <TableCell>
                  {moment(session.created).format("MMM Do YYYY, h:mm:ss a")}
                </TableCell>
                <TableCell>{session.eventCount}</TableCell>
                <TableCell>
                  <SysClientName id={session.clientId} />
                </TableCell>
                <TableCell>
                  {!!session.expires
                    ? moment(session.expires).format("MMM Do YYYY, h:mm:ss a")
                    : "--"}
                </TableCell>
                <TableCell>{!!session.revoked ? "Revoked" : "--"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Events
        session={currentSession}
        open={showEvents}
        onClose={() => {
          setShowEvents(false);
        }}
      />
    </>
  );
};

const Events = ({ session, open, onClose }) => {
  return (
    <Dialog open={!!open} onClose={onClose}>
      <SessionEvents session={session} />
    </Dialog>
  );
};

Sessions.propTypes = {
  userId: PropTypes.number,
};

export default Sessions;
