/********************************************************************
 *
 * CRM/Customers/Communications.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router";
import format from "../../../../utils/format";
import moment from "moment-timezone";
import { useApp, useAPI } from "../../../../providers/AppProvider";

/**
 * Communications
 *
 * Displays a summary of communcations sent to the customer
 */
const Communications = () => {
  const [communications, setCommunications] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const { id } = useParams();
  let navigate = useNavigate();
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    fetchCommunications(id);
  }, [id]);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * FetchCommunications
   *
   * Calls the API to fetch the customer's communications
   *
   * @param {*} customerId
   */
  const fetchCommunications = (customerId) => {
    if (!customerId) throw new Error("Parameter 'customerId' required.");

    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading Communications");

    //
    //  Request searches
    //
    api
      .fetch(`/api/customers/customer/${customerId}/communications`)
      .then(({ payload: communications }) => {
        setCommunications(communications);
        setLoaded(true);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setCommunications(null);
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  if (!loaded)
    return <div className="spinner-border text-secondary" role="status" />;

  if (!communications) return "No comunication records";

  return (
    <div className="table-responsive small">
      <table className="table table-striped table-hover table-sm">
        <thead>
          <tr>
            <th>Time</th>
            <th>Description</th>
            <th>Subject</th>
            <th>Error</th>
          </tr>
        </thead>
        <tbody>
          {communications.map((item, index) => (
            <tr
              key={index}
              className="pointer"
              onClick={() => {
                navigate(`/app/crm/customer/${id}/communication/${item.id}`);
              }}
            >
              <td>{format.shortDateTime(moment(item.timestamp))}</td>
              <td>{item.description}</td>
              <td>{item.subject}</td>
              <td>{item.error}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Communications;
