/********************************************************************
 *
 * Index.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import { Typography } from "@mui/material";
import React from "react";
import { useOutletContext } from "react-router";

/**
 * RateClassIdentity
 *
 * Renders a summary of the rate class
 *
 * @param {*} param0
 */
const Index = () => {
  const { rateClass } = useOutletContext();

  if (!rateClass) return "";

  return <Typography>{rateClass.name}</Typography>;
};

export default Index;
