/********************************************************************
 *
 * Index.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { DirectionsBoat } from "@mui/icons-material";
import { Main, CardList, LinkCard2, CardAction } from "../../lib";
import Pagination from "../../lib/Pagination";
import { AssetSummaryHeader, AssetSummary } from "./AssetSummary";
import { useApp, useAPI } from "../../../providers/AppProvider";

const PAGE_SIZE = 15;

/**
 * Assets
 *
 * TODO: Complete asset management
 */
const Assets = () => {
  const [assets, setAssets] = useState(null);
  const [assetCount, setAssetCount] = useState(0);
  const [currPage, setCurrPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    fetchAssets();
  }, []);

  ///////////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////////

  /**
   * FetchAssets
   *
   */
  const fetchAssets = () => {
    api
      .fetch(`/api/assets/`)
      .then(({ payload: assets, max: count }) => {
        setAssets(assets);
        setAssetCount(count);
        setLoaded(true);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////////

  const onTurnPage = (e) => {
    setCurrPage(e.currentPage);
    setPageSize(e.pageSize);
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Main
      header={{
        icon: <DirectionsBoat />,
        title: "Assets",
        bonus: (
          <Button variant="outlined" color="primary" href="/app/admin/asset">
            Create a new asset
          </Button>
        ),
      }}
      loaded={loaded}
    >
      <>
        <CardList
          xs={4}
          md={4}
          spacing={2}
          items={assets}
          onRenderCard={({ item: asset }) => (
            <LinkCard2
              link={`/app/admin/asset/${asset.id}`}
              header={<AssetSummaryHeader asset={asset} />}
              content={<AssetSummary asset={asset} />}
              actions={<CardAction to="#" label="Delete" />}
            />
          )}
        />
        <br />
        <Pagination
          className={"small"}
          currPage={currPage}
          totalRecords={assetCount}
          pageSize={pageSize}
          pageNeighbours={1}
          onPageChanged={onTurnPage}
        />
      </>
    </Main>
  );
};

export default Assets;
