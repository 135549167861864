/********************************************************************
 *
 * CardList.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import { Grid2 as Grid } from "@mui/material";

/**
 * CardList
 *
 * Renders a list of employee summaries in card format
 *
 * @param {*} param0
 */
const CardList = ({
  xs = 1,
  sm = 2,
  md = 3,
  lg = 4,
  xl = 6,
  items,
  spacing = 2,
  deleted,
  onRenderCard,
}) => {
  if (!items) return null;

  return (
    <Grid
      container
      spacing={spacing}
      sx={{
        "& > *": {
          marginBottom: 2,
        },
      }}
    >
      {items.map((item, index) =>
        deleted && deleted(item) ? (
          ""
        ) : (
          <Grid
            key={index}
            size={{
              xs: xs,
              sm: sm,
              md: md,
              lg: lg,
              xl: xl,
            }}
          >
            {onRenderCard && onRenderCard({ item })}
          </Grid>
        )
      )}
    </Grid>
  );
};

/*
 **  PropTypes
 */
CardList.propTypes = {
  items: PropTypes.array,
  onRenderHeader: PropTypes.func,
  onRenderLink: PropTypes.func,
  onRenderContent: PropTypes.func,
  onRenderActions: PropTypes.func,
};

export default CardList;
