/********************************************************************
 *
 * ChargeSummary.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import { Box, IconButton, Typography } from "@mui/material";
import { Delete } from "@mui/icons-material";
import format from "../../../../../utils/format";
import ProductTimeDisplay from "./ProductTimeDisplay";

/**
 * ChargeSumary
 *
 * @param {*} param0
 * @returns
 */
const ChargeSummary = ({ charge, onRemoveDiscount }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex" }}>
          <Typography variant="largeChar">{charge.qty}</Typography>
          <Typography variant="smallChar" sx={{ ml: 1 }}>
            @ {format.currency(charge.price)}
          </Typography>
        </Box>
        <Box>
          {charge.product && (
            <ProductTimeDisplay
              start={charge.product.start}
              end={charge.product.end}
            />
          )}
        </Box>
      </Box>
      <Box>
        {charge.rateClass.policies.map((policy) => (
          <Box key={policy.id} sx={{ mb: 2 }}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              {policy.name}
            </Typography>
            <Typography variant="body3">{policy.description}</Typography>
          </Box>
        ))}
      </Box>
      {charge.discounts && charge.discounts.items && (
        <>
          <hr />
          <div className="h5 mb-4">Discounts</div>
          {charge.discounts.items.map((discount, index) => (
            <React.Fragment key={discount.id}>
              <div
                className={`flexRowStretch ${index > 0 ? "mt-3" : ""}`}
                style={{ alignItems: "baseline" }}
              >
                <div className="h6 m-0">{discount.discountType.name}</div>
                <div className="h6 m-0">
                  {format.currency(discount.amount)}{" "}
                  <IconButton
                    onClick={(e) => onRemoveDiscount({ discount })}
                    size="large"
                  >
                    <Delete fontSize="small" />
                  </IconButton>
                </div>
              </div>
              <div
                className="flexRowStretch"
                style={{ alignItems: "baseline" }}
              >
                <div className="small">{discount.description}</div>
              </div>
            </React.Fragment>
          ))}
        </>
      )}
    </Box>
  );
};

/**
 * PropTypes
 *
 */
ChargeSummary.propTypes = {
  charge: PropTypes.object.isRequired,
  onRemoveDiscount: PropTypes.func.isRequired,
};

export default ChargeSummary;
