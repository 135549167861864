/********************************************************************
 *
 * Charge.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import format from "../../../../../utils/format";

/**
 * ProductTimeDisplay
 *
 * @param {*} param0
 * @returns
 */
const ProductTimeDisplay = ({ start, end }) => {
  return (
    <Box sx={{ display: "flex" }}>
      <Typography variant="largeChar">{moment(start).format("D")}</Typography>
      <Box sx={{ ml: 2 }}>
        <Typography
          variant="smallChar"
          sx={{ fontWeight: 600 }}
          component="div"
        >
          {format.shortMonthYear(start)}
        </Typography>
        <Typography variant="smallChar" component="div">
          {format.shortDay(start)}
        </Typography>
      </Box>
      <Box sx={{ ml: 2 }}>
        <Typography
          variant="smallChar"
          sx={{ fontWeight: 600 }}
          component="div"
        >
          {format.time(start)}
        </Typography>
        <Typography
          variant="smallChar"
          sx={{ fontWeight: 600 }}
          component="div"
        >
          {format.time(end)}
        </Typography>
      </Box>
    </Box>
  );
};

ProductTimeDisplay.propTypes = {
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
};

export default ProductTimeDisplay;
