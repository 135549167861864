/********************************************************************
 *
 * RateClassRates.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { NavLink } from "react-router";
import format from "../../../utils/format";
import { Edit, Add } from "@mui/icons-material";
import RateCalendar from "./RateCalendar";
import { useApp, useAPI } from "../../../providers/AppProvider";

/**
 * RateClassRates
 *
 * Renders the rates for a rate type.
 *
 * @param {*} param0
 */
const RateClassRates = ({
  rateClass,
  productType,
  readonly,
  highlightRate,
}) => {
  const [rates, setRates] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    fetchRates(rateClass);
  }, [rateClass]);

  /**
   * ParseRates
   *
   * Iterate through the rate collection and build a tree with product
   * types as the first branch with corresponding rates as leafs
   *
   * @param {*} rates
   */
  const parseRates = (rates) => {
    let tree = {};

    if (!rates) return null;

    rates.forEach((rate) => {
      if (undefined === tree[rate.productType.id]) {
        tree[rate.productType.id] = {
          productType: rate.productType,
          rates: [],
        };
      }

      tree[rate.productType.id].rates.push(rate);
    });

    return Object.values(tree);
  };

  /**
   * FetchRates
   *
   * @param {*} rateClass
   */
  const fetchRates = (rateClass) => {
    api
      .fetch(`/api/rateclasses/rateclass/${rateClass.id}/rates`)
      .then(({ payload: rates }) => {
        setRates(parseRates(rates));
        setLoaded(true);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  if (!rates) return "Loading Rates...";

  return (
    <>
      {rates ? (
        <div className="table-responsive small">
          {rates.map((node, index) =>
            !productType || node.productType.id === productType.id ? (
              <div key={index}>
                <span className="container">
                  <NavLink
                    className={`btn btn-link float-right`}
                    to={`/app/catalog/rate/edit?pt=${node.productType.id}&rc=${rateClass.id}`}
                  >
                    <Add />
                  </NavLink>
                  <img
                    className="img-fluid float-left d-none d-md-block m-2"
                    style={{ height: "50px" }}
                    src={node.productType.imageURL}
                    alt={node.productType.name}
                  />
                  <h4 className="align-bottom">{node.productType.name}</h4>
                </span>
                <RateCalendar />
                <table className="table table-striped table-sm">
                  <thead>
                    <tr>
                      <th>Rate</th>
                      <th>Applied</th>
                      <th>Open</th>
                      <th>Account</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {node.rates.map((rate) => (
                      <tr
                        key={rate.id}
                        className={`${
                          highlightRate && highlightRate.id === rate.id
                            ? "table-info"
                            : ""
                        }`}
                      >
                        <td>{format.currency(rate.amount)}</td>
                        <td>{`${format.date(rate.start)} - ${format.date(
                          rate.end
                        )}`}</td>
                        <td>{`${format.date(rate.open)} - ${format.date(
                          rate.close
                        )}`}</td>
                        <td>{rate.account.name}</td>
                        <td>
                          <NavLink
                            className={`btn btn-link float-right py-0 ${
                              readonly ? "d-none" : ""
                            }`}
                            to={`/app/catalog/rate/edit/${rate.id}?pt=${node.productType.id}&rc=${rateClass.id}`}
                          >
                            <Edit />
                          </NavLink>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              ""
            )
          )}
        </div>
      ) : (
        "No Rates Available"
      )}
    </>
  );
};

export default RateClassRates;
