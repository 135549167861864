/********************************************************************
 *
 * SupplierScheduler.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState } from "react";
import { useParams, useOutletContext } from "react-router";
import { Box } from "@mui/material";
import moment from "moment";
import Calendar from "../../lib/Calendar/Calendar";
import Schedule from "../../lib/Schedule";
import format from "../../../utils/format";
import Alerts from "../libs/Alerts";
import ModalDialog from "../../lib/ModalDialog";
import GoodMiniEdit from "../libs/GoodMiniEdit";
import "../assets/scheduler.css";
import { useApp, useAPI } from "../../../providers/AppProvider";

const DEFAULT_QUANTITY = -1;

/**
 * SupplierScheduler
 *
 * Renders the schedule management component for a supplier type.
 *
 * The interface allows the user to navigate a schedule of thrid
 * party goods.
 *
 * Using the calendar, a user can select a date and the scheduled
 * goods appear in the agenda. Goods can be added or editted by
 * clicing in the agenda.
 *
 * When a good is selected, or a new good is created, a dialog
 * appears with the summary data. The user can manipulate the data
 * in the dialog, or dive into more detailed information in the
 * Suppplier Good page.
 *
 * All dates and times are relative to the good's timezone.
 */
const SupplierScheduler = () => {
  const [calendar, setCalendar] = useState(null);
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [goods, setGoods] = useState(null);
  const [currentGood, setCurrentGood] = useState(null);
  const [tz] = useState("America/Vancouver");
  const { goodType } = useOutletContext();
  const app = useApp();
  const api = useAPI();

  const { goodTypeId } = useParams();

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * FetchGoods
   *
   * Calls the api to fetch the third party goods for the visible dates
   * on the calendar.
   *
   * @param {*} goodTypeId
   * @param {*} start
   * @param {*} end
   */
  const fetchGoods = (goodTypeId, start, end) => {
    let _start, _end;

    return new Promise((resolve, reject) => {
      //
      //  Validate parameters
      //
      if (!start) throw new Error("Start date not defined");
      if (!end) throw new Error("End date not defined");

      //
      //  Request data for the timezone-adjusted start of day
      //
      _start = format.getMomentLocalized(start, tz).startOf("day");
      _end = format.getMomentLocalized(end, tz).endOf("day");

      if (!_start.isValid()) throw new Error("Invalid start date format");
      if (!_end.isValid()) throw new Error("Invalid end date format");

      //
      //  Request product types
      //  TODO: Fetch by the current good type
      //
      api
        .fetch(
          `/api/suppliergoodtypes/suppliergoodtype/${goodTypeId}/daterange/${format.toApiDateTime(
            _start
          )}/${format.toApiDateTime(moment(_end).add(1, "day"))}`
        )
        .then((envelope) => {
          resolve(envelope.payload);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * CreateGood
   *
   * Creates a new good from a given start date/time.
   *
   * Called when the user clicks on a timeslot. A good is created
   * based upon the start date/tim and default data.
   *
   * @param {*} startDateTime
   * @param {*} tz
   */
  const createGood = (goodTypeId, startDateTime, tz) => {
    let start, end;

    start = moment.tz(startDateTime, tz);
    end = moment(start).add(180, "minutes");

    //
    //  TODO:Add supplier, goodType
    //
    return {
      goodTypeId: goodTypeId,
      start: start.toISOString(),
      displayStart: start.toISOString(),
      end: end.toISOString(),
      displayEnd: end.toISOString(),
      tz: tz,
      quantity: DEFAULT_QUANTITY,
    };
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////
  //
  //  Calendar Component
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnCalendarUpdate
   *
   * Fired when the dates on the calendar change.
   *
   * Fetches goods for the dates visible on the calendar.
   *
   * @param {*} event
   */
  const onCalendarUpdate = (event) => {
    setCalendar(event);

    if (event && event.startDate && event.endDate) {
      //
      //  Status message
      //
      const notifyHandle = app.notify("Updating Goods");

      fetchGoods(goodTypeId, event.startDate, event.endDate)
        .then((goods) => {
          setGoods(goods);
          app.endNotify(notifyHandle);
        })
        .catch((error) => {
          app.error({ error });
          setGoods(null);
        });
    }
  };

  /**
   * OnDateRender
   *
   * Fired when a calendar date renders.
   *
   * Determines if there are goods on the specified date and
   * marks up the calendar accordingly.
   *
   * @param {*} event
   */
  const onDateRender = (event) => {
    let daysGoods = [];

    if (goods) {
      goods.forEach((good) => {
        if (
          moment
            .tz(good.start, good.tz)
            .isSame(moment.tz(event.date, good.tz), "day")
        )
          daysGoods.push(good);
      });
    }

    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          backgroundColor: daysGoods.length > 0 ? "rgba(39,159,159, 0.2)" : "",
        }}
      >
        <div>{format.mday(event.date)}</div>
        <div style={{ padding: "0.2em", fontSize: "0.8em" }}>
          {daysGoods.map((good, index) => (
            <div key={index}>{`${format.time(good.start, good.tz)} ${
              good.sold
            }/${parseInt(good.available) !== -1 ? good.available : "FS"}`}</div>
          ))}
        </div>
      </div>
    );
  };

  /**
   * OnSelectedDateChanged
   *
   * Fired when the user clicks a date cell in the calendar.
   *
   * @param {event} event
   */
  const onSelectedDateChanged = (event) => {
    setSelectedDate(event.date);
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Schedule Component
  //
  ///////////////////////////////////////////////////////////////////

  /**
   *  OnHeaderRender
   *
   *  Renders alerts for the day. Alerts are stored as all-day events
   *  in the Canadian Coastal Google calendar.
   *
   * @param {*} event
   */
  const onHeaderRender = (event) => {
    return <Alerts date={selectedDate} />;
  };

  /**
   * OnTimeSlotRender
   *
   * Fired by the Schedule component when a time slot is being
   * rendered.
   *
   * @param {*} event
   */
  const onTimeSlotRender = (event) => {
    return (
      <div className="timeSlotContainer" onClick={() => onClickTimeSlot(event)}>
        {goods &&
          goods.map((good) =>
            moment.tz(good.start, good.tz).isSame(event.dateTime, "day") &&
            moment.tz(good.start, good.tz).hour() === event.dateTime.hour() ? (
              <div
                key={good.id}
                className="timeSlotTour"
                onClick={(event) => {
                  onClickGood({ good: good });
                  event.stopPropagation();
                }}
              >
                <div className="time">
                  {`${format.time(good.start, good.tz)} - ${format.time(
                    good.end,
                    good.tz
                  )}`}
                </div>
                <div className="details">
                  <div>{`${
                    parseInt(good.available) !== -1
                      ? good.available
                      : "Free Sell"
                  }`}</div>
                </div>
              </div>
            ) : (
              ""
            )
          )}
      </div>
    );
  };

  /**
   * OnClickTimeSlot
   *
   * Fired when a user clicks a timeslot in the schedule. Creates
   * the framework of a good.
   *
   * @param {*} event
   */
  const onClickTimeSlot = (event) => {
    setCurrentGood(createGood(goodTypeId, event.dateTime, event.tz));
  };

  /**
   * OnClickGood
   *
   * @param {*} event
   */
  const onClickGood = (event) => {
    setCurrentGood(event.good);
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Good Dialog Component
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnDialogClose
   *
   * Called as a callback from the GoodMiniEdit component.
   *
   * Nullifies the currentGood (which closes the dialog), and
   * refreshes the goods. The refresh will display any new good added
   * by the dialog.
   *
   * @param {*} e
   */
  const onDialogClose = (e) => {
    setCurrentGood(null);

    if (calendar && calendar.startDate && calendar.endDate) {
      //
      //  Status message
      //
      const notifyHandle = app.notify("Updating Goods");

      fetchGoods(goodTypeId, calendar.startDate, calendar.endDate)
        .then((goods) => {
          setGoods(goods);
          app.endNotify(notifyHandle);
        })
        .catch((error) => {
          app.error({ error });
        });
    }
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        flexWrap="noWrap"
        className="pt-3"
      >
        <div style={{ fontSize: "12px" }}>
          <Calendar
            date={undefined}
            width={400}
            onCalendarUpdating={onCalendarUpdate}
            onDateRender={onDateRender}
            onSelectedDateChanged={onSelectedDateChanged}
          />
        </div>
        <div className="flex-fill ml-5">
          <Schedule
            date={selectedDate}
            tz={tz}
            onHeaderRender={onHeaderRender}
            onTimeSlotRender={onTimeSlotRender}
          />
        </div>
      </Box>
      {!!currentGood && (
        <ModalDialog show={!!currentGood}>
          <GoodMiniEdit
            goodType={goodType}
            good={currentGood}
            onSaved={onDialogClose}
            onCancelled={onDialogClose}
          />
        </ModalDialog>
      )}
    </>
  );
};

export default SupplierScheduler;
