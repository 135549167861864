/********************************************************************
 *
 * /Catalog/Products/Features/Identity/Header.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { Box, Avatar, Chip, Typography } from "@mui/material";
import { PersonAddDisabled, ErrorOutline } from "@mui/icons-material";
import { TipChip, CardContentHeader, HorizontalGroup } from "../../../../lib";

/**
 * Header
 *
 * Renders a feature type.
 *
 */
const Header = ({ featureType }) => {
  const goodTypeClassPicker = [
    { backgroundColor: "green", fontSize: "0.7em" },
    { backgroundColor: "blue", fontSize: "0.7em" },
    { backgroundColor: "purple", fontSize: "0.7em" },
  ];

  return (
    <CardContentHeader
      avatar={
        !featureType.goodType ? (
          <Avatar>
            <ErrorOutline />
          </Avatar>
        ) : (
          <Avatar sx={[goodTypeClassPicker[featureType.goodType.source.id]]}>
            {featureType.goodType.source.name}
          </Avatar>
        )
      }
      content={
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          style={{ height: "100%", width: "100%" }}
        >
          <Box flexGrow={1}>
            <Typography variant="h4">{featureType.name}</Typography>
            <Typography variant="h5">
              by {featureType.goodType.supplier.name}
            </Typography>
          </Box>
          <HorizontalGroup gap={1}>
            {!!featureType.deleted && <Chip label="Deleted" />}
            {!featureType.shared && (
              <TipChip
                tip={"Private use"}
                label={<PersonAddDisabled fontSize="small" />}
                sx={{
                  color: "darkRed",
                  bgcolor: "transparent",
                }}
              />
            )}
            {!featureType.goodType ? (
              <Chip
                size="small"
                label={"Feature is empty"}
                sx={{ backgroundColor: "red", color: "white" }}
              />
            ) : (
              <TipChip
                tip="Number of goods included per product"
                size="small"
                color="primary"
                variant="default"
                label={`Includes ${featureType.quantity} ${featureType.goodType.name}`}
              />
            )}
          </HorizontalGroup>
        </Box>
      }
    />
  );
};

export default Header;
