/********************************************************************
 *
 * TourStatus.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import { MenuItem, TextField } from "@mui/material";
import { useApp, useAPI } from "../../../providers/AppProvider";

/**
 * TourStatus
 *
 * Renders the status of a tour
 *
 */
const TourStatus = ({ tour, onTourUpdated }) => {
  const app = useApp();
  const api = useAPI();

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  /////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * OnChangeStatus
   *
   * @param {*} event
   */
  const onChangeStatus = (event) => {
    let statusId = event.target.value;

    event.preventDefault();

    const notifyHandle = app.notify("Updating Tour Status");

    api
      .update(`/api/tours/tour/${tour.id}/status/`, {
        status: statusId,
      })
      .then((envelope) => {
        onTourUpdated && onTourUpdated({ tour: envelope.payload });
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle Methods
  //
  ///////////////////////////////////////////////////////////////////////

  if (!tour || !tour.id) return "";

  return (
    <TextField
      name="status.id"
      select
      variant="standard"
      value={tour.status.id}
      onChange={onChangeStatus}
    >
      <MenuItem value="0">Open</MenuItem>
      <MenuItem value="1">Departed</MenuItem>
      <MenuItem value="2">Closed</MenuItem>
      <MenuItem value="3">Cancelled</MenuItem>
    </TextField>
  );
};

TourStatus.propTypes = {
  tour: PropTypes.object,
  onTourUpdated: PropTypes.func.isRequired,
};

export default TourStatus;
