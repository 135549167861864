/********************************************************************
 *
 * /Sales/Orders/Abandoned.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Link } from "react-router";
import { Box, Button } from "@mui/material";
import { Edit, Receipt } from "@mui/icons-material";
import Pagination from "../../lib/Pagination";
import LiveSearch from "../../lib/LiveSearch";
import format from "../../../utils/format";
import { useApp, useAPI } from "../../../providers/AppProvider";

const PAGE_SIZE = 15;

/**
 * Abandoned
 *
 * Renders a searchable paged list of Orders.
 */
const Abandoned = ({ setTitle }) => {
  const [orders, setOrders] = useState(null);
  const [orderCount, setOrderCount] = useState(0);
  const [currPage, setCurrPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [match, setMatch] = useState("");
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    setTitle("Abandoned Orders");
  }, []);

  useEffect(() => {
    fetchAbandonedOrders(currPage, pageSize, match);
  }, [currPage, pageSize, match]);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * fetchAbandonedOrders
   *
   * Calls the api to fetch abandoned orders.
   *
   * @param {*} currPage
   * @param {*} pageSize
   * @param {*} match
   */
  const fetchAbandonedOrders = (currPage, pageSize, match) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading");

    api
      .fetch(
        `/api/orders/abandoned?match=${match}&page=${currPage}&limit=${pageSize}&verbose`
      )
      .then(({ payload: orders, max: count }) => {
        setOrders(orders);
        setOrderCount(count);
        setLoaded(true);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////////

  /**
   * onSearch
   *
   * Fired when the user enters a search phrase.
   *
   * @param {*} e
   */
  const onSearch = ({ match }) => {
    setCurrPage(1);
    setMatch(match);
  };

  /**
   * Fired when the user changes the current page.
   *
   * @param {*} e
   */
  const onTurnPage = ({ currPage }) => {
    setCurrPage(currPage);
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Box>
      {loaded && !orders
        ? "No Orders Available"
        : loaded && (
            <>
              <Box
                display="flex"
                alignItems="baseLine"
                justifyContent="space-between"
                flexWrap="wrap"
                mb={2}
              >
                <LiveSearch match={match} onSearch={onSearch} />
                <Pagination
                  currPage={currPage}
                  totalRecords={orderCount}
                  pageSize={pageSize}
                  pageNeighbours={1}
                  onPageChanged={onTurnPage}
                />
              </Box>
              <PaginatedTable orders={orders} />
            </>
          )}
    </Box>
  );
};

///////////////////////////////////////////////////////////////////////
//
//  Functional Components
//
///////////////////////////////////////////////////////////////////////

/**
 * PaginatedTable
 *
 * @param {*} param0
 */
const PaginatedTable = ({ orders }) => {
  if (!orders) return <div className="h4">No Orders Available</div>;

  return (
    <div className="table-responsive small">
      <table className="table table-striped table-sm">
        <thead>
          <tr>
            <th>Order</th>
            <th>Created</th>
            <th>Amount</th>
            <th>UID</th>
            <th>Name</th>
            <th>Account</th>
            <th>Comments</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {orders.map(
            (order) =>
              !!order && (
                <tr
                  key={order.id}
                  style={
                    order.deleted ? { textDecoration: "line-through" } : null
                  }
                  className={order.deleted ? "text-muted" : null}
                >
                  <td>
                    <Link
                      className={order.deleted ? "text-muted" : null}
                      to={`/app/sales/order/${order.id}`}
                      target="__blank"
                    >
                      {order.id}
                    </Link>
                  </td>
                  <td>{format.date(order.timestamp)}</td>
                  <td>
                    {!!order.charges
                      ? format.currency(order.charges.total)
                      : "--"}
                  </td>
                  <td>
                    {!order.user
                      ? "--"
                      : order.user.fname && order.user.lname
                      ? `${order.user.fname} ${order.user.lname}`
                      : order.user.id}
                  </td>
                  <td>
                    <Link
                      className={order.deleted ? "text-muted" : null}
                      to={`/app/sales/order/${order.id}`}
                      target="__blank"
                    >
                      {!!order.name ? order.name : "--"}
                    </Link>
                  </td>
                  <td>
                    {!!order.customer ? (
                      <Link
                        className={order.deleted ? "text-muted" : null}
                        to={`/app/crm/customer/${order.customer.id}`}
                        target="__blank"
                      >
                        {order.customer.fname} {order.customer.lname}
                      </Link>
                    ) : (
                      "Unknown"
                    )}
                  </td>
                  <td className="text">
                    <span>{order.message}</span>
                  </td>
                  <td>
                    <Link to={`/app/sales/order/${order.id}`} target="__blank">
                      <Edit fontSize="small" />
                    </Link>
                  </td>
                </tr>
              )
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Abandoned;
