/********************************************************************
 *
 * /lib/Schedule.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import moment from "moment-timezone";
import format from "../../utils/format";

/**
 * Schedule
 *
 * Renders an event schedule
 *
 */
const Schedule = ({
  date,
  tz,
  height = 400,
  onHeaderRender,
  onTimeSlotRender,
}) => {
  const classes = useStyles();

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  /////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle Methods
  //
  ///////////////////////////////////////////////////////////////////////

  const containerRef = useRef(null);
  useEffect(() => {
    containerRef.current.scroll({
      top: 480,
      left: 0,
      behavior: "smooth",
    });
  });

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="no-wrap"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Typography>GMT{format.timezone(tz)}</Typography>
        {onHeaderRender && <Box>{onHeaderRender()}</Box>}
      </Box>
      <Box
        className={classes.gridContainer}
        style={{
          height: height,
        }}
        ref={containerRef}
      >
        <TimeGrid
          date={date}
          height={height}
          onTimeSlotRender={onTimeSlotRender}
          tz={tz}
        />
      </Box>
    </>
  );
};

/**
 * TimeGrid
 *
 * @param {Array} props
 */
const TimeGrid = ({ date, tz, onTimeSlotRender }) => {
  const classes = useStyles();
  const hours = [...Array(24).keys()];
  const _date = moment.tz(date, tz).startOf("day");

  return (
    <Box display="flex">
      {/* Index */}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        flexBasis="auto"
        flexGrow="0"
        flexShrink="0"
        className={classes.scheduleIndex}
      >
        <Box className={classes.scheduleIndexCol}>
          {hours.map((hour) => (
            <Box key={hour} className={classes.scheduleIndexItem}>
              {hour !== 0 ? `${hour}:00` : ""}
            </Box>
          ))}
        </Box>
      </Box>
      {/* Agenda */}
      <Box
        display="flex"
        alignItems="flex-start"
        flexBasis="auto"
        flexGrow="1"
        flexShrink="1"
        className={classes.agenda}
      >
        {/* Grid */}
        <div
          style={{
            display: "block",
            flexBasis: "auto",
            flexGrow: 1,
            flexShrink: 0,
            minWidth: 129,
            boxSizing: "border-box",
            overflowX: "visible",
            overflowY: "visible",
            paddingRight: "12px",
            position: "relative",
          }}
        >
          <div
            style={{
              display: "block",
              boxSizing: "border-box",
              position: "relative",
            }}
          >
            {hours.map((hour) => (
              <div
                key={hour}
                style={{
                  display: "block",
                  height: "48px",
                  paddingRight: "8px",
                  position: "relative",
                  borderTop: "solid 1px #eeeeee",
                  backgroundColor:
                    hour >= 9 && hour < 17 ? "#ffffff" : "#f8f8f8",
                }}
              >
                {onTimeSlotRender &&
                  onTimeSlotRender({
                    dateTime: moment(_date).add(hour, "hour"),
                    tz: tz,
                  })}
              </div>
            ))}
          </div>
        </div>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    overflowY: "scroll",
  },
  scheduleIndex: {
    minWidth: "50px",
    overflowY: "hidden",
    fontSize: "60%",
    fontWeight: "400",
  },
  scheduleIndexCol: {
    display: "block",
    boxSizing: "border-box",
    position: "relative",
  },
  scheduleIndexItem: {
    display: "block",
    height: "48px",
    paddingLeft: "20px",
    position: "relative",
    textAlign: "right",
    top: "-6px",
  },
  agenda: {
    overflowX: "auto",
    overflowY: "hidden",
    fontSize: "10px",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 400,
  },
}));

export default Schedule;
