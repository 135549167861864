/********************************************************************
 *
 * MenuItems.jsx
 *
 * Standard header that contains branding, nav and authentication
 * credentials for the application.
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 ********************************************************************/

/**
 *
 * Navigation tree
 *
 */
const menuItems = [
  {
    label: "sample",
    hide: "true",
    path: "/path/",
    children: [{ label: "submenu sample", hide: "true", path: "/subpath/" }],
  },
  {
    label: "Catalog",
    children: [
      { label: "Products", path: "/app/catalog" },
      { label: "Rate Classes", path: "/app/catalog/rateclasses/" },
      { label: "Goods", path: "/app/goods" },
      { label: "Inventory", path: "/app/goods/inventory/" },
      { label: "Reports", path: "/app/catalog/reports/products/" },
    ],
  },
  {
    label: "CRM",
    children: [
      {
        label: "Customers",
        path: "/app/crm/customers",
      },
      {
        label: "Reviews",
        path: "/app/crm/reviews",
      },
    ],
  },
  {
    label: "Sales",
    children: [
      { label: "Orders", path: "/app/sales/" },
      { label: "Partners", path: "/app/sales/partners/" },
      { label: "Discounts", path: "/app/sales/discounts/" },
      { label: "Campaigns", path: "/app/sales/campaigns/" },
    ],
  },
  {
    label: "Reports",
    path: "/app/reports/",
  },
  {
    label: "Admin",
    children: [
      { label: "Staff", path: "/app/admin/employees/" },
      { label: "Tickets", path: "/app/admin/tickets/" },
      { label: "Work Orders", path: "/app/admin/workorders/" },
      { label: "Assets", path: "/app/admin/assets/" },
      { label: "Settings", path: "/app/admin/config/" },
      { label: "Tools", path: "/app/admin/tools/" },
    ],
  },
];

export default menuItems;
