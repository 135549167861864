/********************************************************************
 *
 * Partners.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { Favorite } from "@mui/icons-material";
import { Main, CardList, LinkCard2, CardAction } from "../../lib";
import Pagination from "../../lib/Pagination";
import PartnerSummary from "./PartnerSummary";
import { useApp, useAPI } from "../../../providers/AppProvider";

const PAGE_SIZE = 15;

/**
 * Partners
 *
 * Displays partners
 *
 */
const Partners = () => {
  const [partners, setPartners] = useState(null);
  const [itemCount, setItemCount] = useState(null);
  const [currPage, setCurrPage] = useState(1);
  const [pageSize] = useState(PAGE_SIZE);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    fetchPartners(currPage, pageSize);
  }, [currPage, pageSize]);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * FetchPartners
   *
   * Calls the api to fetch partners
   *
   * @param {*} currPage
   * @param {*} pageSize
   */
  const fetchPartners = (currPage, pageSize) => {
    //
    //  Request partners
    //
    api
      .fetch(`/api/partners/?page=${currPage}&limit=${pageSize}`)
      .then(({ payload: partners, max: count }) => {
        setPartners(partners);
        setItemCount(count);
        setLoaded(true);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////////

  const handleTurnPage = (e) => {
    setCurrPage(e.currPage);
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Main
      header={{
        icon: <Favorite />,
        title: `Partners for ${process.env.REACT_APP_ORG_NAME}`,
        bonus: (
          <Button variant="outline-cc" href="/app/sales/partner">
            Create a new partner
          </Button>
        ),
      }}
      loaded={loaded}
    >
      {partners && (
        <>
          <CardList
            xs={12}
            md={4}
            items={partners}
            spacing={4}
            onRenderCard={({ item: partner }) => (
              <PartnerCard partner={partner} />
            )}
          />

          <Pagination
            className={"small"}
            currPage={currPage}
            totalRecords={itemCount}
            pageSize={pageSize}
            pageNeighbours={1}
            onPageChanged={handleTurnPage}
          />
        </>
      )}
    </Main>
  );
};

/**
 * PartnerCard
 *
 * Renders a partner summary in card format
 *
 * @param {*} param0
 */
const PartnerCard = ({ partner }) => (
  <LinkCard2
    link={`/app/sales/partner/${partner.id}`}
    content={<PartnerSummary partner={partner} />}
    actions={
      <>
        <CardAction href="#">Rates</CardAction>
        <CardAction href="#">Delete</CardAction>
      </>
    }
  />
);

export default Partners;
