/********************************************************************
 *
 * FormComponent.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

/**
 * FormComponent
 *
 * @param {*} param0
 * @returns
 */
const FormComponent = ({ start = 1, span = 12, children }) => (
  <Box sx={{ gridColumn: { xs: `${start}/span ${span}` } }}>{children}</Box>
);

/**
 * PropTypes
 */
FormComponent.propTypes = {
  start: PropTypes.number,
  span: PropTypes.number,
  children: PropTypes.node,
};

export default FormComponent;
