/********************************************************************
 *
 * CardContentHeader.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import { Box, Badge } from "@mui/material";

const CardContentHeader = ({ avatar, callout, content }) => (
  <Box
    display="flex"
    alignItems="flex-start"
    wrap="nowrap"
    sx={{ marginBottom: 2 }}
  >
    {!!avatar && (
      <Box
        sx={{
          marginRight: 2,
        }}
      >
        {avatar}
      </Box>
    )}
    <Box sx={{ flexGrow: 1 }}>
      <Badge
        badgeContent={callout ? callout.label : undefined}
        color={callout ? callout.color : undefined}
        anchorOrigin={
          callout && callout.anchor
            ? callout.anchor
            : { horizontal: "right", vertical: "top" }
        }
        sx={{ width: "100%" }}
      >
        {content}
      </Badge>
    </Box>
  </Box>
);

/*
 **  PropTypes
 */
CardContentHeader.propTypes = {
  avatar: PropTypes.node,
  callout: PropTypes.object,
  content: PropTypes.node,
};

export default CardContentHeader;
