/********************************************************************
 *
 * ExpansionCard.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Divider,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

/**
 * ExpansionCard
 *
 * @param {*} param0
 *
 * @returns
 */
const ExpansionCard = ({ header, content, actions }) => (
  <Accordion data-test-id="EXPANSION_CARD">
    <AccordionSummary expandIcon={<ExpandMore />}>{header}</AccordionSummary>
    <AccordionDetails>{content}</AccordionDetails>
    {actions && (
      <>
        <Divider />
        <AccordionActions>{actions}</AccordionActions>
      </>
    )}
  </Accordion>
);

/*
 **  PropTypes
 */
ExpansionCard.propTypes = {
  header: PropTypes.node,
  content: PropTypes.node,
  actions: PropTypes.node,
};

export default ExpansionCard;
