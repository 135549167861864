/********************************************************************
 *
 * CRM/Customers/History/Index.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router";
import format from "../../../../utils/format";
import moment from "moment";
import { useApp, useAPI } from "../../../../providers/AppProvider";

/**
 * History
 *
 * Renders the order and travel history for a customer.
 *
 */
export default History = () => {
  const [orders, setOrders] = useState(null);
  const [products, setProducts] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const { customer } = useOutletContext();
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading History");

    Promise.all([fetchOrders(customer.id), fetchProducts(customer.id)])
      .then(([orders, products]) => {
        setOrders(orders);
        setProducts(products);
        setLoaded(true);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setOrders(null);
        setProducts(null);
        setLoaded(true);
      });
  }, []);

  /**
   * fetchOrders
   *
   * Calls the API to fetch past orders for the customer
   *
   * @param {*} customerId
   */
  const fetchOrders = (customerId) => {
    return new Promise((resolve, reject) => {
      api
        .fetch(`/api/customers/customer/${customerId}/orders`)
        .then((envelope) => {
          resolve(envelope.payload);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * fetchProducts
   *
   * Calls the API to fetch past orders for the customer
   *
   * @param {*} customerId
   */
  const fetchProducts = (customerId) => {
    return new Promise((resolve, reject) => {
      api
        .fetch(`/api/customers/customer/${customerId}/products/`)
        .then((envelope) => {
          resolve(
            envelope.payload
              ? envelope.payload.sort((a, b) => {
                  return moment(a.product.startTime).isBefore(
                    b.product.startTime
                  )
                    ? -1
                    : 1;
                })
              : null
          );
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  if (!loaded)
    return <div className="spinner-border text-secondary" role="status" />;

  return (
    <>
      <h5>Orders</h5>
      <Orders orders={orders} />
      <h5 className="mt-3">Goods and Services</h5>
      <Tours products={products} />
    </>
  );
};

/////////////////////////////////////////////////////////////////////
//
//  Functional Components
//
/////////////////////////////////////////////////////////////////////

/**
 * Orders
 *
 * @param {*} props
 */
const Orders = ({ history, orders }) => {
  if (!orders) return "No previous orders";

  return (
    <table className="table table-striped table-hover table-sm small">
      <colgroup>
        <col style={{ width: "125px" }} />
        <col style={{ width: "100px" }} />
        <col />
        <col style={{ width: "100px" }} />
        <col style={{ width: "100px" }} />
      </colgroup>
      <thead>
        <tr>
          <th>Booked</th>
          <th>Order ID</th>
          <th>Agent</th>
          <th className="text-right">Total</th>
          <th className="text-right">Due</th>
        </tr>
      </thead>
      <tbody>
        {orders.map((order, index) => (
          <tr
            key={index}
            className="pointer"
            onClick={(e) => {
              window.location.href = `/app/sales/order/${order.id}`;
            }}
          >
            <td>{format.date(order.committed)}</td>
            <td>{order.id}</td>
            <td>
              {order.user.fname} {order.user.lname}
            </td>
            <td className="text-right">{order.totals.amount}</td>
            <td className="text-right">{order.totals.due}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

/**
 * Tours
 *
 * @param {*} props
 */
const Tours = ({ history, products }) => {
  if (!products) return "No previous tours";

  return (
    <table className="table table-striped table-hover table-sm small">
      <colgroup>
        <col style={{ width: "125px" }} />
        <col />
      </colgroup>
      <thead>
        <tr>
          <td>Date</td>
          <td>Product</td>
        </tr>
      </thead>
      <tbody>
        {products.map((product, index) => (
          <tr
            key={index}
            className="pointer"
            onClick={(e) => {
              history.push(`/app/catalog/product/${product.id}`);
            }}
          >
            <td>{format.date(product.startTime, product.timezone)}&nbsp;</td>
            <td>{product.name}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
