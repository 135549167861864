/********************************************************************
 *
 * Page.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { Outlet } from "react-router";
import PublicThemeProvider from "../../themes/public";
import Header from "./Header";
import { PublicAppProvider } from "../../providers";

/**
 * Index
 *
 * Page wrapper for publicly visible pages
 *
 * @param {*} param0
 */
export const Index = () => {
  return (
    <PublicAppProvider>
      <PublicThemeProvider>
        <Header />
        <Outlet />
      </PublicThemeProvider>
    </PublicAppProvider>
  );
};

export default Index;
