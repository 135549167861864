/********************************************************************
 *
 * Order.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Box, Grid, Avatar, Typography } from "@mui/material";
import {
  WidgetFrame,
  CardList,
  LinkCard2,
  CardContentHeader,
  Property,
} from "../../../lib";
import format from "../../../../utils/format";
import { useApp, useAPI } from "../../../../providers/AppProvider";

/**
 * Orders
 *
 * Renders a list of orders for a product.
 *
 */
const Orders = ({ product }) => {
  const [orders, setOrders] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    fetchOrders();
  }, []);

  ///////////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////////

  const fetchOrders = () => {
    api
      .fetch(`/api/products/product/${product.id}/orders`)
      .then(({ payload: orders }) => {
        setOrders(orders);
        setLoaded(true);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle Methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <WidgetFrame title="Orders">
      {loaded && !orders ? (
        "No orders"
      ) : (
        <CardList
          xs={12}
          md={6}
          spacing={4}
          items={orders}
          onRenderCard={({ item }) => <Order order={item} />}
        />
      )}
    </WidgetFrame>
  );
};

const Order = ({ order }) => (
  <LinkCard2
    link={`/app/sales/order/${order.id}`}
    header={<OrderHeader order={order} />}
    content={<OrderContent order={order} />}
  />
);

const OrderHeader = ({ order }) => {
  if (!order) return null;

  return (
    <CardContentHeader
      avatar={<Avatar variant="circular">{order.name[0]}</Avatar>}
      callout={
        !!order.deleted && {
          label: "Deleted",
          color: "error",
        }
      }
      content={
        <Box>
          <Typography variant="h4">{order.name}</Typography>
          <Typography variant="h6">
            Booked {format.date(order.committed)}
          </Typography>
        </Box>
      }
    />
  );
};

const OrderContent = ({ order }) => (
  <Property>
    <Grid container>
      <Grid item xs={4}>
        Name:
      </Grid>
      <Grid
        item
        xs={8}
      >{`${order.customer.fname} ${order.customer.lname}`}</Grid>
      <Grid item xs={4}>
        P.O.:
      </Grid>
      <Grid item xs={8}>
        {order.PO}
      </Grid>
      <Grid item xs={4}>
        Confirmation:
      </Grid>
      <Grid item xs={8}>
        {order.confirmation}
      </Grid>
      <Grid item xs={4}>
        Message:
      </Grid>
      <Grid item xs={8}>
        {order.message}
      </Grid>
    </Grid>
  </Property>
);

export default Orders;
