/********************************************************************
 *
 * ProductReport.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Link } from "react-router";
import { Anchor, LocalHospital, Add } from "@mui/icons-material";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker } from "react-dates";
import { START_DATE, END_DATE } from "react-dates/constants";
import moment from "moment-timezone";
import format from "../../../utils/format";
import ProductWaterLevel from "../ProductWaterLevel";
import { useApp, useAPI } from "../../../providers/AppProvider";

/**
 * ProductReport
 *
 * Displays the Product Report
 */
const ProductReport = () => {
  const [products, setProducts] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    let today = moment().startOf("day");

    let startDate = moment(JSON.parse(localStorage.getItem("_prStartDate")));
    let endDate = moment(JSON.parse(localStorage.getItem("_prEndDate")));

    if (!startDate.isValid() || !endDate.isValid()) {
      setStartDate(today);
      setEndDate(moment(today).endOf("month"));
    } else {
      setStartDate(startDate);
      setEndDate(endDate);
    }
  }, []);

  useEffect(() => {
    fetchProducts(startDate, endDate);
  }, [startDate, endDate]);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * GetStartDate
   *
   * Computes an appropriate date to start the calendar.
   *
   * @param {*} date
   */
  const getStartDate = (date) => {
    if (date) {
      return moment(date).startOf("month").startOf("week").startOf("day");
    }

    return null;
  };

  /**
   * FetchProducts
   *
   * Calls the api to fetch products
   *
   * @param {*} startDate
   * @param {*} endDate
   */
  const fetchProducts = (startDate, endDate) => {
    if (!moment(startDate).isValid() || !moment(endDate).isValid()) return;

    //
    //  Request searches
    //
    api
      .fetch(
        `/api/products/daterange/${format.toApiDateTime(
          startDate
        )}/${format.toApiDateTime(endDate)}`
      )
      .then(({ payload: products }) => {
        setProducts(products);
        setLoaded(true);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  const onDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setLoaded(false);
    setProducts(null);
    localStorage.setItem("_prStartDate", JSON.stringify(startDate));
    localStorage.setItem("_prEndDate", JSON.stringify(endDate));
  };

  const onFocusChange = (focusedInput) => {
    setFocusedInput(focusedInput);
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <div className="page-title">
          <LocalHospital />
          Products
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <div className="btn-group mr-2">
            <Link
              className="btn btn-sm btn-outline-secondary"
              to="/app/catalog/product/edit/"
            >
              <Add />
            </Link>
          </div>
        </div>
      </div>
      <div className="mb-2">
        <DateRangePicker
          startDate={startDate}
          startDateId={START_DATE}
          endDate={endDate}
          endDateId={END_DATE}
          focusedInput={focusedInput}
          onDatesChange={onDatesChange}
          onFocusChange={onFocusChange}
        />
      </div>
      {loaded ? (
        products ? (
          <Products products={products} />
        ) : (
          "No Products Available"
        )
      ) : (
        "Loading..."
      )}
    </main>
  );
};

/**
 * Products
 *
 * @param {*} param0
 */
const Products = ({ products }) => {
  return (
    <div className="table-responsive small">
      <table className="table table-striped table-sm">
        <thead>
          <tr>
            <th>Product</th>
            <th>Date</th>
            <th>Time</th>
            <th>Duration</th>
            <th>Levels</th>
            <th>Capacity</th>
            <th>Avail</th>
            <th>PAX</th>
            <th>Asset</th>
          </tr>
        </thead>
        <tbody>
          {products
            ? products.map(
                (product) =>
                  !product.deleted && (
                    <tr
                      key={product.id}
                      className={product.deleted ? "text-muted" : ""}
                      style={
                        product.deleted
                          ? { textDecoration: "line-through" }
                          : null
                      }
                    >
                      <td>
                        <Link to={`/app/catalog/product/${product.id}`}>
                          {product.name}
                        </Link>
                        {product.departure && <Anchor />}
                      </td>
                      <td>
                        <Link to={`/app/catalog/product/${product.id}`}>
                          {format.shortDayDate(product.startTime.time)}
                        </Link>
                      </td>
                      <td>
                        {`${format.time(product.startTime.time)} -
                      ${format.time(product.endTime.time)}`}
                      </td>
                      <td>{format.duration(product.duration)}</td>
                      <td>
                        <ProductWaterLevel product={product} />
                      </td>
                      <td>{product.capacity}</td>
                      <td>{product.available}</td>
                      <td>{product.departure && product.departure.pax}</td>
                      <td>
                        {product.departure && product.departure.asset.name}
                      </td>
                    </tr>
                  )
              )
            : null}
        </tbody>
      </table>
    </div>
  );
};

export default ProductReport;
